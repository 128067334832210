import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ReactGA from "react-ga";

import * as actions from "../../../store/actions/actions";
import { fetchData, URL_SEARCH } from "../../../utils/api";

import SearchResults from "../SearchResults/SearchResults";

import "./SearchBox.css";

import searchIcon from "../../../images/icons/search1.svg";
import DropDownContainer from "./DropdownContainer";

import data from "./countries-regions.json";

import { getCountryFilter } from "../../../utils/mapFilters/nwp";

// returns the index of an array inside another array, -1 otherwise
// source: https://stackoverflow.com/questions/6315180/javascript-search-array-of-arrays
function indexOfArray(val, array) {
  var hash = {};
  for (let i = 0; i < array.length; i++) {
    hash[array[i]] = i;
  }
  return hash.hasOwnProperty(val) ? hash[val] : -1;
}
const removeCurrentCountryFilter = currentFilter => {
  if (currentFilter && currentFilter.length > 0) {
    for (let i = 0; i < currentFilter.length; i++) {
      if (currentFilter[i][1][1]) {
        if (currentFilter[i][1][1][1][1] === "country_id") {
          const index = indexOfArray(currentFilter[i], currentFilter);
          currentFilter.splice(index, 1);
        }
      }
    }
  }

  return currentFilter;
};
const SearchBox = props => {
  let selectedCountries = props.searchedStations.selectedCountries;
  let prefix = "";
  if (props.baseline === "GBON") {
    prefix = "gbon_";
  }

  const ftype = props[prefix + props.fileType];

  let baseURl;
  // the search URL has not the same format for all file types
  if (
    props.fileType === "synop" ||
    props.fileType === "temp" ||
    props.fileType === "buoy" ||
    props.fileType === "ship" ||
    props.fileType === "marine_surface"
  ) {
    baseURl = `${URL_SEARCH}/?file_type=${props.fileType}&baseline=${props.baseline}&period_type=${ftype.selectedPeriodType}&report=${ftype.selectedReport}&variable=${ftype.selectedVariable}&date=${ftype.selectedDate}&period=${ftype.selectedSixHPeriod}&center=${ftype.selectedCenter}`;
  } else if (props.fileType === "guan" || props.fileType === "gsn") {
    baseURl = `${URL_SEARCH}/?file_type=${props.fileType}&report=${ftype.selectedReport}&variable=${ftype.selectedVariable}&date=${ftype.selectedDate}`;
  }
  // This is a function shipped with dropdown country filter to set the herirachy
  // for the select box
  const assignObjectPaths = (obj, stack) => {
    Object.keys(obj).forEach(k => {
      const node = obj[k];
      if (typeof node === "object") {
        node.path = stack ? `${stack}.${k}` : k;
        assignObjectPaths(node, node.path);
      }
    });
  };
  let [countries, setCountries] = useState(data);
  // recursive function to set the checked attribute in country-region json file
  // to True for the selected node from the text box
  let setCheckedStatus = (arr, selectedNode, status) => {
    for (let obj of arr) {
      if (obj.value === selectedNode.value && selectedNode.path === obj.path) {
        obj.checked = status;
        if (obj.children) {
          for (let child of obj.children) {
            // second level,sub region
            child.checked = status;
            if (child.children) {
              for (let subchild of child.children) {
                // last level, country
                subchild.checked = status;
              }
            }
          }
        }
      }
      if (obj.children) {
        let result = setCheckedStatus(obj.children, selectedNode, status);
        if (result) {
          return result;
        }
      }
    }
    return undefined;
  };
  // recursive function to clear checked status
  // we use it intial to make sure when country filter is first time loaded
  // doesn't have any checked element
  let clearchecked = data => {
    for (let obj of data) {
      obj.checked = false;
      if (obj.children) {
        clearchecked(obj.children);
      }
    }
    return data;
  };
  // Update countries regions list with the selected values from user.
  const updateCountriesReginosChecked = (countriesList, selectedNodes) => {
    // for every selected node from dropdown we set the corresponding country/region check status
    for (var i = 0; i < selectedNodes.length; i++) {
      setCheckedStatus(countriesList, selectedNodes[i], true);
    }
    return countriesList;
  };
  const onChangeMouveHover = e => {
    props.onChangeStatus({
      key: "classCCtrlL1",
      value: "ctrl-search-open"
    });
    props.onChangeStatus({
      key: "classContainer",
      value: "inside-filter-open"
    });
  };

  const onCloseSearchCtrl = e => {
    // props.onChangeStatus({ key: "matches", value: [] });
    // props.onChangeStatus({ key: "result", value: "" });
    props.onChangeStatus({
      key: "classContainer",
      value: "inside-filter-closed"
    });
    props.onChangeStatus({ key: "classCCtrlL1", value: "ctrl-search" });
  };

  // Event fired when the input value is changed
  const onChange = e => {
    props.onChangeStatus({
      key: "result",
      value: e.currentTarget.value
    });

    if (e.currentTarget.value.length >= 3) {
      // add the searched string to the URL
      fetchData(`${baseURl}&search=${e.currentTarget.value}`).then(response => {
        props.onChangeStatus({ key: "matches", value: response.data });
      });
    } else {
      props.onChangeStatus({ key: "matches", value: [] });
    }
  };

  // get countries give a sub region id
  const getCountries = (val, countries) => {
    let result = [];
    for (let index = 0; index < countries.length; index++) {
      let element = countries[index];
      for (let obj of element.children) {
        if (obj.value === val) {
          // sub region level
          if (obj.children) {
            for (let child of obj.children) {
              // second level,sub region
              result.push(child.value);
            }
          }
        }
      }
    }
    return result;
  };
  // get country ids as array to be used for download later
  const getCountryIds = selectedNodes => {
    let country_ids = [];
    for (var i = 0; i < selectedNodes.length; i++) {
      // check if it is region selected
      if (selectedNodes[i].region_id) {
        for (let sub_region of selectedNodes[i]._children) {
          let region_id = selectedNodes[i].region_id;

          for (let region of data) {
            if (region.region_id === region_id) {
              for (let sub_region of region.children) {
                for (let country of sub_region.children) {
                  country_ids.push(country.value);
                }
              }
            }
          }
        }
      } else if (selectedNodes[i].sub_region_id) {
        let sub_region_countries = getCountries(
          selectedNodes[i].sub_region_id,
          countries
        );
        for (let country of sub_region_countries) {
          country_ids.push(country);
        }
        // country_ids = [...country_ids, sub_region_countries];
      } else {
        country_ids.push(selectedNodes[i].value);
      }
    }
    // Get unique list
    country_ids = [...new Set(country_ids)];
    return country_ids;
  };
  // NOT USED FUNCTION
  const filterCountryLayer = country_ids => {
    const layerName = props.map.getStyle().layers[
      props.map.getStyle().layers.length - 1
    ].id;
    let currentFilter = props.map.getFilter(layerName);
    // remove all type country_id filters
    if (currentFilter) {
      currentFilter = removeCurrentCountryFilter(currentFilter);
    }

    let newFilter = [];
    let f;

    if (country_ids.length > 0) {
      newFilter = getCountryFilter(country_ids);
    }

    if (currentFilter) {
      if (newFilter.length !== 0) {
        currentFilter.push(newFilter);
      }
      f = currentFilter;
    } else {
      f = ["all", newFilter];
    }
    props.map.setFilter(layerName, f);
  };
  // handle on change countries/regions filter
  const onChangeCountriesRegions = (currentNode, selectedNodes) => {
    // Make sure we have clean un checked values before we set selected countries by user
    let countriesList = clearchecked(data);
    // get selected countries by users to save them before dispatch by other search box elements
    let selectedData = updateCountriesReginosChecked(
      countriesList,
      selectedNodes
    );
    // Save update countrieslist with checked status for every country/region
    setCountries(selectedData);
    let countryIds = getCountryIds(selectedNodes);
    props.onChangeStatus({
      key: "selectedCountries",
      value: countryIds.toString()
    });
    props.onChangeCountryFilter({
      selectedCountries: countryIds
    });
    // filterCountryLayer(selectedNodes);
  };
  useEffect(() => {
    if (selectedCountries === "") {
      setCountries(clearchecked(data));
    }
  });

  // Event fired when the user clicks on a match
  const handleClick = e => {
    const idInArray = e.currentTarget.getAttribute("data-elementid");
    const stationObject = props.searchedStations.matches[idInArray];
    props.onChangeStatus({
      key: "result",
      value: e.currentTarget.innerText
    });
    props.onChangeStatus({
      key: "matches",
      value: []
    });

    props.map.flyTo({
      center: [stationObject[2], stationObject[3]],
      zoom: 9
    });

    // Add Google analytics event to track click on 'download data' link
    if (window.location.hostname !== "localhost") {
      ReactGA.event({
        category: "User Interaction",
        action: "Search station",
        label: stationObject[0]
      });
    }
  };

  assignObjectPaths(data);

  return (
    <>
      <div className={props.searchedStations.classCCtrlL1}>
        <img src={searchIcon} onMouseOver={onChangeMouveHover} alt="" />
        <div className={props.searchedStations.classContainer}>
          <input
            id="filter-input"
            type="text"
            name="filter"
            placeholder="Station name or WIGOS-ID"
            onChange={onChange}
            value={props.searchedStations.result}
            autoComplete="off"
          />
          {/* <label htmlFor="menu-centers">Country Filter</label> */}
          <DropDownContainer
            data={countries}
            onChange={onChangeCountriesRegions}
            className="bootstrap-demo"
            texts={{ placeholder: "Countries or Regions" }}
            keepTreeOnSearch={true}
            keepOpenOnSelect={true}
            keepChildrenOnSearch={true}
            selectedCountries={selectedCountries}
            ownProps={props}
          />
          <button
            className="btn-close-search-ctrl"
            onClick={onCloseSearchCtrl}
            aria-label="Close Search Station Control"
          >
            ×
          </button>
        </div>
      </div>
      <SearchResults
        elements={props.searchedStations.matches}
        onClick={handleClick}
      />
    </>
  );
};

const mapStateToProps = state => {
  return {
    map: state.map,
    synop: state.synop,
    temp: state.temp,
    gbon_synop: state.gbon_synop,
    gbon_temp: state.gbon_temp,
    guan: state.guan,
    gsn: state.gsn,
    buoy: state.buoy,
    ship: state.ship,
    marine_surface: state.marine_surface,
    searchedStations: state.searchedStations,
    activeLayerName: state.activeLayerName
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChangeStatus: data => {
      dispatch(
        actions.setProperty({
          type: "searchedStations",
          key: data.key,
          value: data.value
        })
      );
    },
    onChangeCountryFilter: data => {
      return dispatch((_, getState) => {
        const props = getState();
        // props.map.setFilter(currentState.activeLayerName, undefined);

        const layerName = props.map.getStyle().layers[
          props.map.getStyle().layers.length - 1
        ].id;
        let currentFilter = props.map.getFilter(layerName);
        // remove all type country_id filters
        if (currentFilter) {
          currentFilter = removeCurrentCountryFilter(currentFilter);
        }

        let newFilter = [];
        let f;
        let country_ids = data.selectedCountries;

        if (country_ids.length > 0) {
          newFilter = getCountryFilter(country_ids);
        }

        if (currentFilter) {
          if (newFilter.length !== 0) {
            currentFilter.push(newFilter);
          }
          f = currentFilter;
        } else {
          f = ["all", newFilter];
        }
        props.map.setFilter(layerName, f);
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox);
