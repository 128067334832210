import React from "react";
import { timeFormat, timeParse } from "d3-time-format";
import { rangeMonthsBetweenDates } from "../../../utils/dataAPIformatting/index";
import { Tooltip } from "antd";

import "./GSNAvailability.css";

const formatDate = "%Y-%m-%dT%H:%M:%SZ";
const parserTime = timeParse(formatDate);
const formaterString = timeFormat("%b %Y");

const status = {
  availability: { color: "#7acc7a", tooltip: "Reporting" },
  no_availability: { color: "#ee8058", tooltip: "Not reporting" },
  no_data: { color: "#bdbdbd", tooltip: "No data" }
};

// This component is only used for GSN/Availability
// as this is the only case where we have qualitative
// data and hence can't display time-series
const GSNAvailability = props => {
  // Get the latest date
  const latestDate = props.data[props.data.length - 1].date;
  // Get all the existing 12 months until `latestDate`
  const allMonths = rangeMonthsBetweenDates(latestDate, 11);
  // Get all the months from the data so that we can
  // compare them with `allMonths` and see if some are missing
  const monthsFromData = props.data.map(d => d.date);
  // We reverse the array so that with CSS flexbox
  // we have a better layout on smaller screens
  const months = allMonths.reverse().map(m => {
    let statusMonth;
    if (monthsFromData.includes(m)) {
      const index = monthsFromData.indexOf(m);
      const availability = props.data[index].availability;
      statusMonth = availability ? status.availability : status.no_availability;
    } else {
      statusMonth = status.no_data;
    }
    // We split the date so that we can always display
    // the month and the year on 2 different lines
    const monthParts = formaterString(parserTime(m)).split(" ");
    return (
      <Tooltip key={m} placement="top" title={statusMonth.tooltip}>
        <div
          style={{
            backgroundColor: statusMonth.color
          }}
          className="gsn-availability-item"
        >
          <div className="gsn-availability-row">{monthParts[0]}</div>
          <div className="gsn-availability-row">{monthParts[1]}</div>
        </div>
      </Tooltip>
    );
  });
  return <div className="gsn-availability-container">{months}</div>;
};

export default GSNAvailability;
