import React, { Component } from "react";
import DropdownTreeSelect from "react-dropdown-tree-select";
import isEqual from "lodash/isEqual";

export default class DropDownContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { data: props.data };
  }

  componentWillReceiveProps = nextProps => {
    if (!isEqual(nextProps.data, this.state.data)) {
      this.setState({ data: nextProps.data });
    }
  };

  shouldComponentUpdate = nextProps => {
    if (nextProps.ownProps.activeLayerName) {
      let nextLayer = nextProps.ownProps.activeLayerName;
      let previousLayer = this.props.ownProps.activeLayerName;
      if (nextProps.selectedCountries === "" && nextLayer !== previousLayer) {
        return true;
      }
    }
    return !isEqual(nextProps.data, this.state.data);
  };

  render() {
    const { data, ...rest } = this.props;
    return <DropdownTreeSelect data={this.state.data} {...rest} />;
  }
}
