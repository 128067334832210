import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import App from "./App.js";
import reducer from "./store/reducer";

import "mapbox-gl/dist/mapbox-gl.css";
import "antd/dist/antd.min.css";
import "./main.css";
// eslint-disable-next-line no-underscore-dangle
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, composeEnhancer(applyMiddleware(thunk)));

// Reload the page every 2 hours
setInterval(function() {
  window.location.reload();
}, 7200000);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter forceRefresh={false}>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
