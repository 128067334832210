import axios from "axios";

export const fetchData = url => {
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const WDQMS_API = `${process.env.REACT_APP_DJANGO_BASE_URL}wdqmsapi/v1`;

// Base URL for a station
export const URL_STATION = `${WDQMS_API}/station/`;

// Search stations
export const URL_SEARCH = `${WDQMS_API}/search`;

// SYNOP aggregated observations
export const URL_SYNOP_AGGREGATED_OBSERVATIONS_TIMESERIES = `${WDQMS_API}/time-series/synop-observations-by-periods`;
export const URL_SYNOP_AGGREGATED_OBSERVATIONS_POPUP = `${WDQMS_API}/popup/synop-observations-by-periods`;

// SYNOP individual observations
export const URL_SYNOP_OBSERVATIONS_TIMESERIES = `${WDQMS_API}/time-series/synop-observations`;

// BUOY individual observations
export const URL_BUOY_OBSERVATIONS_TIMESERIES = `${WDQMS_API}/time-series/buoy-observations`;

// SHUIP individual observations
export const URL_SHIP_OBSERVATIONS_TIMESERIES = `${WDQMS_API}/time-series/ship-observations`;

// Buoy aggregated observations

export const URL_BUOY_AGGREGATED_OBSERVATIONS_POPUP = `${WDQMS_API}/popup/buoy-observations-by-periods`;
export const URL_BUOY_AGGREGATED_OBSERVATIONS_TIMESERIES = `${WDQMS_API}/time-series/buoy-observations-by-periods`;

// Ship aggregated observations

export const URL_SHIP_AGGREGATED_OBSERVATIONS_POPUP = `${WDQMS_API}/popup/ship-observations-by-periods`;
export const URL_SHIP_AGGREGATED_OBSERVATIONS_TIMESERIES = `${WDQMS_API}/time-series/ship-observations-by-periods`;

// Ship position

export const URL_SHIP_OBSERVATIONS_POSITIONS = `${WDQMS_API}/popup/ship-positions-by-periods`;
// Ship position

export const URL_BUOY_OBSERVATIONS_POSITIONS = `${WDQMS_API}/popup/buoy-positions-by-periods`;
// TEMP aggregated observations
export const URL_TEMP_AGGREGATED_OBSERVATIONS_SIXHOUR_TIMESERIES = `${WDQMS_API}/time-series/temp-observations-by-sixhour-periods`;
export const URL_TEMP_AGGREGATED_OBSERVATIONS_DAILY_TIMESERIES = `${WDQMS_API}/time-series/temp-observations-by-daily-periods`;
export const URL_TEMP_AGGREGATED_OBSERVATIONS_MONTHLY_TIMESERIES = `${WDQMS_API}/time-series/temp-observations-by-monthly-periods`;
export const URL_TEMP_AGGREGATED_OBSERVATIONS_POPUP = `${WDQMS_API}/popup/temp-observations-by-periods`;

// TEMP individual observations
export const URL_TEMP_OBSERVATIONS_POPUP = `${WDQMS_API}/popup/temp-observations`;
export const URL_TEMP_OBSERVATIONS_TIMESERIES = `${WDQMS_API}/time-series/temp-observations`;

// GUAN observations
export const URL_GUAN_OBSERVATIONS = `${WDQMS_API}/time-series/guan-observations`;

// GSN observations
export const URL_GSN_OBSERVATIONS = `${WDQMS_API}/time-series/gsn-observations`;

// Download data
export const URL_DOWNLOAD_DATA = `${WDQMS_API}/download`;

// OSCAR
export const URL_OSCAR_SURFACE_SEARCH =
  "https://oscar.wmo.int/surface/index.html#/search/station/stationReportDetails/";
