import React from "react";

import "./Footer.css";
import { WDQMS_VERSION } from "../../utils/general";
const Footer = props => {
  return (
    <footer className="wdqms-footer">
      <span>&copy; WMO</span>
      <span>
        The boundaries and names shown and the designations used on this map do
        not imply official endorsement or acceptance by the World Meteorological
        Organization or the United Nations.
      </span>
      <span>
        Version:{" "}
        <a
          href="https://confluence.ecmwf.int/display/WIGOSWT/Releases"
          target="_blank"
          rel="noopener noreferrer"
        >
          {/* {process.env.REACT_APP_VERSION} */}
          {WDQMS_VERSION}
        </a>
      </span>
    </footer>
  );
};

export default Footer;
