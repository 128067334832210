import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import * as actions from "../../../store/actions/actions";
import { reports, updateURL } from "../../../utils/general";

import MenuReports from "./MenuReports/MenuReports";
import MenuVariables from "./MenuVariables/MenuVariables";
import MenuDates from "./MenuDates/MenuDates";

// Note: no CSS file here, we use the one of
// the MapMenu component

const GCOSMapMenu = props => {
  const ftype = props[props.fileType];
  const arrDates = Object.keys(ftype.dates);
  // For GUAN (what about GSN?) availability is not by variable
  const menuVariables = reports[props.fileType][ftype.selectedReport]
    .byVariable ? (
    <MenuVariables
      fileType={props.fileType}
      selectedReport={ftype.selectedReport}
      selectedVariable={ftype.selectedVariable}
      onChangeVariable={props.onChangeVariable}
    />
  ) : null;
  return (
    <div id="menu">
      <MenuReports
        fileType={props.fileType}
        selectedReport={
          ftype.selectedReport.charAt(0).toUpperCase() +
          ftype.selectedReport.slice(1)
        }
        onChangeReport={props.onChangeReport}
      />
      {menuVariables}
      <MenuDates
        arrDates={arrDates}
        selectedDate={ftype.selectedDate}
        onChangeDate={props.onChangeDate}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    guan: state.guan,
    gsn: state.gsn
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChangeReport: event => {
    return dispatch((_, getState) => {
      const newReportValue = event.target.value.toLowerCase();
      dispatch(
        actions.setProperty({
          type: "searchedStations",
          key: "selectedCountries",
          value: ""
        })
      );
      dispatch(
        actions.setProperty({
          type: ownProps.fileType,
          key: "selectedReport",
          value: newReportValue
        })
      );

      const objFType = getState()[ownProps.fileType];
      updateURL(
        ownProps,
        objFType,
        ownProps.fileType,
        "report",
        newReportValue
      );
    });
  },
  onChangeDate: dateObject => {
    return dispatch((_, getState) => {
      const dateString = moment.utc(dateObject).format("YYYY-MM");
      dispatch(
        actions.setProperty({
          type: "searchedStations",
          key: "selectedCountries",
          value: ""
        })
      );
      dispatch(
        actions.setProperty({
          type: ownProps.fileType,
          key: "selectedDate",
          value: dateString
        })
      );

      const objFType = getState()[ownProps.fileType];
      updateURL(ownProps, objFType, ownProps.fileType, "date", dateString);
    });
  },
  onChangeVariable: event => {
    return dispatch((_, getState) => {
      dispatch(
        actions.setProperty({
          type: "searchedStations",
          key: "selectedCountries",
          value: ""
        })
      );
      dispatch(
        actions.setProperty({
          type: ownProps.fileType,
          key: "selectedVariable",
          value: event.target.value.toLowerCase()
        })
      );
      const objFType = getState()[ownProps.fileType];
      updateURL(
        ownProps,
        objFType,
        ownProps.fileType,
        "variable",
        event.target.value
      );
    });
  }
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GCOSMapMenu)
);
