import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../store/actions/actions";
import { updateURL } from "../../utils/general";

import "./SixHourPeriods.css";

const SixHourPeriods = props => {
  let prefix = "";
  if (props.baseline === "GBON") {
    prefix = "gbon_";
  }
  const ftype = props[prefix + props.fileType];
  const ret = Object.keys(ftype.currentSixHPeriods)
    .sort()
    .map(p => {
      let classPeriod = null;
      let enabled = null;
      if (ftype.currentSixHPeriods[p].available) {
        classPeriod = "btn-period period-available";
        enabled = true;
      } else {
        classPeriod = "btn-period period-missing";
        enabled = false;
      }
      if (p === ftype.selectedSixHPeriod) {
        classPeriod += " period-selected";
      }
      return (
        <button
          onClick={props.onChangePeriod}
          key={p}
          value={p}
          className={classPeriod}
          disabled={!enabled}
        >
          {p}
        </button>
      );
    });
  return (
    <div>
      <div id="sixhour-title">Six-hour period</div>
      {ret}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    synop: state.synop,
    temp: state.temp,
    gbon_synop: state.gbon_synop,
    gbon_temp: state.gbon_temp,
    buoy: state.buoy,
    ship: state.ship,
    marine_surface: state.marine_surface
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChangePeriod: event => {
    let prefix = "";
    if (ownProps.baseline === "GBON") {
      prefix = "gbon_";
    }
    return dispatch((_, getState) => {
      dispatch(
        actions.setProperty({
          type: prefix + ownProps.fileType,
          key: "selectedSixHPeriod",
          value: event.target.value
        })
      );
      const objFType = getState()[prefix + ownProps.fileType];
      updateURL(
        ownProps,
        objFType,
        ownProps.fileType,
        "sixh-period",
        event.target.value
      );
    });
  }
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SixHourPeriods)
);
