import { cl_synop_in_oscar } from "../mapStyles/synop";

// For SYNOP and TEMP

// AVAILABILITY

export const getGBONAvailabilityClassFilter = (
  label,
  baseline,
  period_type,
  file_type
) => {
  let valuePeriodType = ["get", "nr_expected"];
  if (file_type === "temp") {
    if (period_type === "six_hour") {
      valuePeriodType = 0;
    } else if (period_type === "daily") {
      valuePeriodType = 2;
    } else {
      valuePeriodType = ["get", "nr_expected"];
    }
  }
  if (file_type === "synop") {
    if (period_type === "six_hour") {
      valuePeriodType = 6;
    } else if (period_type === "daily") {
      valuePeriodType = 24;
    } else {
      valuePeriodType = ["get", "nr_expected"];
    }
  }
  const partBaseline =
    baseline === "OSCAR" ? ["get", "nr_expected"] : valuePeriodType;
  switch (label) {
    case "cl-not-oscar":
      return cl_synop_in_oscar;
    case "cl-avail-silent":
      return [
        "any",
        ["!=", ["/", ["get", "nr_received"], partBaseline], 0],
        ["!=", ["get", "in_oscar"], true]
      ];
    case "cl-synop-avail-big-issues":
      return [
        "any",
        [">=", ["/", ["get", "nr_received"], partBaseline], 0.3],
        ["<=", ["/", ["get", "nr_received"], partBaseline], 0],
        ["!=", ["get", "in_oscar"], true]
      ];
    case "cl-synop-avail-small-issues":
      return [
        "any",
        ["<", ["/", ["get", "nr_received"], partBaseline], 0.3],
        [">=", ["/", ["get", "nr_received"], partBaseline], 0.8],
        ["!=", ["get", "in_oscar"], true]
      ];
    case "cl-synop-avail-normal":
      return [
        "any",
        ["<", ["/", ["get", "nr_received"], partBaseline], 0.8],
        [">", ["/", ["get", "nr_received"], partBaseline], 1],
        ["!=", ["get", "in_oscar"], true]
      ];
    case "cl-synop-gbon-avail-normal":
      return [
        "any",
        ["<=", ["/", ["get", "nr_received"], partBaseline], 0.8],
        ["!=", ["get", "in_oscar"], true]
      ];

    // TEMP
    // Daily
    // GBON
    case "cl-gbon-temp-avail-daily-all":
      return ["any", ["<=", ["/", ["get", "nr_received"], partBaseline], 0.8]];
    case "cl-gbon-temp-avail-small-issues":
      return [
        "any",
        ["<", ["/", ["get", "nr_received"], partBaseline], 0.3],
        [">=", ["/", ["get", "nr_received"], partBaseline], 0.8]
      ];
    case "cl-gbon-temp-avail-big-issues":
      return [
        "any",
        ["<=", ["/", ["get", "nr_received"], partBaseline], 0],
        [">=", ["/", ["get", "nr_received"], partBaseline], 0.3]
      ];
    case "cl-temp-avail-daily-all":
      return [
        "any",
        ["!=", ["/", ["get", "nr_received"], partBaseline], 1],
        ["!=", ["get", "availability"], 3],
        ["!=", ["get", "in_oscar"], true]
      ];
    case "cl-temp-avail-daily-missing-var":
      return [
        "any",
        ["<", ["/", ["get", "nr_received"], partBaseline], 1],
        [">=", ["get", "availability"], 3],
        ["!=", ["get", "in_oscar"], true]
      ];
    case "cl-temp-avail-daily-missing-level":
      return [
        "any",
        ["==", ["get", "nr_received"], 0],
        [">=", ["/", ["get", "nr_received"], partBaseline], 1],
        ["!=", ["get", "in_oscar"], true]
      ];
    // Monthly
    case "cl-synop-avail-monthly-more-than-100p":
      return [
        "any",
        ["<=", ["/", ["get", "nr_received"], partBaseline], 1],
        ["!=", ["get", "availability"], 3],
        ["!=", ["get", "in_oscar"], true]
      ];
    case "cl-temp-avail-monthly-all":
      return [
        "any",
        ["<", ["/", ["get", "nr_received"], partBaseline], 0.8],
        [">", ["/", ["get", "nr_received"], partBaseline], 1],
        ["!=", ["get", "in_oscar"], true]
      ];
    case "cl-temp-avail-monthly-missing-var":
      return [
        "any",
        ["<", ["/", ["get", "nr_received"], partBaseline], 1],
        [">=", ["get", "availability"], 3],
        ["!=", ["get", "in_oscar"], true]
      ];
    case "cl-temp-avail-monthly-missing-level":
      return [
        "any",
        [">=", ["/", ["get", "nr_received"], partBaseline], 0.8],
        ["<=", ["/", ["get", "nr_received"], partBaseline], 0],
        ["!=", ["get", "in_oscar"], true]
      ];
    default:
      return [];
  }
};
