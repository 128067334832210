/* eslint-disable global-require */

import imageSYNOP from "../images/cards/card_synop.png";
import imageTEMP from "../images/cards/card_temp.png";
import imageGBONSYNOP from "../images/cards/card_gbon_synop.png";
import imageGBONTEMP from "../images/cards/card_gbon_temp.png";
import imageGSN from "../images/cards/card_gsn.png";
import imageGUAN from "../images/cards/card_guan.png";
import imageBUOY from "../images/cards/card_buoy.png";
import imageSHIP from "../images/cards/card_ship.png";

// The object 'modules' is used in the 'Home' component.
// The poperty 'hasImage' (boolean) is used to indicate
// if the network has a image to illustrate it.
// If a network has no illustration image it means that
// it's not yet available. In this case we use the text
// in the property 'textNoImage'.
const modules = {
  gbon: {
    title: "Global Basic Observing Network (GBON)",
    networks: {
      // This network is also called 'gsn'
      land_surface: {
        title: "Surface land observations",
        description: "Station Compliance",
        hasImage: true,
        imagePath: imageGBONSYNOP,
        textNoImage: null
      },
      // This network is also called 'guan'
      "land_upper-air": {
        title: "Upper-air land observations",
        description: "Station Compliance",
        hasImage: true,
        imagePath: imageGBONTEMP,
        textNoImage: null
      }
    }
  },
  nwp: {
    title:
      "Near-real-time NWP monitoring of the Global Observing System networks",
    networks: {
      // This network is also called 'synop'
      land_surface: {
        title: "Surface land observations",
        description: "Availability & Quality",
        hasImage: true,
        imagePath: imageSYNOP,
        textNoImage: null
      },
      // This network is also called 'temp'
      "land_upper-air": {
        title: "Upper-air land observations",
        description: "Availability & Quality",
        hasImage: true,
        imagePath: imageTEMP,
        textNoImage: null
      },
      marine_surface: {
        title: "Marine Surface observations",
        description: "Quality",
        hasImage: true,
        imagePath: imageSHIP,
        textNoImage: null
      }
    }
  },
  gcos: {
    title: "Monitoring of the Global Climate Observing System networks",
    networks: {
      // This network is also called 'gsn'
      land_surface: {
        title: "Surface land observations",
        description: "Availability & Completeness",
        hasImage: true,
        imagePath: imageGSN,
        textNoImage: null
      },
      // This network is also called 'guan'
      "land_upper-air": {
        title: "Upper-air land observations",
        description: "Availability & Quality",
        hasImage: true,
        imagePath: imageGUAN,
        textNoImage: null
      }
    }
  }
};

export default modules;
