// THIS FILE IS ONLY USED FOR SYNOP AND TEMP

import { cloneDeep } from "lodash";
import { colors } from "./colors";
import { reports } from "./general";

const arrayCenters = [
  {
    name: "dwd",
    label: "DWD",
    color: colors.chart.centers.dwd,
    representAs: "lines",
    tooltip: "point"
  },
  {
    name: "ecmwf",
    label: "ECMWF",
    color: colors.chart.centers.ecmwf,
    representAs: "lines",
    tooltip: "point"
  },
  {
    name: "jma",
    label: "JMA",
    color: colors.chart.centers.jma,
    representAs: "lines",
    tooltip: "point"
  },
  {
    name: "ncep",
    label: "NCEP",
    color: colors.chart.centers.ncep,
    representAs: "lines",
    tooltip: "point"
  }
];

const arrayCentersExpected = [
  {
    name: "nr_expected",
    label: "Expected",
    color: colors.chart.availability.expected,
    representAs: "lines",
    tooltip: "point"
  },
  ...arrayCenters
];

const metadataAvailability = {
  seriesNames: arrayCentersExpected,
  keyData: "nr_received",
  keyExpected: "nr_expected",
  type: "generic"
};

const synop_obj = {
  availability: {
    six_hour: {
      simple: metadataAvailability,
      combined: metadataAvailability
    },
    daily: {
      simple: metadataAvailability,
      combined: metadataAvailability
    },
    monthly: {
      simple: metadataAvailability,
      combined: metadataAvailability
    }
  },
  quality: {
    six_hour: {
      simple: {
        seriesNames: [
          {
            // The 'name' property is updated elsewhere
            name: null,
            label: "Six-hour average O-B",
            color: colors.chart.quality.simple,
            representAs: "lines",
            tooltip: "point"
          }
        ],
        keyData: "avg_bg_dep",
        keyExpected: null,
        type: "generic"
      },
      combined: {
        seriesNames: arrayCenters,
        keyData: null,
        keyExpected: null,
        type: "specific"
      }
    },
    daily: {
      simple: {
        seriesNames: [
          {
            // The 'name' property is updated elsewhere
            name: null,
            label: "Daily average O-B",
            color: colors.chart.quality.simple,
            representAs: "lines",
            tooltip: "point"
          }
        ],
        keyData: "avg_bg_dep",
        keyExpected: null,
        type: "generic"
      },
      combined: {
        seriesNames: arrayCenters,
        keyData: "avg_bg_dep",
        keyExpected: null,
        type: "generic"
      }
    },
    monthly: {
      simple: {
        seriesNames: [
          {
            // The 'name' property is updated elsewhere
            name: "avg_bg_dep",
            label: "Monthly avg daily O-B avg",
            color: colors.chart.quality.simple,
            representAs: "lines",
            tooltip: "line"
          },
          {
            // The 'name' property is updated elsewhere
            name: "std_bg_dep",
            label: "Monthly STD daily O-B avg",
            color: colors.chart.quality.simple,
            representAs: "dashlines",
            tooltip: "dashline"
          }
        ],
        keyData: null,
        keyExpected: null,
        type: "specific"
      },
      combined: {
        seriesNames: arrayCenters,
        keyData: ["rms", "avg_bg_dep"],
        keyExpected: null,
        type: "generic"
      }
    },
    alert: {
      simple: {
        seriesNames: [
          {
            // The 'name' property is updated elsewhere
            name: null,
            label: "Daily average O-B",
            color: colors.chart.alert.simple,
            representAs: "points",
            tooltip: "point"
          }
        ],
        keyData: "avg_bg_dep",
        keyExpected: null,
        type: "generic"
      },
      combined: {
        seriesNames: arrayCenters,
        keyData: "avg_bg_dep",
        keyExpected: null,
        type: "specific"
      }
    }
  }
};

const ship_obj = {
  quality: {
    six_hour: {
      simple: {
        seriesNames: [
          {
            // The 'name' property is updated elsewhere
            name: null,
            label: "Six-hour average O-B",
            color: colors.chart.quality.simple,
            representAs: "lines",
            tooltip: "point"
          }
        ],
        keyData: "avg_bg_dep",
        keyExpected: null,
        type: "generic"
      },
      combined: {
        seriesNames: arrayCenters,
        keyData: null,
        keyExpected: null,
        type: "specific"
      }
    },
    daily: {
      simple: {
        seriesNames: [
          {
            // The 'name' property is updated elsewhere
            name: null,
            label: "Daily average O-B",
            color: colors.chart.quality.simple,
            representAs: "lines",
            tooltip: "point"
          }
        ],
        keyData: "avg_bg_dep",
        keyExpected: null,
        type: "generic"
      },
      combined: {
        seriesNames: arrayCenters,
        keyData: "avg_bg_dep",
        keyExpected: null,
        type: "generic"
      }
    },
    monthly: {
      simple: {
        seriesNames: [
          {
            // The 'name' property is updated elsewhere
            name: "avg_bg_dep",
            label: "Monthly avg daily O-B avg",
            color: colors.chart.quality.simple,
            representAs: "lines",
            tooltip: "line"
          },
          {
            // The 'name' property is updated elsewhere
            name: "std_bg_dep",
            label: "Monthly STD daily O-B avg",
            color: colors.chart.quality.simple,
            representAs: "dashlines",
            tooltip: "dashline"
          }
        ],
        keyData: null,
        keyExpected: null,
        type: "specific"
      },
      combined: {
        seriesNames: arrayCenters,
        keyData: ["rms", "avg_bg_dep"],
        keyExpected: null,
        type: "generic"
      }
    },
    alert: {
      simple: {
        seriesNames: [
          {
            // The 'name' property is updated elsewhere
            name: null,
            label: "Daily average O-B",
            color: colors.chart.alert.simple,
            representAs: "points",
            tooltip: "point"
          }
        ],
        keyData: "avg_bg_dep",
        keyExpected: null,
        type: "generic"
      },
      combined: {
        seriesNames: arrayCenters,
        keyData: "avg_bg_dep",
        keyExpected: null,
        type: "specific"
      }
    }
  }
};
const buoy_obj = {
  quality: {
    six_hour: {
      simple: {
        seriesNames: [
          {
            // The 'name' property is updated elsewhere
            name: null,
            label: "Six-hour average O-B",
            color: colors.chart.quality.simple,
            representAs: "lines",
            tooltip: "point"
          }
        ],
        keyData: "avg_bg_dep",
        keyExpected: null,
        type: "generic"
      },
      combined: {
        seriesNames: arrayCenters,
        keyData: null,
        keyExpected: null,
        type: "specific"
      }
    },
    daily: {
      simple: {
        seriesNames: [
          {
            // The 'name' property is updated elsewhere
            name: null,
            label: "Daily average O-B",
            color: colors.chart.quality.simple,
            representAs: "lines",
            tooltip: "point"
          }
        ],
        keyData: "avg_bg_dep",
        keyExpected: null,
        type: "generic"
      },
      combined: {
        seriesNames: arrayCenters,
        keyData: "avg_bg_dep",
        keyExpected: null,
        type: "generic"
      }
    },
    monthly: {
      simple: {
        seriesNames: [
          {
            // The 'name' property is updated elsewhere
            name: "avg_bg_dep",
            label: "Monthly avg daily O-B avg",
            color: colors.chart.quality.simple,
            representAs: "lines",
            tooltip: "line"
          },
          {
            // The 'name' property is updated elsewhere
            name: "std_bg_dep",
            label: "Monthly STD daily O-B avg",
            color: colors.chart.quality.simple,
            representAs: "dashlines",
            tooltip: "dashline"
          }
        ],
        keyData: null,
        keyExpected: null,
        type: "specific"
      },
      combined: {
        seriesNames: arrayCenters,
        keyData: ["rms", "avg_bg_dep"],
        keyExpected: null,
        type: "generic"
      }
    },
    alert: {
      simple: {
        seriesNames: [
          {
            // The 'name' property is updated elsewhere
            name: null,
            label: "Daily average O-B",
            color: colors.chart.alert.simple,
            representAs: "points",
            tooltip: "point"
          }
        ],
        keyData: "avg_bg_dep",
        keyExpected: null,
        type: "generic"
      },
      combined: {
        seriesNames: arrayCenters,
        keyData: "avg_bg_dep",
        keyExpected: null,
        type: "specific"
      }
    }
  }
};
const marine_surface_obj = {
  quality: {
    six_hour: {
      simple: {
        seriesNames: [
          {
            // The 'name' property is updated elsewhere
            name: null,
            label: "Six-hour average O-B",
            color: colors.chart.quality.simple,
            representAs: "lines",
            tooltip: "point"
          }
        ],
        keyData: "avg_bg_dep",
        keyExpected: null,
        type: "generic"
      },
      combined: {
        seriesNames: arrayCenters,
        keyData: null,
        keyExpected: null,
        type: "specific"
      }
    },
    daily: {
      simple: {
        seriesNames: [
          {
            // The 'name' property is updated elsewhere
            name: null,
            label: "Daily average O-B",
            color: colors.chart.quality.simple,
            representAs: "lines",
            tooltip: "point"
          }
        ],
        keyData: "avg_bg_dep",
        keyExpected: null,
        type: "generic"
      },
      combined: {
        seriesNames: arrayCenters,
        keyData: "avg_bg_dep",
        keyExpected: null,
        type: "generic"
      }
    },
    monthly: {
      simple: {
        seriesNames: [
          {
            // The 'name' property is updated elsewhere
            name: "avg_bg_dep",
            label: "Monthly avg daily O-B avg",
            color: colors.chart.quality.simple,
            representAs: "lines",
            tooltip: "line"
          },
          {
            // The 'name' property is updated elsewhere
            name: "std_bg_dep",
            label: "Monthly STD daily O-B avg",
            color: colors.chart.quality.simple,
            representAs: "dashlines",
            tooltip: "dashline"
          }
        ],
        keyData: null,
        keyExpected: null,
        type: "specific"
      },
      combined: {
        seriesNames: arrayCenters,
        keyData: ["rms", "avg_bg_dep"],
        keyExpected: null,
        type: "generic"
      }
    },
    alert: {
      simple: {
        seriesNames: [
          {
            // The 'name' property is updated elsewhere
            name: null,
            label: "Daily average O-B",
            color: colors.chart.alert.simple,
            representAs: "points",
            tooltip: "point"
          }
        ],
        keyData: "avg_bg_dep",
        keyExpected: null,
        type: "generic"
      },
      combined: {
        seriesNames: arrayCenters,
        keyData: "avg_bg_dep",
        keyExpected: null,
        type: "specific"
      }
    }
  }
};
const gbon_synop_obj = {
  availability: {
    six_hour: {
      simple: metadataAvailability,
      combined: metadataAvailability
    },
    daily: {
      simple: metadataAvailability,
      combined: metadataAvailability
    },
    monthly: {
      simple: metadataAvailability,
      combined: metadataAvailability
    }
  }
};
const temp_obj = {
  availability: {
    six_hour: {
      simple: {
        seriesNames: arrayCenters,
        keyData: "availability",
        keyExpected: null,
        type: "specific"
      },
      combined: {
        seriesNames: arrayCenters,
        keyData: "availability",
        keyExpected: null,
        type: "specific"
      }
    },
    daily: {
      simple: metadataAvailability,
      combined: metadataAvailability
    },
    monthly: {
      simple: metadataAvailability,
      combined: metadataAvailability
    }
  },
  quality: {
    six_hour: {
      simple: {
        seriesNames: [
          {
            name: "avg_stra",
            label: "Stratosphere O-B avg",
            // color is updated later
            color: null,
            representAs: "lines",
            tooltip: "line"
          },
          {
            name: "avg_trop",
            label: "Troposphere O-B avg",
            // color is updated later
            color: null,
            representAs: "lines",
            tooltip: "line"
          },
          {
            name: "std_stra",
            label: "Stratosphere O-B std",
            // color is updated later
            color: null,
            representAs: "dashlines",
            tooltip: "dashline"
          },
          {
            name: "std_trop",
            label: "Troposphere O-B std",
            // color is updated later
            color: null,
            representAs: "dashlines",
            tooltip: "dashline"
          }
        ],
        keyData: null,
        keyExpected: null,
        type: "specific"
      },
      combined: {
        seriesNames: arrayCenters,
        keyData: "rms",
        keyExpected: null,
        type: "specific"
      }
    },
    daily: {
      simple: {
        seriesNames: [
          {
            name: "avg_stra",
            label: "Daily avg Stra O-B avg",
            // color is updated later
            color: null,
            representAs: "lines",
            tooltip: "line"
          },
          {
            name: "avg_trop",
            label: "Daily avg Trop O-B avg",
            // color is updated later
            color: null,
            representAs: "lines",
            tooltip: "line"
          },
          {
            name: "std_stra",
            label: "Daily avg Stra O-B std",
            // color is updated later
            color: null,
            representAs: "dashlines",
            tooltip: "dashline"
          },
          {
            name: "std_trop",
            label: "Daily avg Trop O-B std",
            // color is updated later
            color: null,
            representAs: "dashlines",
            tooltip: "dashline"
          }
        ],
        keyData: null,
        keyExpected: null,
        type: "specific"
      },
      combined: {
        seriesNames: arrayCenters,
        keyData: "rms",
        keyExpected: null,
        type: "generic"
      }
    },
    monthly: {
      simple: {
        seriesNames: [
          {
            name: "avg_stra",
            label: "Monthly avg Stra O-B avg",
            // color is updated later
            color: null,
            representAs: "lines",
            tooltip: "line"
          },
          {
            name: "avg_trop",
            label: "Monthly avg Trop O-B avg",
            // color is updated later
            color: null,
            representAs: "lines",
            tooltip: "line"
          },
          {
            name: "std_stra",
            label: "Monthly avg Stra O-B std",
            // color is updated later
            color: null,
            representAs: "dashlines",
            tooltip: "dashline"
          },
          {
            name: "std_trop",
            label: "Monthly avg Trop O-B std",
            // color is updated later
            color: null,
            representAs: "dashlines",
            tooltip: "dashline"
          }
        ],
        keyData: null,
        keyExpected: null,
        type: "specific"
      },
      combined: {
        seriesNames: arrayCenters,
        keyData: "rms",
        keyExpected: null,
        type: "generic"
      }
    }
  }
};

const gbon_temp_obj = {
  availability: {
    six_hour: {
      simple: {
        seriesNames: arrayCenters,
        keyData: "availability",
        keyExpected: null,
        type: "specific"
      },
      combined: {
        seriesNames: arrayCenters,
        keyData: "availability",
        keyExpected: null,
        type: "specific"
      }
    },
    daily: {
      simple: metadataAvailability,
      combined: metadataAvailability
    },
    monthly: {
      simple: metadataAvailability,
      combined: metadataAvailability
    }
  }
};
const chartsMetadata = {
  synop: synop_obj,
  temp: temp_obj,
  buoy: buoy_obj,
  ship: ship_obj,
  marine_surface: marine_surface_obj,
  gbon_synop: gbon_synop_obj,
  gbon_temp: gbon_temp_obj
};

export const getChartMetadata = (
  fileType,
  reportType,
  periodType,
  center,
  variable,
  mode,
  yAxisLable
) => {
  // IMPORTANT: deep copy
  const obj = cloneDeep(chartsMetadata[fileType][reportType][periodType][mode]);
  // update center's name if it is null
  if (!obj.seriesNames[0].name) {
    obj.seriesNames[0].name = center.toLowerCase();
  }
  // for TEMP/Quality/Six-hour or Daily/Simple
  // the colors are based on the center
  if (
    fileType === "temp" &&
    reportType === "quality" &&
    (periodType === "six_hour" ||
      periodType === "daily" ||
      periodType === "monthly") &&
    mode === "simple"
  ) {
    obj.seriesNames.forEach(element => {
      element.color = getColorByLevelAndCenter(
        center.toLowerCase(),
        element.name
      );
    });
  }

  // add the property 'metadataYAxis' to the object
  obj.metadataYAxis = getMetadataYAxis(
    fileType,
    reportType,
    periodType,
    variable,
    mode,
    yAxisLable
  );
  return obj;
};

const getColorByLevelAndCenter = (center, name) => {
  const colorsObj = colors.chart.levels[center];
  let color;
  if (name === "avg_stra" || name === "std_stra") {
    color = colorsObj.stra;
  } else if (name === "avg_trop" || name === "std_trop") {
    color = colorsObj.trop;
  }

  return color;
};

// exported only for test
export const getQualityChartYAxisLabel = (mode, base, fileType, variable) => {
  const unit = reports[fileType].quality.filter(s => s.code === variable)[0]
    .unit;

  let output;
  if (mode === "simple") {
    output = `${base} (${unit})`;
  } else if (mode === "detailed") {
    const shortName = reports[fileType].quality.filter(
      s => s.code === variable
    )[0].shortName;
    output = `${shortName} ${base} (${unit})`;
  } else if (mode === "detailed_name") {
    const name = reports[fileType].quality.filter(s => s.code === variable)[0]
      .name;
    output = `${name} ${base} (${unit})`;
  } else if (mode === "simple_no_unit") {
    output = `${base}`;
  }
  return output;
};

const getMetadataYAxis = (
  fileType,
  reportType,
  periodType,
  variable,
  mode,
  yAxisLable
) => {
  let obj;
  if (
    fileType === "synop" ||
    fileType === "ship" ||
    fileType === "buoy" ||
    fileType === "marine_surface"
  ) {
    if (reportType === "availability") {
      obj = {
        min: 0,
        label: "Nb. Obs",
        format: "d",
        y0: false
      };
    } else if (reportType === "quality" && periodType === "six_hour") {
      obj = {
        min: null,
        label: getQualityChartYAxisLabel("simple", "O-B.", fileType, variable),
        format: ".1f",
        y0: true
      };
    } else if (reportType === "quality" && periodType === "daily") {
      obj = {
        min: null,
        label: getQualityChartYAxisLabel(
          "simple",
          "Daily average O-B",
          fileType,
          variable
        ),
        format: ".1f",
        y0: true
      };
    } else if (
      reportType === "quality" &&
      periodType === "monthly" &&
      yAxisLable === "gross_error_percent" &&
      mode === "combined"
    ) {
      obj = {
        min: 0,
        max: 100,
        label: getQualityChartYAxisLabel(
          "simple_no_unit",
          "Percentage of Gross Errors",
          fileType,
          variable
        ),
        format: ".1f",
        y0: true
      };
    } else if (
      reportType === "quality" &&
      periodType === "monthly" &&
      mode === "combined"
    ) {
      obj = {
        min: null,
        label: getQualityChartYAxisLabel(
          "simple",
          "Monthly RMS error",
          fileType,
          variable
        ),
        format: ".1f",
        y0: true
      };
    } else if (reportType === "quality" && periodType === "monthly") {
      obj = {
        min: null,
        label: getQualityChartYAxisLabel(
          "detailed_name",
          "O-B departures",
          fileType,
          variable
        ),
        format: ".1f",
        y0: true
      };
    } else if (reportType === "quality" && periodType === "alert") {
      if (mode === "combined") {
        obj = {
          min: null,
          label: getQualityChartYAxisLabel(
            "simple",
            "5 day average O-B",
            fileType,
            variable
          ),
          format: ".1f",
          y0: true
        };
      } else {
        obj = {
          min: null,
          label: getQualityChartYAxisLabel(
            "simple",
            "Average O-B",
            fileType,
            variable
          ),
          format: ".1f",
          y0: true
        };
      }
    }
  } else if (fileType === "temp") {
    if (
      reportType === "availability" &&
      (periodType === "daily" || periodType === "monthly")
    ) {
      obj = {
        min: 0,
        label: "Nb. Obs",
        format: "d",
        y0: false
      };
    } else if (reportType === "availability" && periodType === "six_hour") {
      obj = {
        min: 0,
        label: "% of completeness",
        format: "d",
        y0: false
      };
    } else if (reportType === "quality" && periodType === "daily") {
      if (mode === "combined") {
        obj = {
          min: null,
          label: getQualityChartYAxisLabel(
            "simple",
            "Daily RMS error",
            fileType,
            variable
          ),
          format: ".1f",
          y0: true
        };
      } else {
        obj = {
          min: null,
          label: getQualityChartYAxisLabel(
            "detailed",
            "O-B departures",
            fileType,
            variable
          ),
          format: ".1f",
          y0: true
        };
      }
    } else if (reportType === "quality" && periodType === "monthly") {
      if (mode === "combined") {
        obj = {
          min: null,
          label: getQualityChartYAxisLabel(
            "simple",
            "Monthly RMS error",
            fileType,
            variable
          ),
          format: ".1f",
          y0: true
        };
      } else {
        obj = {
          min: null,
          label: getQualityChartYAxisLabel(
            "detailed_name",
            "O-B departures",
            fileType,
            variable
          ),
          format: ".1f",
          y0: true
        };
      }
    } else if (reportType === "quality" && periodType === "six_hour") {
      if (mode === "combined") {
        obj = {
          min: null,
          label: getQualityChartYAxisLabel(
            "simple",
            "Six-hour RMS error",
            fileType,
            variable
          ),
          format: ".1f",
          y0: true
        };
      } else {
        obj = {
          min: null,
          label: getQualityChartYAxisLabel(
            "detailed",
            "O-B departures",
            fileType,
            variable
          ),
          format: ".1f",
          y0: true
        };
      }
    }
  }
  return obj;
};

export const getChartTitle = (fileType, props) => {
  let title;

  if (fileType === "synop" || fileType === "temp") {
    const center = props.selectedCenter;
    const variable = reports[fileType][props.selectedReport].filter(
      s => s.code === props.selectedVariable
    )[0].shortName;

    if (fileType === "synop") {
      if (props.selectedReport === "availability") {
        if (props.selectedPeriodType === "six_hour") {
          if (props.combinedMode) {
            title = `Six-hour availability - ${variable} - all centers`;
          } else {
            title = `Six-hour availability - ${variable} - ${center}`;
          }
        } else if (props.selectedPeriodType === "daily") {
          if (props.combinedMode) {
            title = `Daily availability - ${variable} - all centers`;
          } else {
            title = `Daily availability - ${variable} - ${center}`;
          }
        } else if (props.selectedPeriodType === "monthly") {
          if (props.combinedMode) {
            title = `Monthly availability - ${variable} - all centers`;
          } else {
            title = `Monthly availability - ${variable} - ${center}`;
          }
        }
      } else if (props.selectedReport === "quality") {
        if (props.selectedPeriodType === "six_hour") {
          if (props.combinedMode) {
            title = `Six-hour quality - ${variable} - all centers`;
          } else {
            title = `Six-hour quality - ${variable} - ${center}`;
          }
        } else if (props.selectedPeriodType === "daily") {
          if (props.combinedMode) {
            title = `Daily quality - ${variable} - all centers`;
          } else {
            title = `Daily quality - ${variable} - ${center}`;
          }
        } else if (props.selectedPeriodType === "alert") {
          if (props.combinedMode) {
            title = `Alert - ${variable} - all centers`;
          } else {
            title = `Alert - ${variable} - ${center}`;
          }
        } else if (props.selectedPeriodType === "monthly") {
          if (props.combinedMode) {
            title = `Monthly Quality - ${variable} - all centers`;
          } else {
            title = `Monthly Quality - ${variable} - ${center}`;
          }
        }
      }
    }
    // TEMP
    else if (props.selectedReport === "availability") {
      if (props.selectedPeriodType === "six_hour") {
        if (props.combinedMode) {
          title = "Percentage of completeness - all centers";
        } else {
          title = `Percentage of completeness - ${center}`;
        }
      } else if (props.selectedPeriodType === "daily") {
        if (props.combinedMode) {
          title = "Daily availability - all centers";
        } else {
          title = `Daily availability - ${center}`;
        }
      } else if (props.selectedPeriodType === "monthly") {
        if (props.combinedMode) {
          title = "Monthly availability - all centers";
        } else {
          title = `Monthly availability - ${center}`;
        }
      }
    } else if (props.selectedReport === "quality") {
      if (props.selectedPeriodType === "six_hour") {
        if (props.combinedMode) {
          title = `Six-hour quality - ${variable} - ${props.level} - all centers`;
        } else {
          title = `Six-hour quality - ${variable} - ${center}`;
        }
      } else if (props.selectedPeriodType === "daily") {
        if (props.combinedMode) {
          title = `Daily quality - ${variable} - ${props.level} - all centers`;
        } else {
          title = `Daily quality - ${variable} - ${center}`;
        }
      } else if (props.selectedPeriodType === "monthly") {
        if (props.combinedMode) {
          title = `Monthly quality - ${variable} - ${props.level} - all centers`;
        } else {
          title = `Monthly quality - ${variable} - ${center}`;
        }
      }
    }
  }
  if (fileType === "gsn" || fileType === "guan") {
    title =
      props.selectedReport.charAt(0).toUpperCase() +
      props.selectedReport.slice(1);
  }

  return title;
};
