import React from "react";

import { URL_OSCAR_SURFACE_SEARCH } from "../../../utils/api";

const LinkOSCAR = props => {
  return (
    <a
      className="link-oscar"
      target="_blank"
      rel="noopener noreferrer"
      href={URL_OSCAR_SURFACE_SEARCH + props.wigosid}
    >
      Open in OSCAR
    </a>
  );
};

export default LinkOSCAR;
