export const makeFilterQualityEdge = (field, sign, val) => {
  return [
    "any",
    // the order is important
    ["==", ["get", field], null],
    [sign, ["get", field], val]
  ];
};

export const makeFilterQualityMiddle = (field, min, max) => {
  return [
    "any",
    // the order is important
    ["==", ["get", field], null],
    ["<=", ["get", field], min],
    [">", ["get", field], max]
  ];
};
