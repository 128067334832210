import React from "react";
import "./Header.css";
import logoWMO from "../../images/logos/logo_wmo.png";
import logoECMWF from "../../images/logos/logo_ecmwf.png";

var test_version = "";
if (window.location.hostname !== "wdqms.wmo.int") {
  const testStyle = {
    color: "red",
    fontSize: "small"
  };
  test_version = <span style={testStyle}>(Test Version)</span>;
}

const Header = props => {
  return (
    <header className="wdqms-header">
      <img src={logoWMO} id="logo-wmo" className="logo" alt="logo WMO" />
      <h1 className="full-text">
        WIGOS Data Quality Monitoring System {test_version}
      </h1>
      <h1 className="short-text">WDQMS {test_version}</h1>
      <img src={logoECMWF} id="logo-ecmwf" className="logo" alt="logo ECMWF" />
    </header>
  );
};

export default Header;
