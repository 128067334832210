import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import Home from "../components/Home/Home";
import About from "../components/About/About";
import MapContainer from "../components/MapContainer/MapContainer";
import DatabaseStatus from "../components/DatabaseStatus/DatabaseStatus";
import ErrorParamsURL from "../components/ErrorParamsURL/ErrorPramsURL";

const Router = () => {
  return (
    <Switch>
      {/*  This first route is necessary because the prototype
        was accessible at: http://wdqms.wmo.int/wdqms/
        With this route we redirect /wdqms/* to / so that 
        users who have bookmarked the link get redirected to the home page
         */}
      <Route path="/wdqms" render={() => <div />}>
        <Redirect to="/" />
      </Route>
      <Route exact path="/" render={() => <Home />}></Route>
      <Route exact path="/about" render={() => <About />}></Route>
      {/* 3 routes for SYNOP:
        - one with all the parameters needed (correct)
        - one with no parameter, will be changed to the URL with all the default parameters (correct)
        - one with some parameters (less or more than the number needed) (not correct)
        */}
      {/* SYNOP */}
      <Route
        exact
        // 'period' is optional
        path={`/nwp/land_surface/:periodType/:report/:variable/:center/:date/:period?`}
        render={props => (
          <MapContainer
            fileType="synop"
            baseline="OSCAR"
            params={props.match.params}
          />
        )}
      />
      <Route
        exact
        path="/nwp/land_surface"
        render={() => (
          <MapContainer fileType="synop" baseline="OSCAR" params={{}} />
        )}
      />
      {/* The 2 redirections below are necessary because in November 2020
            we changed the SYNOP URLs from:
            - /nwp/synop/...
            to:
            - /nwp/land_surface/... 
            They allow the old URLs to still be valid and redirect to the new ones*/}
      <Redirect
        from="/nwp/synop/:periodType/:report/:variable/:center/:date/:period?"
        to="/nwp/land_surface/:periodType/:report/:variable/:center/:date/:period?"
      />
      <Redirect from="/nwp/synop" to="/nwp/land_surface" />
      <Route path="/nwp/land_surface/*" render={() => <ErrorParamsURL />} />
      {/* TEMP */}
      {/* 4 routes for TEMP:
        - one for Availability with all the parameters needed, don't contain the variable (correct)
        - one for Quality with all the parameters needed, contain the variable (correct)
        - one with no parameter, will be changed to the URL with all the default parameters (correct)
        - one with some parameters (less or more than the number needed) (not correct)
        */}
      <Route
        exact
        // 'period' is optional
        path={`/nwp/land_upper-air/:periodType/availability/:center/:date/:period?`}
        render={props => (
          <MapContainer
            fileType="temp"
            baseline="OSCAR"
            params={props.match.params}
          />
        )}
      />
      <Route
        exact
        // 'period' is optional
        path={`/nwp/land_upper-air/:periodType/quality/:variable/:center/:date/:period?`}
        render={props => (
          <MapContainer
            fileType="temp"
            baseline="OSCAR"
            params={props.match.params}
          />
        )}
      />
      <Route
        exact
        path="/nwp/land_upper-air"
        render={() => (
          <MapContainer fileType="temp" baseline="OSCAR" params={{}} />
        )}
      />
      {/* The 3 redirections below are necessary because in November 2020
            we changed the TEMP URLs from:
            - /nwp/temp/...
            to:
            - /nwp/land_upper-air/... 
            They allow the old URLs to still be valid and redirect to the new ones*/}
      <Redirect
        from="/nwp/temp/:periodType/availability/:center/:date/:period?"
        to="/nwp/land_upper-air/:periodType/availability/:center/:date/:period?"
      />
      <Redirect
        from="/nwp/temp/:periodType/quality/:variable/:center/:date/:period?"
        to="/nwp/land_upper-air/:periodType/quality/:variable/:center/:date/:period?"
      />
      <Redirect from="/nwp/temp" to="/nwp/land_upper-air" />
      <Route path="/nwp/land_upper-air/*" render={() => <ErrorParamsURL />} />

      {/* GUAN */}
      <Route
        exact
        path={`/gcos/land_upper-air/availability/:date`}
        render={props => (
          <MapContainer fileType="guan" params={props.match.params} />
        )}
      />
      <Route
        exact
        path={`/gcos/land_upper-air/quality/:variable/:date`}
        render={props => (
          <MapContainer fileType="guan" params={props.match.params} />
        )}
      />
      <Route
        exact
        path="/gcos/land_upper-air"
        render={() => <MapContainer fileType="guan" params={{}} />}
      />
      <Route path="/gcos/land_upper-air/*" render={() => <ErrorParamsURL />} />
      {/* GSN */}
      <Route
        exact
        path={`/gcos/land_surface/availability/:date`}
        render={props => (
          <MapContainer fileType="gsn" params={props.match.params} />
        )}
      />
      <Route
        exact
        path={`/gcos/land_surface/completeness/:variable/:date`}
        render={props => (
          <MapContainer fileType="gsn" params={props.match.params} />
        )}
      />
      <Route
        exact
        path="/gcos/land_surface"
        render={() => <MapContainer fileType="gsn" params={{}} />}
      />
      <Route path="/gcos/land_surface/*" render={() => <ErrorParamsURL />} />
      <Route path="/gcos/*" render={() => <ErrorParamsURL />} />
      {/* GBON SYNOP */}
      <Route
        exact
        // 'period' is optional
        path={`/gbon/land_surface/:periodType/:report/:variable/:center/:date/:period?`}
        render={props => (
          <MapContainer
            fileType="synop"
            baseline="GBON"
            params={props.match.params}
          />
        )}
      />
      <Route
        exact
        path="/gbon/land_surface"
        render={() => (
          <MapContainer fileType="synop" baseline="GBON" params={{}} />
        )}
      />
      <Redirect
        from="/gbon/synop/:periodType/:report/:variable/:center/:date/:period?"
        to="/gbon/land_surface/:periodType/:report/:variable/:center/:date/:period?"
      />
      <Redirect from="/gbon/synop" to="/gbon/land_surface" />
      <Route path="/gbon/land_surface/*" render={() => <ErrorParamsURL />} />
      {/* GBON TEMP */}
      <Route
        exact
        // 'period' is optional
        path={`/gbon/land_upper-air/:periodType/availability/:center/:date/:period?`}
        render={props => (
          <MapContainer
            fileType="temp"
            baseline="GBON"
            params={props.match.params}
          />
        )}
      />
      <Route
        exact
        path="/gbon/land_upper-air"
        render={() => (
          <MapContainer fileType="temp" baseline="GBON" params={{}} />
        )}
      />
      {/* The 3 redirections below are necessary because in November 2020
            we changed the TEMP URLs from:
            - /gbon/temp/...
            to:
            - /gbon/land_upper-air/... 
            They allow the old URLs to still be valid and redirect to the new ones*/}
      <Redirect
        from="/gbon/temp/:periodType/availability/:center/:date/:period?"
        to="/gbon/land_upper-air/:periodType/availability/:center/:date/:period?"
      />
      <Redirect from="/gbon/temp" to="/gbon/land_upper-air" />
      <Route path="/gbon/land_upper-air/*" render={() => <ErrorParamsURL />} />
      <Route
        exact
        path="/database-status/:module"
        render={props => <DatabaseStatus params={props.match.params} />}
      ></Route>

      {/* BUOY */}
      <Route
        exact
        // 'period' is optional
        path={`/nwp/buoy/:periodType/quality/:variable/:center/:date/:period?`}
        render={props => (
          <MapContainer
            fileType="buoy"
            baseline=""
            params={props.match.params}
          />
        )}
      />
      <Route
        exact
        path="/nwp/buoy"
        render={() => (
          <MapContainer fileType="buoy" baseline="OSCAR" params={{}} />
        )}
      />
      <Route path="/nwp/buoy/*" render={() => <ErrorParamsURL />} />

      {/* SHIP */}
      <Route
        exact
        // 'period' is optional
        path={`/nwp/ship/:periodType/quality/:variable/:center/:date/:period?`}
        render={props => (
          <MapContainer
            fileType="ship"
            baseline=""
            params={props.match.params}
          />
        )}
      />
      <Route
        exact
        path="/nwp/ship"
        render={() => (
          <MapContainer fileType="ship" baseline="OSCAR" params={{}} />
        )}
      />
      <Route path="/nwp/ship/*" render={() => <ErrorParamsURL />} />

      {/* marine_surface */}
      <Route
        exact
        // 'period' is optional
        path={`/nwp/marine_surface/:periodType/quality/:variable/:center/:date/:period?`}
        render={props => (
          <MapContainer
            fileType="marine_surface"
            baseline=""
            params={props.match.params}
          />
        )}
      />
      <Route
        exact
        path="/nwp/marine_surface"
        render={() => (
          <MapContainer
            fileType="marine_surface"
            baseline="OSCAR"
            params={{}}
          />
        )}
      />
      <Route path="/nwp/marine_surface/*" render={() => <ErrorParamsURL />} />

      {/* <Route
        exact
        path="/database-status"
        render={() => <DatabaseStatus />}
      ></Route> */}

      {/* This redirect is needed for historical reason 
      because before there was only 1 database staus page
      for NWP which was at '/database-status' */}
      <Redirect from="/database-status" to="/database-status/nwp" />

      <Route path="/nwp/*" render={() => <ErrorParamsURL />} />
      <Route path="/gbon/*" render={() => <ErrorParamsURL />} />
      <Route path="/*" render={() => <ErrorParamsURL />} />
    </Switch>
  );
};

export default Router;
