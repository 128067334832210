import React from "react";
import { connect } from "react-redux";
import ReactGA from "react-ga";
import * as actions from "../../../store/actions/actions";

import { Tooltip } from "antd";
import { getMapStyle, clearLegend } from "../../../utils/map";

import "antd/lib/tooltip/style/index.css";
import "./BaselineSwitch.css";

const BaselineSwitch = props => {
  const ftype = props[props.fileType];

  // for TEMP/Six-hour/Availability make the switch inactive
  let classActivity = "baseline-inactive";
  if (
    (props.fileType === "synop" && ftype.selectedReport === "availability") ||
    (props.fileType === "temp" &&
      ftype.selectedReport === "availability" &&
      (ftype.selectedPeriodType === "daily" ||
        ftype.selectedPeriodType === "monthly"))
  ) {
    classActivity = "baseline-active";
  }
  let radioValue = "hourly";
  if (props.fileType === "temp") {
    radioValue = "2-daily";
  }
  let tooltipValue = `Expected number of observations based on ${radioValue.toLowerCase()} reporting`;
  const classesElement = ["options-baseline", classActivity].join(" ");

  return (
    <div className="menu-element switch-container">
      <div className="title-baseline">Baseline </div>
      <div className={classesElement}>
        <input
          type="radio"
          id="oscar"
          checked={ftype.baseline === "OSCAR"}
          value="OSCAR"
          onChange={props.onChangeBaseline}
        ></input>
        <label
          htmlFor="oscar"
          className={ftype.baseline === "OSCAR" ? "baseline-selected" : ""}
        >
          <Tooltip
            placement="rightTop"
            title="Expected number of observations based on OSCAR/Surface."
          >
            Oscar
          </Tooltip>
        </label>
        <input
          type="radio"
          id="gbon"
          checked={ftype.baseline === "GBON"}
          value="GBON"
          onChange={props.onChangeBaseline}
        ></input>
        <label
          htmlFor="gbon"
          className={ftype.baseline === "GBON" ? "baseline-selected" : ""}
        >
          <Tooltip placement="rightTop" title={tooltipValue}>
            {radioValue}
          </Tooltip>
        </label>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    map: state.map,
    synop: state.synop,
    temp: state.temp,
    activeLayerName: state.activeLayerName
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChangeBaseline: event => {
      return dispatch((_, getState) => {
        const currentState = getState();
        let objFType = currentState[ownProps.fileType];

        // remove the filter if any
        currentState.map.setFilter(currentState.activeLayerName, undefined);
        clearLegend();
        getMapStyle(
          ownProps.fileType,
          objFType.selectedReport,
          objFType.selectedVariable,
          objFType.selectedPeriodType,
          objFType.selectedDate,
          event.target.value
        );

        const style = getMapStyle(
          ownProps.fileType,
          objFType.selectedReport,
          objFType.selectedVariable,
          objFType.selectedPeriodType,
          objFType.selectedDate,
          event.target.value
        );
        currentState.map.setPaintProperty(
          currentState.activeLayerName,
          "circle-color",
          style
        );

        dispatch(
          actions.setProperty({
            type: ownProps.fileType,
            key: "baseline",
            value: event.target.value
          })
        );
        // Add Google analytics event to track click on baseline switch
        if (window.location.hostname !== "localhost") {
          ReactGA.event({
            category: "User Interaction",
            action: "Change value of baseline switch",
            label: event.target.value
          });
        }
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BaselineSwitch);
