import React from "react";

import { niceDate } from "../utils";

import "./DBFooter.css";

const DBFooter = props => {
  let currentPeriod;
  let periodPrev;
  let periodNext;
  // for NWP we display the month and year
  if (props.metadata.module === "nwp" || props.metadata.module === "gbon") {
    currentPeriod = niceDate(props.periods[props.index]);
    if (props.index < props.periods.length - 1) {
      periodPrev = niceDate(props.periods[props.index + 1]);
    }
    if (props.index > 0) {
      periodNext = niceDate(props.periods[props.index - 1]);
    }
  } else {
    // for GCOS, only the year
    currentPeriod = props.periods[props.index].split("-")[0];
    if (props.index < props.periods.length - 1) {
      periodPrev = props.periods[props.index + 1].split("-")[0];
    }
    if (props.index > 0) {
      periodNext = props.periods[props.index - 1].split("-")[0];
    }
  }

  const buttonPrev =
    props.index < props.periods.length - 1 ? (
      <Button
        type="prev"
        period={periodPrev}
        onUpdateIndex={props.onUpdateIndex}
        metadata={props.metadata}
        index={props.index}
      />
    ) : null;
  const buttonNext =
    props.index > 0 ? (
      <Button
        type="next"
        period={periodNext}
        onUpdateIndex={props.onUpdateIndex}
        metadata={props.metadata}
        index={props.index}
      />
    ) : null;
  return (
    <>
      <div className="dbs-footer-item">{buttonPrev}</div>
      <div className="dbs-footer-item current-period">{currentPeriod}</div>
      <div className="dbs-footer-item">{buttonNext}</div>
    </>
  );
};

export default DBFooter;

const Button = props => {
  const newState = { ...props.metadata.currentIndexes };
  let newIndex;
  if (props.type === "next") {
    newIndex = props.index - 1;
  } else {
    newIndex = props.index + 1;
  }

  return (
    <button
      className="button-periods"
      onClick={() => {
        newState[props.metadata.module][
          props.metadata.currentFileType
        ] = newIndex;
        props.onUpdateIndex(newState);
      }}
    >
      {props.type === "prev" ? (
        <span className="dbs-footer-arrow">&#8249;</span>
      ) : null}
      {props.period}{" "}
      {props.type === "next" ? (
        <span className="dbs-footer-arrow">&#8250;</span>
      ) : null}
    </button>
  );
};
