import { rangeBetweenDates } from "../../utils/dataAPIformatting/index";

export const listCenters = ["DWD", "ECMWF", "JMA", "NCEP"];

// exported only for test
export const formatDate = date => {
  const d = new Date(date);
  let month = String(d.getMonth() + 1);
  let day = String(d.getDate());
  const year = d.getFullYear();

  if (month.length < 2) {
    month = "0" + month;
  }
  if (day.length < 2) {
    day = "0" + day;
  }

  return [year, month, day].join("-");
};

// Returns an array containing the last day of each month
// between 'newestDate' and 'oldestDate'
// This is used for NWP
export const getListMonths = (newestDate, oldestDate) => {
  const listMonths = [newestDate];

  let nbrDays = new Date(newestDate).getDate();
  let dx = new Date(newestDate);
  dx.setDate(dx.getDate() - nbrDays);
  let lastDayPreviousMonth = formatDate(dx);

  while (lastDayPreviousMonth >= oldestDate) {
    listMonths.push(lastDayPreviousMonth);
    nbrDays = new Date(lastDayPreviousMonth).getDate();
    dx = new Date(lastDayPreviousMonth);
    dx.setDate(dx.getDate() - nbrDays);
    lastDayPreviousMonth = formatDate(dx);
  }
  return listMonths;
};

// Returns an array containing the last month of each year
// between 'newestDate' and 'oldestDate'
// This is used for GCOS
export const getListYears = (newestDate, oldestDate) => {
  const listYears = [newestDate];
  const newestYear = new Date(newestDate).getFullYear();
  const oldestYear = new Date(oldestDate).getFullYear();
  let currentYear = newestYear - 1;
  while (currentYear >= oldestYear) {
    const currentDate = `${currentYear}-12`;
    listYears.push(currentDate);

    currentYear -= 1;
  }
  return listYears;
};

// Given the object from the API containing all the available
// dates 'originalData' and the last date of a month 'lastDate'
// returns an array containing all the dates for that month
// for all centers
export const processNWPData = (originalData, lastDate) => {
  const templateArray = {};

  // Get the number of days between 'lastDate' and
  // the first day of the month of 'lastDate'
  const nbrDays = new Date(lastDate).getDate() - 1;

  // Get all the dates between the 2 dates
  let arrDates = [];
  // If lastDate is the first day of the month, only include this day
  if (nbrDays === 0) {
    arrDates = [lastDate];
  } else {
    // we use reverse so that the most recent date is the first element
    arrDates = rangeBetweenDates(lastDate, nbrDays, "", []).reverse();
  }

  // for each date add the template object that will be updated below
  arrDates.forEach(date => {
    // .substring(0, 10) to only keep the date part
    templateArray[date.substring(0, 10)] = {};
    for (let index = 0; index < listCenters.length; index++) {
      const element = listCenters[index];
      templateArray[date.substring(0, 10)][element] = {
        "00": false,
        "06": false,
        "12": false,
        "18": false
      };
    }
  });

  // Go through all properties of 'originalData' except 'All'
  // to update the arrays of boolean values
  for (const center in originalData) {
    if (center !== "all") {
      for (const i in arrDates) {
        const date = arrDates[i].substring(0, 10);
        for (const period in originalData[center][date]) {
          if (originalData[center][date][period].available) {
            templateArray[date][center][period] = true;
          }
        }
      }
    }
  }
  return templateArray;
};

// Given an array `originalData` of dates in the format 'yyyy-mm'
// and a string date `lastDate` also in the format 'yyyy-mm', returns
// an object whose keys are all the dates in the format 'yyyy-mm'
// older or equal to `lastDate` and from the same year. The value is
// true if the month is in the array `originalData`, false otherwise
export const processGCOSData = (originalData, lastDate) => {
  const templateArray = {};
  const lastDateParts = lastDate.split("-");
  const year = lastDateParts[0];
  const month = parseInt(lastDateParts[1], 10);
  for (let i = month; i >= 1; i--) {
    const currentMonth = i >= 10 ? i : `0${i}`;
    const currentDate = `${year}-${currentMonth}`;
    const currentValue = originalData.includes(currentDate);
    templateArray[currentDate] = currentValue;
  }

  return templateArray;
};

// Given a string 'd' in the format 'yyyy-mm-dd'
// return a string containing the month and year
// in the format 'Month yyyy'
export const niceDate = d => {
  const date = new Date(d);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  return `${months[date.getMonth()]} ${date.getFullYear()}`;
};
