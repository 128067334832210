import { cloneDeep } from "lodash";
import { colors } from "../colors";
import { rangeMonthsBetweenDates } from "./index";

// This function returns a object with the following 3 properties:
//
// - series: an object that contains the series. The key is the
//   name of the serie. The value is an obect containing the 7 following propeties:
//     - data: An array of objects. Each object contains 2 mandatory properties (can contain others):
//       - x: the date
//       - y: the value
//
//     - max: The naximum value of the serie
//     - min: The minimum value of the serie
//     - label: The name of the serie to display
//     - color: The color of the serie to display
//     - representAs: how to represent the serie: 'points', 'lines' or 'dashlines'
//     - tooltip: how the serie should be represented in the tooltip: 'point', 'line' or 'dashline'
//
// - metadataYAxis: An object containing metadata about the Y axis
//   with the following 4 properties:
//     - min: Can be null or equal to '0'. If '0' the minimum value of the Y axis will be '0'.
//              If null, will be replaced later by the minimum value of all series
//     - label: The label of the Y axis to display
//     - format: D3 format for the values of the Y axis, integer or float. For example: "d" or ".1f"
//     - y0: boolean. If true, display a line y=0 on the chart
//
// - rangeX:  An array of all the dates between the oldest and the newest dates without gaps
//            If displaying six-hour periods, the interval between all dates is 6 hours
//            If displaying daily periods, the interval between all dates is 1 day
//            If displaying monthly periods, the interval between all dates is 1 month
//            This is used to build the X axis of the chart
export const prepareGcosDataForChart = (fileType, props) => {
  // get all the dates of all months during year back
  const allTimeSteps = rangeMonthsBetweenDates(props.selectedDate, 11);

  // An array of objects for all dates of the time-series
  // to use a template for all series
  const allTimeStepsNull = allTimeSteps.map(t => {
    let obj = {
      x: t,
      y: null
    };
    return obj;
  });

  let series = cloneDeep(template_obj[fileType][props.selectedReport]);

  // for each serie add the 3 properties
  // 'data', 'max' and 'min'
  for (var serie in series) {
    series[serie].data = [...allTimeStepsNull];
    series[serie].max = -Infinity;
    series[serie].min = Infinity;
  }

  props.chartData.forEach(function(d) {
    const indexToUpdate = allTimeSteps.indexOf(d.date);

    for (const serie in series) {
      let yValue = d[serie];
      // For GUAN/Availability a value of -1
      // means 'missing data' so we replace it with `null`
      // For GSN/Completeness a value of -1
      // means not provided, so we also replace it with `null`
      if (
        (fileType === "guan" &&
          props.selectedReport === "availability" &&
          d[serie] === -1) ||
        (fileType === "gsn" &&
          props.selectedReport === "completeness" &&
          d[serie] === -1)
      ) {
        yValue = null;
      }

      series[serie].data[indexToUpdate] = {
        x: d.date,
        y: yValue
      };

      // Calculate the minimum and maximum of each serie
      if (d[serie] > series[serie].max) {
        series[serie].max = d[serie];
      }
      if (d[serie] && d[serie] < series[serie].min) {
        series[serie].min = d[serie];
      }
    }
  });

  const metadataYAxis = templateMetadataYAxis[fileType][props.selectedReport];

  const dateFormats = { xAxis: "%b %Y", tooltip: "%b %Y" };

  return {
    series,
    dateFormats,
    metadataYAxis,
    rangeX: allTimeSteps
  };
};

const guan_obj = {
  availability: {
    availability: {
      label: "Availability",
      color: colors.chart.guan.availability,
      representAs: "lines",
      tooltip: "line"
    }
  },
  quality: {
    temperature: {
      label: "Temperature",
      color: colors.chart.guan.quality.temperature,
      representAs: "lines",
      tooltip: "line"
    },
    wind: {
      label: "Wind",
      color: colors.chart.guan.quality.wind,
      representAs: "lines",
      tooltip: "line"
    },
    humidity: {
      label: "Humidity",
      color: colors.chart.guan.quality.humidity,
      representAs: "lines",
      tooltip: "line"
    }
  }
};

const gsn_obj = {
  availability: null,
  completeness: {
    days_with_pressure: {
      label: "Pressure",
      color: colors.chart.gsn.completeness.days_with_pressure,
      representAs: "lines",
      tooltip: "line"
    },
    days_with_temperature: {
      label: "Temperature",
      color: colors.chart.gsn.completeness.days_with_temperature,
      representAs: "lines",
      tooltip: "line"
    },
    days_with_max_temperature: {
      label: "Max Temperature",
      color: colors.chart.gsn.completeness.days_with_max_temperature,
      representAs: "lines",
      tooltip: "line"
    },
    days_with_min_temperature: {
      label: "Min Temperature",
      color: colors.chart.gsn.completeness.days_with_min_temperature,
      representAs: "lines",
      tooltip: "line"
    },
    days_with_watervapor: {
      label: "Water vapor",
      color: colors.chart.gsn.completeness.days_with_watervapor,
      representAs: "lines",
      tooltip: "line"
    },
    days_with_precipitation: {
      label: "Precipitation",
      color: colors.chart.gsn.completeness.days_with_precipitation,
      representAs: "lines",
      tooltip: "line"
    },
    days_with_sunshine_duration: {
      label: "Sunshine duration",
      color: colors.chart.gsn.completeness.days_with_sunshine_duration,
      representAs: "lines",
      tooltip: "line"
    }
  }
};

const template_obj = {
  guan: guan_obj,
  gsn: gsn_obj
};

const templateMetadataYAxis = {
  guan: {
    availability: {
      min: 0,
      label: "#ascents achieving 30 hPa",
      format: "d",
      y0: false
    },
    quality: {
      min: null,
      label: "RMS",
      format: ".1f",
      y0: false
    }
  },
  gsn: {
    availability: null,
    completeness: {
      min: 0,
      label: "Number of days",
      format: "d",
      y0: false
    }
  }
};
