import { colors } from "../colors";

export const LegendGuanAvailability = {
  title: "Total number of ascents\nachieving 30 hPa",
  body: [
    {
      label: "\u2265 25",
      color: colors.map.guan.availability[0],
      filter: "cl-guan-avail-gt25"
    },
    {
      label: "< 25",
      color: colors.map.guan.availability[1],
      filter: "cl-guan-avail-lt25"
    },
    {
      label: "< 15",
      color: colors.map.guan.availability[2],
      filter: "cl-guan-avail-lt15"
    },
    {
      label: "0",
      color: colors.map.guan.availability[3],
      filter: "cl-guan-avail-zero"
    },
    {
      label: "Missing data",
      color: colors.map.guan.availability[4],
      filter: "cl-guan-avail-nodata",
      asterisk: "This refers to BUFR reports only"
    }
  ]
};

export const LegendGuanQualityHumidity = {
  title: "Observation and model differences\nRoot Mean Square Error (%)",
  body: [
    {
      label: "> 30",
      color: colors.map.guan.quality[5],
      filter: "cl-guan-quality-gt-30"
    },
    {
      label: "15 < x \u2264 30",
      color: colors.map.guan.quality[4],
      filter: "cl-guan-quality-gt-15-le-30"
    },
    {
      label: "10 < x \u2264 15",
      color: colors.map.guan.quality[3],
      filter: "cl-guan-quality-gt-10-le-15"
    },
    {
      label: "5 < x \u2264 10",
      color: colors.map.guan.quality[2],
      filter: "cl-guan-quality-gt-5-le-10"
    },
    {
      label: "2 < x \u2264 5",
      color: colors.map.guan.quality[1],
      filter: "cl-guan-quality-gt-2-le-5"
    },
    {
      label: "\u2264 2",
      color: colors.map.guan.quality[0],
      filter: "cl-guan-quality-le-2"
    }
  ]
};

export const LegendGuanQualityWind = {
  title: "Observation and model differences\nRoot Mean Square Error (m/s)",
  body: [
    {
      label: "> 15",
      color: colors.map.guan.quality[5],
      filter: "cl-guan-quality-gt-15"
    },
    {
      label: "8 < x \u2264 15",
      color: colors.map.guan.quality[4],
      filter: "cl-guan-quality-gt-8-le-15"
    },
    {
      label: "5 < x \u2264 8",
      color: colors.map.guan.quality[3],
      filter: "cl-guan-quality-gt-5-le-8"
    },
    {
      label: "3 < x \u2264 5",
      color: colors.map.guan.quality[2],
      filter: "cl-guan-quality-gt-3-le-5"
    },
    {
      label: "1 < x \u2264 3",
      color: colors.map.guan.quality[1],
      filter: "cl-guan-quality-gt-1-le-3"
    },
    {
      label: "\u2264 1",
      color: colors.map.guan.quality[0],
      filter: "cl-guan-quality-le-1"
    }
  ]
};

export const LegendGuanQualityTemperature = {
  title: "Observation and model differences\nRoot Mean Square Error (K)",
  body: [
    {
      label: "> 10",
      color: colors.map.guan.quality[5],
      filter: "cl-guan-quality-gt-10"
    },
    {
      label: "5 < x \u2264 10",
      color: colors.map.guan.quality[4],
      filter: "cl-guan-quality-gt-5-le-10"
    },
    {
      label: "3 < x \u2264 5",
      color: colors.map.guan.quality[3],
      filter: "cl-guan-quality-gt-3-le-5"
    },
    {
      label: "1 < x \u2264 3",
      color: colors.map.guan.quality[2],
      filter: "cl-guan-quality-gt-1-le-3"
    },
    {
      label: "0.5 < x \u2264 1",
      color: colors.map.guan.quality[1],
      filter: "cl-guan-quality-gt-0p5-le-1"
    },
    {
      label: "\u2264 0.5",
      color: colors.map.guan.quality[0],
      filter: "cl-guan-quality-le-0p5"
    }
  ]
};
