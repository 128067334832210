import React from "react";

import "./SearchResults.css";

const SearchResults = props => {
  let stationsList;

  // every element of 'props.elements' is an array containing:
  // - name (index = 0)
  // - wigosid (index=1)
  // - longitude
  // - latitude
  // - secondary_wigosids

  if (props.elements.length === 0) {
    stationsList = <div />;
  } else {
    const listElements = props.elements.map((suggestion, index) => {
      return (
        <li key={index} onClick={props.onClick} data-elementid={index}>
          {suggestion[0]} &ndash; {suggestion[1]}
          {suggestion[4] ? " {" + suggestion[4] + "}" : ""}
        </li>
      );
    });

    stationsList = <ul className="suggestions">{listElements}</ul>;
  }

  return <div className="container-search-result">{stationsList}</div>;
};

export default SearchResults;
