import { makeFilterQualityEdge, makeFilterQualityMiddle } from "./utils";
import { cl_synop_in_oscar } from "../mapStyles/synop";
import { getDaysInMonth } from "../../utils/general";

// For SYNOP and TEMP

// AVAILABILITY

export const getNWPAvailabilityClassFilter = (
  label,
  baseline,
  period_type,
  file_type,
  date
) => {
  let nbDays = getDaysInMonth(date);
  let valuePeriodType = 6; // for six_hour
  if (period_type === "daily") {
    valuePeriodType = 24;
  } else if (period_type === "monthly") {
    // monthly

    // valuePeriodType = ["get", "nr_full_days"];
    valuePeriodType = ["*", nbDays, 24];
  }
  if (file_type === "temp") {
    valuePeriodType = 2;
    if (period_type === "monthly") {
      // valuePeriodType = ["get", "nr_full_days"];
      valuePeriodType = ["*", nbDays, 2];
    }
  }
  let partBaseline =
    baseline === "OSCAR" ? ["get", "nr_expected"] : valuePeriodType;
  switch (label) {
    case "cl-not-oscar":
      return cl_synop_in_oscar;
    case "cl-avail-silent":
      return [
        "any",
        ["!=", ["get", "nr_received"], 0],
        ["!=", ["get", "in_oscar"], true]
      ];
    case "cl-synop-avail-big-issues":
      return [
        "any",
        [">=", ["/", ["get", "nr_received"], partBaseline], 0.3],
        ["<=", ["/", ["get", "nr_received"], partBaseline], 0],
        ["!=", ["get", "in_oscar"], true]
      ];

    case "cl-synop-avail-big-issues-hourly":
      return [
        "any",
        [">=", ["/", ["get", "nr_received"], partBaseline], 0.3],
        ["<=", ["/", ["get", "nr_received"], partBaseline], 0],
        ["==", ["get", "nr_received"], 0]
      ];
    case "cl-synop-avail-small-issues":
      return [
        "any",
        ["<", ["/", ["get", "nr_received"], partBaseline], 0.3],
        [">=", ["/", ["get", "nr_received"], partBaseline], 0.8],
        ["!=", ["get", "in_oscar"], true]
      ];
    case "cl-synop-avail-small-issues-hourly":
      return [
        "any",
        ["<", ["/", ["get", "nr_received"], partBaseline], 0.3],
        [">=", ["/", ["get", "nr_received"], partBaseline], 0.8],
        ["==", ["get", "nr_received"], 0]
      ];
    case "cl-synop-avail-normal":
      return [
        "any",
        ["<", ["/", ["get", "nr_received"], partBaseline], 0.8],
        [">", ["/", ["get", "nr_received"], partBaseline], 1],
        ["!=", ["get", "in_oscar"], true]
      ];
    case "cl-synop-avail-normal-hourly":
      return [
        "any",
        ["<", ["/", ["get", "nr_received"], partBaseline], 0.8],
        [">", ["/", ["get", "nr_received"], partBaseline], 1],
        ["==", ["get", "nr_received"], 0]
      ];
    // this case is more complicated
    case "cl-synop-avail-more-than-100p":
      if (baseline === "OSCAR") {
        return [
          "any",
          ["==", partBaseline, 0],
          ["<=", ["/", ["get", "nr_received"], partBaseline], 1],
          ["!=", ["get", "in_oscar"], true]
        ];
      }
      return [
        "any",

        ["<=", ["/", ["get", "nr_received"], partBaseline], 1],
        ["<=", ["get", "nr_full_days"], 0],
        ["!=", ["get", "in_oscar"], true]
      ];
    // this case is more complicated
    case "cl-synop-avail-more-than-100p-hourly":
      return [
        "any",

        ["<=", ["/", ["get", "nr_received"], partBaseline], 1],
        ["<=", ["get", "nr_full_days"], 0],
        ["==", ["get", "nr_received"], 0]
      ];
    // this case is more complicated
    case "cl-synop-avail-expected-zero":
      if (baseline === "OSCAR") {
        return [
          "any",
          [">", partBaseline, 0],
          ["==", ["get", "nr_received"], 0],
          ["!=", ["get", "in_oscar"], true]
        ];
      }
      return [
        "any",
        [">=", ["get", "nr_received"], 0],
        ["!=", ["get", "in_oscar"], true]
      ];

    // TEMP
    // // Six-hour
    case "cl-temp-avail-sixhour-all":
      return [
        "any",
        ["!=", ["get", "availability"], 3],
        ["!=", ["get", "in_oscar"], true]
      ];
    case "cl-temp-avail-sixhour-missing-var":
      return [
        "any",
        ["!=", ["get", "availability"], 2],
        ["!=", ["get", "in_oscar"], true]
      ];
    case "cl-temp-avail-sixhour-missing-level":
      return [
        "any",
        ["!=", ["get", "availability"], 1],
        ["!=", ["get", "in_oscar"], true]
      ];
    // Daily
    case "cl-synop-avail-daily-more-than-100p":
      return [
        "any",
        ["<=", ["/", ["get", "nr_received"], partBaseline], 1],
        ["!=", ["get", "availability"], 3],
        ["==", ["get", "nr_received"], 0],
        ["!=", ["get", "in_oscar"], true]
      ];
    case "cl-temp-avail-daily-more-than-100p":
      return [
        "any",
        ["<=", ["/", ["get", "nr_received"], partBaseline], 1],
        ["!=", ["get", "availability"], 3],
        ["!=", ["get", "in_oscar"], true]
      ];
    case "cl-temp-avail-daily-more-than-100p-2-daily":
      return [
        "any",
        ["<=", ["/", ["get", "nr_received"], partBaseline], 1],
        ["!=", ["get", "availability"], 3]
      ];
    case "cl-temp-avail-daily-all":
      return [
        "any",
        ["!=", ["/", ["get", "nr_received"], partBaseline], 1],
        ["!=", ["get", "availability"], 3],
        ["!=", ["get", "in_oscar"], true]
      ];
    case "cl-temp-avail-daily-all-2-daily":
      return [
        "any",
        ["!=", ["/", ["get", "nr_received"], partBaseline], 1],
        ["!=", ["get", "availability"], 3]
      ];
    case "cl-temp-avail-daily-missing-var":
      return [
        "any",
        ["<", ["/", ["get", "nr_received"], partBaseline], 1],
        ["==", ["get", "availability"], 3],
        ["!=", ["get", "in_oscar"], true]
      ];
    case "cl-temp-avail-daily-missing-var-2-daily":
      return [
        "any",
        ["<", ["/", ["get", "nr_received"], partBaseline], 1],
        ["==", ["get", "availability"], 3]
      ];
    case "cl-temp-avail-daily-missing-level":
      return [
        "any",
        ["==", ["get", "nr_received"], 0],
        [">=", ["/", ["get", "nr_received"], partBaseline], 1],
        ["!=", ["get", "in_oscar"], true]
      ];

    case "cl-temp-avail-daily-missing-level-2-daily":
      return [
        "any",
        ["==", ["get", "nr_received"], 0],
        [">=", ["/", ["get", "nr_received"], partBaseline], 1]
      ];
    // Monthly
    case "cl-temp-avail-monthly-more-than-100p":
      return [
        "any",
        ["<=", ["/", ["get", "nr_received"], partBaseline], 1],
        ["!=", ["get", "availability"], 3],
        ["<=", ["get", "nr_full_days"], 0],
        ["!=", ["get", "in_oscar"], true]
      ];
    case "cl-temp-avail-monthly-more-than-100p-2-daily":
      return [
        "any",
        ["<=", ["/", ["get", "nr_received"], partBaseline], 1],
        ["!=", ["get", "availability"], 3],
        ["<=", ["get", "nr_full_days"], 0]
      ];
    case "cl-temp-avail-monthly-all":
      return [
        "any",
        ["<", ["/", ["get", "nr_received"], partBaseline], 0.8],
        [">", ["/", ["get", "nr_received"], partBaseline], 1],
        ["==", ["get", "nr_received"], 0],
        ["!=", ["get", "in_oscar"], true]
      ];
    case "cl-temp-avail-monthly-all-2-daily":
      return [
        "any",
        ["<", ["/", ["get", "nr_received"], partBaseline], 0.8],
        [">", ["/", ["get", "nr_received"], partBaseline], 1],
        ["==", ["get", "nr_received"], 0]
      ];
    case "cl-temp-avail-monthly-missing-var":
      return [
        "any",
        ["<", ["/", ["get", "nr_received"], partBaseline], 0.3],
        [">=", ["/", ["get", "nr_received"], partBaseline], 0.8],
        ["==", ["get", "nr_received"], 0]
      ];
    case "cl-temp-avail-monthly-missing-var-2-daily":
      return [
        "any",
        ["<", ["/", ["get", "nr_received"], partBaseline], 0.3],
        [">=", ["/", ["get", "nr_received"], partBaseline], 0.8]
      ];
    case "cl-temp-avail-monthly-missing-level":
      return [
        "any",
        [">=", ["/", ["get", "nr_received"], partBaseline], 0.3],
        ["<=", ["/", ["get", "nr_received"], partBaseline], 0],
        ["==", ["get", "nr_received"], 0],
        ["!=", ["get", "in_oscar"], true]
      ];
    case "cl-temp-avail-monthly-missing-level-2-daily":
      return [
        "any",
        [">=", ["/", ["get", "nr_received"], partBaseline], 0.3],
        ["<=", ["/", ["get", "nr_received"], partBaseline], 0],
        ["==", ["get", "nr_received"], 0]
      ];
    default:
      return [];
  }
};

// Quality

export const getNWPQualityClassFilter = label => {
  switch (label) {
    case "cl-synop-quality-gt-100":
      return makeFilterQualityEdge("avg_bg_dep", "<=", 100);
    case "cl-synop-quality-gt-15":
      return makeFilterQualityEdge("avg_bg_dep", "<=", 15);
    case "cl-synop-quality-gt-10":
      return makeFilterQualityEdge("avg_bg_dep", "<=", 10);
    case "cl-synop-quality-gt-0p3":
      return makeFilterQualityEdge("avg_bg_dep", "<=", 0.3);
    case "cl-synop-quality-gt-40-le-100":
      return makeFilterQualityMiddle("avg_bg_dep", 40, 100);
    case "cl-synop-quality-gt-30-le-40":
      return makeFilterQualityMiddle("avg_bg_dep", 30, 40);
    case "cl-synop-quality-gt-5-le-15":
      return makeFilterQualityMiddle("avg_bg_dep", 5, 15);
    case "cl-synop-quality-gt-5-le-10":
      return makeFilterQualityMiddle("avg_bg_dep", 5, 10);
    case "cl-synop-quality-gt-3-le-5":
      return makeFilterQualityMiddle("avg_bg_dep", 3, 5);
    case "cl-synop-quality-gt-2-le-5":
      return makeFilterQualityMiddle("avg_bg_dep", 2, 5);
    case "cl-synop-quality-gt-2-le-3":
      return makeFilterQualityMiddle("avg_bg_dep", 2, 3);
    case "cl-synop-quality-gt-1-le-5":
      return makeFilterQualityMiddle("avg_bg_dep", 1, 5);
    case "cl-synop-quality-gt-1-le-2":
      return makeFilterQualityMiddle("avg_bg_dep", 1, 2);
    case "cl-synop-quality-gt-0p5-le-2":
      return makeFilterQualityMiddle("avg_bg_dep", 0.5, 2);
    case "cl-synop-quality-gt-0p5-le-1":
      return makeFilterQualityMiddle("avg_bg_dep", 0.5, 1);
    case "cl-synop-quality-gt-0p15-le-0p3":
      return makeFilterQualityMiddle("avg_bg_dep", 0.15, 0.3);
    case "cl-synop-quality-gt-0p1-le-0p15":
      return makeFilterQualityMiddle("avg_bg_dep", 0.1, 0.15);
    case "cl-synop-quality-gt-0p05-le-0p1":
      return makeFilterQualityMiddle("avg_bg_dep", 0.05, 0.1);
    case "cl-synop-quality-gt-0p02-le-0p05":
      return makeFilterQualityMiddle("avg_bg_dep", 0.02, 0.05);
    case "cl-synop-quality-le-30":
      return makeFilterQualityEdge("avg_bg_dep", ">", 30);
    case "cl-synop-quality-le-0p5":
      return makeFilterQualityEdge("avg_bg_dep", ">", 0.5);
    case "cl-synop-quality-le-0p02":
      return makeFilterQualityEdge("avg_bg_dep", ">", 0.02);
    // TEMP
    case "cl-temp-quality-gt-15":
      return makeFilterQualityEdge("rms", "<=", 15);
    case "cl-temp-quality-gt-10":
      return makeFilterQualityEdge("rms", "<=", 10);
    case "cl-temp-quality-gt-0p3":
      return makeFilterQualityEdge("rms", "<=", 0.3);
    case "cl-temp-quality-gt-8-le-15":
      return makeFilterQualityMiddle("rms", 8, 15);
    case "cl-temp-quality-gt-5-le-10":
      return makeFilterQualityMiddle("rms", 5, 10);
    case "cl-temp-quality-gt-5-le-8":
      return makeFilterQualityMiddle("rms", 5, 8);
    case "cl-temp-quality-gt-3-le-5":
      return makeFilterQualityMiddle("rms", 3, 5);
    case "cl-temp-quality-gt-1-le-3":
      return makeFilterQualityMiddle("rms", 1, 3);
    case "cl-temp-quality-gt-0p5-le-1":
      return makeFilterQualityMiddle("rms", 0.5, 1);
    case "cl-temp-quality-gt-0p15-le-0p3":
      return makeFilterQualityMiddle("rms", 0.15, 0.3);
    case "cl-temp-quality-gt-0p1-le-0p15":
      return makeFilterQualityMiddle("rms", 0.1, 0.15);
    case "cl-temp-quality-gt-0p05-le-0p1":
      return makeFilterQualityMiddle("rms", 0.05, 0.1);
    case "cl-temp-quality-gt-0p02-le-0p05":
      return makeFilterQualityMiddle("rms", 0.02, 0.05);
    case "cl-temp-quality-le-1":
      return makeFilterQualityEdge("rms", ">", 1);
    case "cl-temp-quality-le-0p5":
      return makeFilterQualityEdge("rms", ">", 0.5);
    case "cl-temp-quality-le-0p02":
      return makeFilterQualityEdge("rms", ">", 0.02);
    default:
      return [];
  }
};

// ALERT - TO UPDATE

export const getAlertSimpleClassFilter = label => {
  switch (label) {
    case "gt-100":
      return makeFilterAlertSimpleEdge("<=", 100);
    case "gt-15":
      return makeFilterAlertSimpleEdge("<=", 15);
    case "gt-10":
      return makeFilterAlertSimpleEdge("<=", 10);
    case "gt-0p3":
      return makeFilterAlertSimpleEdge("<=", 0.3);
    case "gt-40-le-100":
      return makeFilterAlertSimpleMiddle(40, 100);
    case "gt-30-le-40":
      return makeFilterAlertSimpleMiddle(30, 40);
    case "gt-5-le-15":
      return makeFilterAlertSimpleMiddle(5, 15);
    case "gt-5-le-10":
      return makeFilterAlertSimpleMiddle(5, 10);
    case "gt-3-le-5":
      return makeFilterAlertSimpleMiddle(3, 5);
    case "gt-2-le-5":
      return makeFilterAlertSimpleMiddle(2, 5);
    case "gt-2-le-3":
      return makeFilterAlertSimpleMiddle(2, 3);
    case "gt-1-le-5":
      return makeFilterAlertSimpleMiddle(1, 5);
    case "gt-1-le-2":
      return makeFilterAlertSimpleMiddle(1, 2);
    case "gt-0p5-le-2":
      return makeFilterAlertSimpleMiddle(0.5, 2);
    case "gt-0p5-le-1":
      return makeFilterAlertSimpleMiddle(0.5, 1);
    case "gt-0p15-le-0p3":
      return makeFilterAlertSimpleMiddle(0.15, 0.3);
    case "gt-0p1-le-0p15":
      return makeFilterAlertSimpleMiddle(0.1, 0.15);
    case "gt-0p05-le-0p1":
      return makeFilterAlertSimpleMiddle(0.05, 0.1);
    case "gt-0p02-le-0p05":
      return makeFilterAlertSimpleMiddle(0.02, 0.05);
    case "le-30":
      return makeFilterAlertSimpleEdge(">", 30);
    case "le-0p5":
      return makeFilterAlertSimpleEdge(">", 0.5);
    case "le-0p02":
      return makeFilterAlertSimpleEdge(">", 0.02);
    case "qual-no-data":
      return ["!=", ["get", "avg_bg_dep"], null];
    case "not-valid":
      return ["==", ["get", "valid"], true];
    default:
      return [];
  }
};

export const getCountryFilter = country_ids => {
  return [
    "any",
    ["==", ["in", ["get", "country_id"], ["literal", country_ids]], true]
  ];
};
const makeFilterAlertSimpleEdge = (sign, val) => {
  return [
    "any",
    // the order is important
    ["==", ["get", "avg_bg_dep"], null],
    [sign, ["get", "avg_bg_dep"], val],
    ["!=", ["get", "valid"], true]
  ];
};

const makeFilterAlertSimpleMiddle = (min, max) => {
  return [
    "any",
    // the order is important
    ["==", ["get", "avg_bg_dep"], null],
    ["<=", ["get", "avg_bg_dep"], min],
    [">", ["get", "avg_bg_dep"], max],
    ["!=", ["get", "valid"], true]
  ];
};

export const getAlertCombinedClassFilter = label => {
  switch (label) {
    case "gt-100":
      return makeFilterAlertCombinedEdge("<=", 100);
    case "gt-15":
      return makeFilterAlertCombinedEdge("<=", 15);
    case "gt-10":
      return makeFilterAlertCombinedEdge("<=", 10);
    case "gt-0p3":
      return makeFilterAlertCombinedEdge("<=", 0.3);
    case "gt-40-le-100":
      return makeFilterAlertCombinedMiddle(40, 100);
    case "gt-30-le-40":
      return makeFilterAlertCombinedMiddle(30, 40);
    case "gt-5-le-15":
      return makeFilterAlertCombinedMiddle(5, 15);
    case "gt-5-le-10":
      return makeFilterAlertCombinedMiddle(5, 10);
    case "gt-3-le-5":
      return makeFilterAlertCombinedMiddle(3, 5);
    case "gt-2-le-5":
      return makeFilterAlertCombinedMiddle(2, 5);
    case "gt-2-le-3":
      return makeFilterAlertCombinedMiddle(2, 3);
    case "gt-1-le-5":
      return makeFilterAlertCombinedMiddle(1, 5);
    case "gt-1-le-2":
      return makeFilterAlertCombinedMiddle(1, 2);
    case "gt-0p5-le-2":
      return makeFilterAlertCombinedMiddle(0.5, 2);
    case "gt-0p5-le-1":
      return makeFilterAlertCombinedMiddle(0.5, 1);
    case "gt-0p15-le-0p3":
      return makeFilterAlertCombinedMiddle(0.15, 0.3);
    case "gt-0p1-le-0p15":
      return makeFilterAlertCombinedMiddle(0.1, 0.15);
    case "gt-0p05-le-0p1":
      return makeFilterAlertCombinedMiddle(0.05, 0.1);
    case "gt-0p02-le-0p05":
      return makeFilterAlertCombinedMiddle(0.02, 0.05);
    case "le-30":
      return makeFilterAlertCombinedEdge(">", 30);
    case "le-0p5":
      return makeFilterAlertCombinedEdge(">", 0.5);
    case "le-0p02":
      return makeFilterAlertCombinedEdge(">", 0.02);
    case "qual-no-data":
      return [
        "any",
        ["!=", ["get", "min_val"], null],
        [
          "any",
          ["!=", ["get", "valid_dwd"], true],
          ["!=", ["get", "valid_ecmwf"], true],
          ["!=", ["get", "valid_jma"], true],
          ["!=", ["get", "valid_ncep"], true]
        ]
      ];
    case "not-valid":
      return [
        "any",
        ["==", ["get", "valid_dwd"], true],
        ["==", ["get", "valid_ecmwf"], true],
        ["==", ["get", "valid_jma"], true],
        ["==", ["get", "valid_ncep"], true]
      ];
    default:
      return [];
  }
};

const makeFilterAlertCombinedEdge = (sign, val) => {
  return [
    "any",
    // the order is important
    ["==", ["get", "min_val"], null],
    [sign, ["get", "min_val"], val]
    // ["!=", ["get", "valid"], true]
  ];
};

const makeFilterAlertCombinedMiddle = (min, max) => {
  return [
    "any",
    // the order is important
    ["==", ["get", "min_val"], null],
    ["<=", ["get", "min_val"], min],
    [">", ["get", "min_val"], max]
    // ["!=", ["get", "valid"], true]
  ];
};
