export const SET_PERIOD_DATA_KEY = "SET_PERIOD_DATA_KEY";

export const SET_SELECTED_STATION = "SET_SELECTED_STATION";
export const SET_CHART_DATA = "SET_CHART_DATA";
export const INITIALIZE_MAP = "INITIALIZE_MAP";

export const UPDATE_PERIOD_TYPES = "UPDATE_PERIOD_TYPES";

export const SET_READY_TO_FILL_MENU = "SET_READY_TO_FILL_MENU";

export const SET_ERROR_404 = "SET_ERROR_404";
export const SET_PROPERTY = "SET_PROPERTY";

export const SET_ACTIVELAYERNAME = "SET_ACTIVELAYERNAME";
export const SET_LEVEL = "SET_LEVEL";
