import { colors } from "../colors";
import { reports, getDaysInMonth } from "../general";

export const LegendGsnAvailability = {
  title: "Availability",
  body: [
    {
      label: "Reporting",
      color: colors.map.gsn.availability[0],
      filter: "cl-gsn-avail-reporting"
    },
    {
      label: "No match in OSCAR/Surface",
      color: colors.map.gsn.availability[1],
      filter: "cl-gsn-avail-no-match"
    },
    {
      label: "Not reporting",
      color: colors.map.gsn.availability[2],
      filter: "cl-gsn-avail-not-reporting"
    },
    {
      label: "Not reporting & no match in OSCAR/Surface",
      color: colors.map.gsn.availability[3],
      filter: "cl-gsn-avail-not-reporting-no-match"
    }
  ]
};

export const getLegendGsnCompleteness = (variable, date) => {
  // Get the printable name of the variable
  const variableName = reports.gsn.completeness.variables.filter(
    v => v.code === variable
  )[0].name;

  // Get the number of days in the month
  const nbDays = getDaysInMonth(date);

  return {
    title: `Number of daily averaged\ndata for ${variableName}`,
    body: [
      {
        label: `${nbDays}`,
        color: colors.map.gsn.completeness[0],
        filter: "cl-gsn-completeness-top"
      },
      {
        label: `< ${nbDays}`,
        color: colors.map.gsn.completeness[1],
        filter: "cl-gsn-completeness-middle"
      },
      {
        label: "< 15",
        color: colors.map.gsn.completeness[2],
        filter: "cl-gsn-completeness-low"
      },
      {
        label: "0",
        color: colors.map.gsn.completeness[3],
        filter: "cl-gsn-completeness-null"
      },
      {
        label: "Not provided",
        color: colors.map.gsn.completeness[4],
        filter: "cl-gsn-completeness-not-provided",
        asterisk: "This information is not mandatory in CLIMAT reports"
      }
    ]
  };
};
