import React from "react";
import { DatePicker } from "antd";
import moment from "moment";

const { MonthPicker } = DatePicker;

const MenuDates = props => {
  const disabledDate = current => {
    return (
      current && !props.arrDates.includes(moment.utc(current).format("YYYY-MM"))
    );
  };

  return (
    <div className="menu-element">
      <div>Month</div>
      <MonthPicker
        size="small"
        value={moment.utc(props.selectedDate, "YYYY-MM")}
        disabledDate={disabledDate}
        onChange={props.onChangeDate}
        allowClear={false}
      />
    </div>
  );
};

export default MenuDates;
