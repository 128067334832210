import React, { useState, useEffect } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./LinkMarineRoute.css";
import { MAPBOX_KEY } from "../../../utils/map";
import {
  URL_SHIP_OBSERVATIONS_POSITIONS,
  URL_BUOY_OBSERVATIONS_POSITIONS,
  fetchData
} from "../../../utils/api";
import { size } from "lodash";

function applyShipPositions(
  props,
  startingCoordinates,
  geojson,
  isModalOpen,
  MAPBOX_BACKGROUND_WDQMS,
  url
) {
  let dataFeatures = [];
  let lastPositionsIndex = [];
  fetchData(url)
    .then(response => {
      response.data.forEach((d, i) => {
        // console.log(d);
        let startHereTxt;
        let endHereTxt;
        let startHere = false;
        let endHere = false;
        lastPositionsIndex[d.center] = i;
        if (i === 0) {
          startHereTxt = "<li><b>Starting position. </b></li>";
          startHere = true;
        } else {
          startHereTxt = "";
          startHere = false;
        }
        if (i === size(response.data) - 1) {
          endHereTxt = "<li><b>Ending position.</b></li>";
          endHere = true;
        } else {
          endHereTxt = "";
          endHere = false;
        }
        let postionDescription =
          "<div id='popup-content'>" +
          "<ul class='popup-station-properties'>" +
          startHereTxt +
          endHereTxt +
          "<li> date:" +
          d.date +
          "</li>" +
          "<li class='popup-title-center'>" +
          d.center +
          "</li></ul></div>";

        let feature = {
          type: "Feature",
          properties: {
            description: postionDescription,
            startHere: startHere,
            endHere: endHere,
            center: d.center
          },
          geometry: {
            type: "Point",
            coordinates: [d.longitude, d.latitude]
          }
        };
        dataFeatures.push(feature);
      });
      // console.log(response.data[0].latitude);
      startingCoordinates = [
        response.data[0].longitude,
        response.data[0].latitude
      ];
      console.log(url);
      geojson = {
        type: "FeatureCollection",
        features: dataFeatures
      };

      if (isModalOpen) {
        var map = new mapboxgl.Map({
          container: "model_map",
          style: MAPBOX_BACKGROUND_WDQMS,
          center: startingCoordinates,
          zoom: 3
        });
        map.addControl(new mapboxgl.FullscreenControl());
        map.addControl(new mapboxgl.NavigationControl());
        // Add staion position during that period
        addMarinePositions();
        // Draw marine route crossing all station position during that period.
        addMarineRoute();
      }

      function addMarineRoute() {
        let routeCoords = [];
        geojson.features.forEach(function(point) {
          routeCoords.push(point.geometry.coordinates);
        });
        // console.log(routeCoords);
        // If a route is already loaded, remove it
        if (map.getSource("route")) {
          map.removeLayer("route");
          map.removeSource("route");
        } else {
          map.on("load", function() {
            // Add a new layer to the map
            map.addLayer({
              id: "route",
              type: "line",
              source: {
                type: "geojson",
                data: {
                  type: "Feature",
                  geometry: {
                    type: "LineString",
                    coordinates: routeCoords
                  }
                }
              },
              layout: {
                "line-join": "round",
                "line-cap": "round"
              },
              paint: {
                "line-color": "lightblue",
                "line-width": 8,
                "line-opacity": 0.8
              }
            });
          });
        }
      }
      function addMarinePositions() {
        let popup = new mapboxgl.Popup();
        // create a HTML element for each feature
        var el = document.createElement("div");
        // add markers to map
        let i = 0;
        geojson.features.forEach(function(marker) {
          el = document.createElement("div");
          el.className = "marker";
          if (lastPositionsIndex[marker.properties.center] === i) {
            el.className = "marker-end";
          } else {
            el.className = "marker";
          }
          popup = new mapboxgl.Popup()
            .setLngLat(marker.geometry.coordinates)
            .setHTML(marker.properties.description);
          new mapboxgl.Marker(el)
            .setLngLat(marker.geometry.coordinates)
            .setPopup(popup)
            .addTo(map);
          i++;
        });
      }
    })
    .catch(error => {
      console.log("ERROR API");
      console.log(error);
    });
  return { startingCoordinates, geojson };
}

function getLinkMarineMapText(props) {
  let centers = props.centers;
  if (props.selectedCenter === "all") {
    centers = props.centers.filter(center => center !== "all");
    centers = centers.join(" and ");
  } else {
    centers = props.selectedCenter;
  }

  let marine_route_text = (
    <>
      Route of <b>{props.wigosid}</b> for period centred on{" "}
      <b>{props.selectedSixHPeriod}UTC</b> on <b>{props.selectedDate}</b> for{" "}
      {centers}
    </>
  );
  if (props.period_type === "daily") {
    marine_route_text = (
      <>
        Daily Route of <b>{props.wigosid}</b> on <b>{props.selectedDate}</b> for{" "}
        {centers}.
      </>
    );
  }
  return marine_route_text;
}
const LinkMarineRoute = props => {
  const MAPBOX_BACKGROUND_WDQMS =
    "mapbox://styles/kurt-hectic/cj488lfn91qxh2rrz0nghg0y3";

  mapboxgl.accessToken = MAPBOX_KEY;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  var geojson = {
    type: "FeatureCollection",
    features: []
  };
  let startingCoordinates;
  useEffect(() => {
    if (props.file_type == "ship") {
      let url = `${URL_SHIP_OBSERVATIONS_POSITIONS}?station_id=${props.station_id}&period_type=${props.period_type}&var_id=${props.var_id}&${props.periodsAsQP}`;

      ({ startingCoordinates, geojson } = applyShipPositions(
        props,
        startingCoordinates,
        geojson,
        isModalOpen,
        MAPBOX_BACKGROUND_WDQMS,
        url
      ));
    } else if (props.file_type === "buoy") {
      let url = `${URL_BUOY_OBSERVATIONS_POSITIONS}?station_id=${props.station_id}&period_type=${props.period_type}&var_id=${props.var_id}&${props.periodsAsQP}`;
      ({ startingCoordinates, geojson } = applyShipPositions(
        props,
        startingCoordinates,
        geojson,
        isModalOpen,
        MAPBOX_BACKGROUND_WDQMS,
        url
      ));
    }

    // Store api result in geojson array

    // console.log(props);
    // console.log(url);
  });
  let marine_route_text = getLinkMarineMapText(props);
  return (
    <>
      <a href="#" onClick={openModal}>
        Display route
      </a>
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <div className="modal-content">
              <div id="model_map"></div>
              {/* <h2>Marine Route of {props.wigosid}</h2> */}
              <div>
                <p>{marine_route_text}</p>
              </div>
              <div className="route_div">
                <button onClick={closeModal}>Close</button>
                <p className="route_disclaimer">
                  <b>Note:</b> The positions provided by different centers may
                  overlap.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LinkMarineRoute;
