import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

import { connect } from "react-redux";
import mapboxgl from "mapbox-gl";
import * as actions from "../../store/actions/actions";

import {
  WDQMS_TILES,
  getPeriodsAsQueryParameters,
  getMarinePeriodsAsQueryParameters
} from "../../utils/general";
import {
  MAPBOX_KEY,
  MAPBOX_BACKGROUND,
  getMapStyle,
  getMapLegend,
  makeStroke,
  clearLegend,
  editMapAttribution
} from "../../utils/map";

import Legend from "./Legend/Legend";
import Spinner from "../UI/Spinner/Spinner";
import Popup from "./Popup/Popup";
import GCOSPopup from "../GCOS/Popup/Popup";
import SearchBox from "./SearchBox/SearchBox";

import legendControlIcon from "../../images/icons/align-justify.svg";

import "./Map.css";
import Chart from "../Chart/Chart";
import { colors } from "../../utils/colors";
import imageWATERMARK from "../../images/icons/test_watermark_resized.png";
import { getQualityChartYAxisLabel } from "../../utils/chartsMetadata";

mapboxgl.accessToken = MAPBOX_KEY;

const popup = new mapboxgl.Popup();

const MapMarine = props => {
  let prefix = "";
  if (props.baseline === "GBON") {
    prefix = "gbon_";
  }
  const ftype = props[prefix + props.fileType];

  const [layerIsLoaded, setLayerIsLoaded] = useState(true);
  const [mapOpacity, setMapOpacity] = useState(1);
  const [visible, setVisible] = useState(false);
  const [legendVisible, setLegendVisible] = useState(false);
  const [legend, setLegend] = useState(
    getMapLegend(
      prefix,
      props.fileType,
      ftype.selectedReport,
      ftype.selectedVariable,
      ftype.selectedPeriodType,
      ftype.selectedDate,
      ftype.baseline
    )
  );
  const [done, setDone] = useState(false);
  const [firstLayerAdded, setFirstLayerAdded] = useState(false);

  // function called when clicking on the map control to toggle the legend
  const clickOnLegendButton = () => {
    setLegendVisible(prevLegendVisible => !prevLegendVisible);
  };
  let layersNames = [];
  const addLayer = (map, periodType, report, variable, center) => {
    popup.remove();

    const style = getMapStyle(
      prefix,
      props.fileType,
      report,
      variable,
      periodType,
      ftype.selectedDate,
      ftype.baseline
    );
    setLegend(
      getMapLegend(
        prefix,
        props.fileType,
        report,
        variable,
        periodType,
        ftype.selectedDate,
        ftype.baseline
      )
    );

    let layerName;
    let functionName;
    let url;
    let periodsAsQP;

    // We have two layers we need to fetch and we have predefined params
    // report = qualitu
    // file type needs to be splitted as buoy and ship
    let file_types = { buoy: "buoy", ship: "ship" };
    for (let file_type of Object.keys(file_types)) {
      functionName = `${file_type}_${report}by`;

      if (periodType === "six_hour") {
        functionName = `${functionName}_sixhour_period`;
      } else {
        functionName = `${functionName}_${periodType}_period`;
      }
      if (ftype.combinedMode) {
        functionName += "_combined";
      } else {
        functionName += "_by_center";
      }

      periodsAsQP = getMarinePeriodsAsQueryParameters(
        file_type.toUpperCase(),
        ftype.periodData,
        periodType,
        ftype.selectedCenter,
        ftype.selectedDate,
        ftype.selectedSixHPeriod
      );

      layerName = `wdqms-${functionName}`;
      if (report === "quality" && periodType === "alert") {
        url = `${WDQMS_TILES}/tileserv.${functionName}/{z}/{x}/{y}.pbf?param_var_id=${variable}&param_ts=${ftype.selectedDate}&param_center=${center}`;
      } else {
        url = `${WDQMS_TILES}/tileserv.${functionName}/{z}/{x}/{y}.pbf?param_var_id=${variable}&${periodsAsQP.toLowerCase()}`;
      }

      // update the layer name in the store
      props.onSetActiveLayerName(layerName);
      map.addLayer({
        id: layerName,
        type: "circle",
        source: {
          type: "vector",
          tiles: [url],
          minzoom: 0,
          maxzoom: 16
        },
        "source-layer": layerName,
        paint: {
          "circle-radius": {
            base: 1,
            stops: [
              [4, 3],
              [12, 6]
            ]
          },
          "circle-color": style,
          "circle-stroke-width": makeStroke(
            periodType,
            ftype.selectedReport,
            props.fileType
          ),
          "circle-stroke-color": colors.map.synop.alertStroke
        }
      });

      map.once("idle", function() {
        setMapOpacity(1);
        setLayerIsLoaded(true);
      });

      addEventsOnLayer(map, layerName);
      layersNames.push(layerName);
    }

    map.once("idle", () => {
      let layers = document.getElementById("menu_toggle");
      layers.innerHTML = "";
      // If these two layers were not added to the map, abort
      // Enumerate ids of the layers.
      const toggleableLayerIds = layersNames;
      // Set up the corresponding toggle button for each layer.
      for (const id of toggleableLayerIds) {
        // Skip layers that already have a button set up.
        if (document.getElementById(id)) {
          continue;
        }
        let layerDisplayName = "Buoy";
        if (id.includes("ship")) {
          layerDisplayName = "Ship";
        }
        // Create a link.
        const link = document.createElement("a");
        link.id = id;
        link.href = "#";
        link.textContent = layerDisplayName;
        link.className = `${layerDisplayName} active`;

        map.setLayoutProperty(id, "visibility", "visible");
        // Show or hide layer when the toggle is clicked.
        link.onclick = function(e) {
          const clickedLayer = this.id;
          e.preventDefault();
          e.stopPropagation();

          const visibility = map.getLayoutProperty(clickedLayer, "visibility");

          // Toggle layer visibility by changing the layout object's visibility property.
          if (visibility === "visible") {
            map.setLayoutProperty(clickedLayer, "visibility", "none");
            this.className = layerDisplayName;
          } else {
            this.className = `${layerDisplayName} active`;
            map.setLayoutProperty(clickedLayer, "visibility", "visible");
          }
        };

        layers.appendChild(link);
      }
      setMapOpacity(1);
      setLayerIsLoaded(true);
    });

    clearLegend();
    editMapAttribution(
      props.fileType,
      props.baseline,
      ftype.selectedPeriodType,
      ftype.selectedReport,
      ftype.selectedVariable,
      ftype.selectedCenter,
      ftype.selectedDate,
      ftype.selectedSixHPeriod
    );
  };

  function onClick(e) {
    let layerId = e.features[0].layer.id;
    let stationInfo = e.features[0].properties;
    if (layerId.includes("buoy")) {
      stationInfo["file_type"] = "buoy";
    } else {
      stationInfo["file_type"] = "ship";
    }
    const coordinates = e.features[0].geometry.coordinates.slice();
    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    // Find a better solution
    // This is used to pass the information to the popup element
    // which baseline is active (OSCAR or GBON)
    let baseline = null;
    let baselineElement = document.getElementsByClassName("baseline-selected");
    if (baselineElement.length > 0) {
      baseline = baselineElement[0].innerText.replace("*", "");
    }

    popup
      .setLngLat(coordinates)
      // multiple `<br>` instead of an empty string so that the popup is always
      // completely visible no matter where it is compared to the map extent
      .setHTML("<br><br><br><br><br><br><br><br><br><br>")
      .addTo(props.map);

    let popupComponent;
    if (props.fileType === "marine_surface") {
      popupComponent = (
        <Popup
          obj={popup}
          baseline={props.baseline}
          fileType={props.fileType}
          stationObject={stationInfo}
          combinedMode={ftype.combinedMode}
          setVisible={setVisible}
          selectedReport={ftype.selectedReport}
          selectedPeriodType={ftype.selectedPeriodType}
          selectedCenter={ftype.selectedCenter}
          selectedDate={ftype.selectedDate}
          selectedSixHPeriod={ftype.selectedSixHPeriod}
          selectedVariable={ftype.selectedVariable}
          periodData={ftype.periodData}
          onSetChartData={props.onSetChartData}
          onSetSelectedStation={props.onSetSelectedStation}
          // this is passed to LinkTimeSeries
          onSetLevel={props.onSetLevel}
        />
      );
    }
    ReactDOM.render(
      // Provider is only needed if connect Popup to redux
      // <Provider store={store} fileType={props.fileType}>
      popupComponent,
      document.querySelector(".mapboxgl-popup-content")
    );
  }
  // The event of the former doesn't seem to be removed
  // seems to only occur with combined mode
  const addEventsOnLayer = (map, layerId) => {
    // When a click event occurs on a feature open a popup at the
    // location of the feature, with description HTML from its properties.
    map.on("click", layerId, onClick);

    // Change the cursor to a pointer when the mouse is over the places layer.
    map.on("mouseenter", layerId, function() {
      map.getCanvas().style.cursor = "pointer";
    });

    // Change it back to a pointer when it leaves.
    map.on("mouseleave", layerId, function() {
      map.getCanvas().style.cursor = "";
    });
  };

  // Initialize the map
  useEffect(() => {
    if (!mapboxgl.supported()) {
      // eslint-disable-next-line no-alert
      alert("Your browser does not support Mapbox GL");
    }
    const mapObject = new mapboxgl.Map({
      container: "map",
      style: MAPBOX_BACKGROUND,
      zoom: 1.2,
      center: [0, 22]
    });

    props.onInitializeMap(mapObject);
    editMapAttribution();
    setDone(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Display the first layer
  useEffect(() => {
    if (
      ftype.readyToFillMenu &&
      done &&
      !firstLayerAdded &&
      props.map !== null
    ) {
      // clear and close the search station control
      props.onChangeStatus({ key: "matches", value: [] });
      props.onChangeStatus({ key: "result", value: "" });
      props.onChangeStatus({
        key: "classContainer",
        value: "inside-filter-closed"
      });
      props.onChangeStatus({ key: "classCCtrlL1", value: "ctrl-search" });

      // If there is no data stop the spinner remove the transparency

      if (
        props.fileType === "marine_surface" &&
        ftype.periodData[ftype.selectedPeriodType].noData
      ) {
        setMapOpacity(1);
        setLayerIsLoaded(true);
      } else {
        setMapOpacity(0.2);
        setLayerIsLoaded(false);

        props.map.on("load", event => {
          addLayer(
            props.map,
            ftype.selectedPeriodType,
            ftype.selectedReport,
            ftype.selectedVariable,
            ftype.selectedCenter
          );

          // Add map control to toggle legend
          const controlTopRight = document.getElementsByClassName(
            "mapboxgl-ctrl-top-right"
          )[0];
          const divMapControl = document.createElement("div");
          divMapControl.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
          divMapControl.innerHTML = `<button class="mapboxgl-ctrl-icon mapboxgl-ctrl-legend" type="button" aria-label="Toggle legend" title="Toggle legend"><img class="control-icon" src="${legendControlIcon}"></button>`;
          controlTopRight.appendChild(divMapControl);

          const legendButton = document.getElementsByClassName(
            "mapboxgl-ctrl-legend"
          )[0];

          legendButton.addEventListener("click", clickOnLegendButton);
          setLegendVisible(true);
          setFirstLayerAdded(true);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ftype.readyToFillMenu, done, props.map]);

  function show_watermark() {
    if (window.location.hostname != "wdqms.wmo.int") {
      const waterMark = Array.from(
        document.querySelectorAll("a.mapboxgl-ctrl-logo")
      );
      waterMark.forEach(element => {
        element.style.backgroundImage = "url(" + imageWATERMARK + ")";
        element.style.width = "140px";
        element.style.height = "41px";
      });
    }
  }
  // Change the layer through the map menu
  useEffect(() => {
    show_watermark();

    if (ftype.readyToFillMenu && firstLayerAdded && props.map !== null) {
      if (props.map.getStyle().layers.length > 1) {
        const buoyLayerId = props.map.getStyle().layers[
          props.map.getStyle().layers.length - 1
        ].id;
        const shipLayerId = props.map.getStyle().layers[
          props.map.getStyle().layers.length - 2
        ].id;
        // Set the map with transparency and display the spinner
        setMapOpacity(0.2);
        setLayerIsLoaded(false);
        // Only delete the layer if it's one of the WDQMS layer not a background layer
        if (buoyLayerId.startsWith("wdqms-")) {
          props.map.removeLayer(buoyLayerId);
          props.map.removeSource(buoyLayerId);
        }
        if (shipLayerId.startsWith("wdqms-")) {
          props.map.removeLayer(shipLayerId);
          props.map.removeSource(shipLayerId);
        }
      }
      // The 2 conditions below are ugly - to improve!
      if (props.fileType === "marine_surface") {
        // Only add layer if there is data for that period type
        // useful when changing a period type
        if (!ftype.periodData[ftype.selectedPeriodType].noData) {
          addLayer(
            props.map,
            ftype.selectedPeriodType,
            ftype.selectedReport,
            ftype.selectedVariable,
            ftype.selectedCenter
          );
        }
      }

      // clear and close the search station control
      props.onChangeStatus({ key: "matches", value: [] });
      props.onChangeStatus({ key: "result", value: "" });
      props.onChangeStatus({
        key: "classContainer",
        value: "inside-filter-closed"
      });
      props.onChangeStatus({ key: "classCCtrlL1", value: "ctrl-search" });
    }
    // Cleanup function to remove the event listener
    return () => {
      if (props.map !== null) {
        console.log(
          props.map.getStyle().layers[props.map.getStyle().layers.length - 1]
        );
        props.map.off(
          "click",
          props.map.getStyle().layers[props.map.getStyle().layers.length - 1]
            .id,
          onClick
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ftype.selectedPeriodType,
    ftype.selectedReport,
    ftype.selectedSixHPeriod,
    ftype.selectedVariable,
    ftype.selectedDate,
    ftype.selectedCenter
  ]);

  // Change the layer through the app menu
  useEffect(() => {
    if (ftype.readyToFillMenu && firstLayerAdded) {
      if (props.map.getStyle().layers.length > 1) {
        const buoyLayerId = props.map.getStyle().layers[
          props.map.getStyle().layers.length - 1
        ].id;
        const shipLayerId = props.map.getStyle().layers[
          props.map.getStyle().layers.length - 2
        ].id;
        // Set the map with transparency and display the spinner
        setMapOpacity(0.2);
        setLayerIsLoaded(false);
        // Only delete the layer if it's one of the WDQMS layer not a background layer
        if (buoyLayerId.startsWith("wdqms-")) {
          props.map.removeLayer(buoyLayerId);
          props.map.removeSource(buoyLayerId);
        }
        if (shipLayerId.startsWith("wdqms-")) {
          props.map.removeLayer(shipLayerId);
          props.map.removeSource(shipLayerId);
        }
      }
      // The 2 conditions below are ugly - to improve!
      if (props.fileType === "marine_surface") {
        // Only add layer if there is data for that period type
        // useful when changing a period type
        if (!ftype.periodData[ftype.selectedPeriodType].noData) {
          addLayer(
            props.map,
            ftype.selectedPeriodType,
            ftype.selectedReport,
            ftype.selectedVariable,
            ftype.selectedCenter
          );
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.fileType, ftype.readyToFillMenu, ftype.baseline]);

  // When changing the baseline, update the legend
  useEffect(() => {
    setLegend(
      getMapLegend(
        prefix,
        props.fileType,
        ftype.selectedReport,
        ftype.selectedVariable,
        ftype.selectedPeriodType,
        ftype.selectedDate,
        ftype.baseline
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ftype.baseline]);

  let spinner;
  if (!layerIsLoaded) {
    spinner = <Spinner />;
  }
  let chart;
  if (props.selectedStation !== null) {
    chart = (
      <Chart
        fileType={props.fileType}
        visible={visible}
        setVisible={setVisible}
        baseline={props.baseline}
      />
    );
  }

  return (
    <div id="map-container">
      <div id="map" style={{ opacity: mapOpacity }}>
        <nav id="menu_toggle"></nav>
        <SearchBox fileType={props.fileType} baseline={props.baseline} />
        <Legend
          fileType={props.fileType}
          legendContent={legend}
          isVisible={legendVisible}
          baseline={props.baseline}
        />
      </div>
      {spinner}
      {chart}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    map: state.map,
    synop: state.synop,
    temp: state.temp,
    gbon_synop: state.gbon_synop,
    gbon_temp: state.gbon_temp,
    guan: state.guan,
    gsn: state.gsn,
    buoy: state.buoy,
    ship: state.ship,
    marine_surface: state.marine_surface,
    activeLayerName: state.activeLayerName
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  let prefix = "";
  return {
    onSetSelectedStation: data => {
      dispatch(actions.setSelectedStation(data));
    },
    onSetActiveLayerName: data => {
      dispatch(actions.setActiveLayerName(data));
    },
    onSetChartData: data => {
      dispatch(
        actions.setProperty({
          type: prefix + ownProps.fileType,
          key: "chartData",
          value: data
        })
      );
    },
    onInitializeMap: data => {
      return dispatch((_, getState) => {
        dispatch(actions.initializeMap(data));
        const currentState = getState();
        currentState.map.addControl(
          new mapboxgl.NavigationControl({ showCompass: false })
        );
        currentState.map.addControl(new mapboxgl.FullscreenControl());
        // disable map rotation using right click + drag
        currentState.map.dragRotate.disable();

        // disable map rotation using touch rotation gesture
        currentState.map.touchZoomRotate.disableRotation();
      });
    },
    onSetLevel: data => {
      dispatch(actions.setLevel(data));
    },
    onChangeStatus: data => {
      dispatch(
        actions.setProperty({
          type: "searchedStations",
          key: data.key,
          value: data.value
        })
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MapMarine);
