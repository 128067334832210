import { colors } from "../colors";

export const getStyleGuanAvailability = report => {
  return [
    "case",
    ["==", ["get", "availability"], -1],
    colors.map.guan.availability[4],
    ["==", ["get", "availability"], 0],
    colors.map.guan.availability[3],
    ["<", ["get", "availability"], 15],
    colors.map.guan.availability[2],
    ["<", ["get", "availability"], 25],
    colors.map.guan.availability[1],
    [">=", ["get", "availability"], 25],
    colors.map.guan.availability[0],
    /* other - never used here but needed */ colors.map.classNeeded
  ];
};

const cl_guan_le_0p5 = v => ["<=", ["get", v], 0.5];
const cl_guan_le_1 = v => ["<=", ["get", v], 1];
const cl_guan_le_2 = v => ["<=", ["get", v], 2];

const cl_guan_gt_0p5_le_1 = v => [
  "all",
  [">", ["get", v], 0.5],
  ["<=", ["get", v], 1]
];

const cl_guan_gt_1_le_3 = v => [
  "all",
  [">", ["get", v], 1],
  ["<=", ["get", v], 3]
];

const cl_guan_gt_2_le_5 = v => [
  "all",
  [">", ["get", v], 2],
  ["<=", ["get", v], 5]
];

const cl_guan_gt_3_le_5 = v => [
  "all",
  [">", ["get", v], 3],
  ["<=", ["get", v], 5]
];

const cl_guan_gt_5_le_8 = v => [
  "all",
  [">", ["get", v], 5],
  ["<=", ["get", v], 8]
];

const cl_guan_gt_5_le_10 = v => [
  "all",
  [">", ["get", v], 5],
  ["<=", ["get", v], 10]
];

const cl_guan_gt_8_le_15 = v => [
  "all",
  [">", ["get", v], 8],
  ["<=", ["get", v], 15]
];

const cl_guan_gt_10_le_15 = v => [
  "all",
  [">", ["get", v], 10],
  ["<=", ["get", v], 15]
];

const cl_guan_gt_15_le_30 = v => [
  "all",
  [">", ["get", v], 15],
  ["<=", ["get", v], 30]
];

const cl_guan_gt_10 = v => [">", ["get", v], 10];
const cl_guan_gt_15 = v => [">", ["get", v], 15];
const cl_guan_gt_30 = v => [">", ["get", v], 30];

export const getStyleGuanQuality = variable => {
  let style;
  if (variable === "temperature") {
    style = [
      "case",
      cl_guan_le_0p5(variable),
      colors.map.guan.quality[0],
      cl_guan_gt_0p5_le_1(variable),
      colors.map.guan.quality[1],
      cl_guan_gt_1_le_3(variable),
      colors.map.guan.quality[2],
      cl_guan_gt_3_le_5(variable),
      colors.map.guan.quality[3],
      cl_guan_gt_5_le_10(variable),
      colors.map.guan.quality[4],
      cl_guan_gt_10(variable),
      colors.map.guan.quality[5],
      // never used but needed
      colors.map.classNeeded
    ];
  } else if (variable === "humidity") {
    style = [
      "case",
      cl_guan_le_2(variable),
      colors.map.guan.quality[0],
      cl_guan_gt_2_le_5(variable),
      colors.map.guan.quality[1],
      cl_guan_gt_5_le_10(variable),
      colors.map.guan.quality[2],
      cl_guan_gt_10_le_15(variable),
      colors.map.guan.quality[3],
      cl_guan_gt_15_le_30(variable),
      colors.map.guan.quality[4],
      cl_guan_gt_30(variable),
      colors.map.guan.quality[5],
      // never used but needed
      colors.map.classNeeded
    ];
  } else if (variable === "wind") {
    style = [
      "case",
      cl_guan_le_1(variable),
      colors.map.guan.quality[0],
      cl_guan_gt_1_le_3(variable),
      colors.map.guan.quality[1],
      cl_guan_gt_3_le_5(variable),
      colors.map.guan.quality[2],
      cl_guan_gt_5_le_8(variable),
      colors.map.guan.quality[3],
      cl_guan_gt_8_le_15(variable),
      colors.map.guan.quality[4],
      cl_guan_gt_15(variable),
      colors.map.guan.quality[5],
      // never used but needed
      colors.map.classNeeded
    ];
  }
  return style;
};
