import React from "react";

import "./DBHeader.css";

const allCenters = ["DWD", "ECMWF", "JMA", "NCEP"];
const allPeriods = ["00", "06", "12", "18"];

const DBHeader = props => {
  const headerCenters = allCenters.map(c => (
    <div className="dbs-header-item" key={c}>
      {c}
    </div>
  ));

  const headerElement = allCenters.map(c => {
    return (
      <div className="dbs-row-item list-periods" key={c}>
        {allPeriods.map(p => (
          <div className="period-element" key={p}>
            <div className="container-period" key={p}>
              {p}
            </div>
          </div>
        ))}
      </div>
    );
  });

  return (
    <>
      <div className="dbs-row">
        {/* We first add an empty div before
         `headerCenters` so that the content of
         this row is aligned with the rows 
         from dbs-body */}
        <div className="dbs-header-item"></div>
        {headerCenters}
      </div>
      <div className="dbs-row">
        {/* We first add an empty div before
         `headerElement` so that the content of
         this row is aligned with the rows 
         from dbs-body */}
        <div className="dbs-header-item"></div>
        {headerElement}
      </div>
    </>
  );
};

export default DBHeader;
