import React from "react";
import { Alert } from "antd";
import "antd/lib/alert/style/index.css";
import "./ErrorParamsURL.css";

const ErrorParamsURL = props => {
  return (
    <Alert
      className="error-params-url"
      message="This page does not exist."
      type="error"
      showIcon
    />
  );
};

export default ErrorParamsURL;
