import React from "react";
import { reports } from "../../../../utils/general";

const MenuVariables = props => {
  const variablesOptions = reports[props.fileType][
    props.selectedReport
  ].variables.map(v => (
    <option key={v.code} value={v.code}>
      {v.name}
    </option>
  ));

  return (
    <div className="menu-element">
      <label htmlFor="menu-variables">Variable</label>
      <select
        className="select-css"
        id="menu-variables"
        onChange={props.onChangeVariable}
        value={props.selectedVariable}
      >
        {variablesOptions}
      </select>
    </div>
  );
};

export default MenuVariables;
