import moment from "moment";
import { notification } from "antd";
import { WDQMS_API, fetchData } from "../../utils/api";
import { variablesSlug } from "../../utils/general";
import * as actionTypes from "./actionTypes";

const setPeriodDataKey = data => {
  return {
    type: actionTypes.SET_PERIOD_DATA_KEY,
    payload: data
  };
};

export const setActiveLayerName = data => {
  return {
    type: actionTypes.SET_ACTIVELAYERNAME,
    payload: data
  };
};

export const setProperty = data => {
  return { type: actionTypes.SET_PROPERTY, payload: data };
};

const setError404 = () => {
  return {
    type: actionTypes.SET_ERROR_404
  };
};

export const updatePeriodTypes = data => {
  return {
    type: actionTypes.UPDATE_PERIOD_TYPES,
    payload: data
  };
};

const getVariableID = (fileType, variable) => {
  return variablesSlug[fileType][variable];
};

const isEmpty = obj => {
  return Object.keys(obj).length === 0;
};

export const setLevel = data => {
  return {
    type: actionTypes.SET_LEVEL,
    payload: data
  };
};

let sixHourFetched = false;
let dailyFetched = false;

// call fetchDataFromApi only once per fileType
// It fetches first the six-hour data then the daily data
// If there is no URL params we display the six-hour data

// If there is an error fetching the six-hour data
// and there is no URL params, display the daily data

export const fetchDataFromApi = (payload, pageType) => {
  // differentiate between requests coming from the map and from the database status
  if (pageType === "DB_STATUS") {
    pageType = "&page_type=DB_STATUS";
  }
  return dispatch => {
    // If we have URL params, set them to the selected
    // params for the menu and map
    if (!isEmpty(payload.params)) {
      dispatch(
        setProperty({
          type: payload.fileType,
          key: "selectedPeriodType",
          value: payload.params.periodType
        })
      );

      if (payload.fileType === "synop") {
        dispatch(
          setProperty({
            type: payload.fileType,
            key: "selectedReport",
            value: payload.params.report
          })
        );
      } else if (payload.fileType === "temp") {
        // For TEMP if there is 'payload.params.variable'
        // it means that the report is "quality"
        if (payload.params.variable) {
          dispatch(
            setProperty({
              type: payload.fileType,
              key: "selectedReport",
              value: "quality"
            })
          );
        } else {
          // Otherwise, the report is "availability"
          dispatch(
            setProperty({
              type: payload.fileType,
              key: "selectedReport",
              value: "availability"
            })
          );
        }
      }

      // Update the variable only if it is in the params
      // If not, we keep the default value defined in the store
      // This is necessary for TEMP/Availability as there is no variable in the URL
      if (payload.params.variable) {
        dispatch(
          setProperty({
            type: payload.fileType,
            key: "selectedVariable",
            value: getVariableID(payload.fileType, payload.params.variable)
          })
        );
      }

      dispatch(
        setProperty({
          type: payload.fileType,
          key: "selectedCenter",
          value: payload.params.center
        })
      );

      if (payload.params.center !== "all") {
        dispatch(
          setProperty({
            type: payload.fileType,
            key: "combinedMode",
            value: false
          })
        );
      }

      dispatch(
        setProperty({
          type: payload.fileType,
          key: "selectedDate",
          value: payload.params.date
        })
      );
    }
    if (!payload.alreadyFetched) {
      // Load intiatil periods of six hour (quick fetch to load page faster )
      if (loadInitialPeriods(900, pageType, payload, "Oscar", dispatch)) {
        // Load all periods of six hour
        loadAllPeriods(payload, pageType).then(response => {
          // set calendar periods
          setCalendarPeriods(response, dispatch, payload, true);
          dispatch(
            setProperty({
              type: payload.fileType,
              key: "allDataFetched",
              value: true
            })
          );
        });
      }
    }
  };
};

export const fetchGbonDataFromApi = (payload, pageType) => {
  // differentiate between requests coming from the map and from the database status
  if (pageType === "DB_STATUS") {
    pageType = "&page_type=DB_STATUS";
  }
  return dispatch => {
    // If we have URL params, set them to the selected
    // params for the menu and map
    if (!isEmpty(payload.params)) {
      dispatch(
        setProperty({
          type: payload.fileType,
          key: "selectedPeriodType",
          value: payload.params.periodType
        })
      );

      if (payload.fileType === "gbon_synop") {
        dispatch(
          setProperty({
            type: payload.fileType,
            key: "selectedReport",
            value: payload.params.report
          })
        );
      } else if (payload.fileType === "gbon_temp") {
        if (payload.params.variable) {
          dispatch(
            setProperty({
              type: payload.fileType,
              key: "selectedReport",
              value: "quality"
            })
          );
        } else {
          // Otherwise, the report is "availability"
          dispatch(
            setProperty({
              type: payload.fileType,
              key: "selectedReport",
              value: "availability"
            })
          );
        }
      }
      if (payload.params.variable) {
        dispatch(
          setProperty({
            type: payload.fileType,
            key: "selectedVariable",
            value: getVariableID(payload.fileType, payload.params.variable)
          })
        );
      }

      dispatch(
        setProperty({
          type: payload.fileType,
          key: "selectedCenter",
          value: payload.params.center
        })
      );

      if (payload.params.center !== "all") {
        dispatch(
          setProperty({
            type: payload.fileType,
            key: "combinedMode",
            value: false
          })
        );
      }

      dispatch(
        setProperty({
          type: payload.fileType,
          key: "selectedDate",
          value: payload.params.date
        })
      );
    }
    if (!payload.alreadyFetched) {
      // Load intiatil periods of six hour (quick fetch to load page faster )
      if (loadInitialPeriods(900, pageType, payload, "Gbon", dispatch)) {
        // Load all periods of six hour
        loadAllPeriods(payload, pageType, "Gbon").then(response => {
          // set calendar periods
          setCalendarPeriods(response, dispatch, payload, true);
          dispatch(
            setProperty({
              type: payload.fileType,
              key: "allDataFetched",
              value: true
            })
          );
        });
      }
    }
  };
};
export const fetchMarineDataFromApi = (payload, pageType) => {
  // differentiate between requests coming from the map and from the database status
  // differentiate between requests coming from the map and from the database status
  if (pageType == "DB_STATUS") {
    pageType = "&page_type=DB_STATUS";
  }
  return dispatch => {
    // If we have URL params, set them to the selected
    // params for the menu and map
    if (!isEmpty(payload.params)) {
      dispatch(
        setProperty({
          type: payload.fileType,
          key: "selectedPeriodType",
          value: payload.params.periodType
        })
      );

      if (payload.fileType === "buoy") {
        dispatch(
          setProperty({
            type: payload.fileType,
            key: "selectedReport",
            value: "quality"
          })
        );
      } else if (payload.fileType === "ship") {
        if (payload.params.variable) {
          dispatch(
            setProperty({
              type: payload.fileType,
              key: "selectedReport",
              value: "quality"
            })
          );
        } else {
          // Otherwise, the report is "availability"
          dispatch(
            setProperty({
              type: payload.fileType,
              key: "selectedReport",
              value: "availability"
            })
          );
        }
      }
      if (payload.params.variable) {
        dispatch(
          setProperty({
            type: payload.fileType,
            key: "selectedVariable",
            value: getVariableID(payload.fileType, payload.params.variable)
          })
        );
      }

      dispatch(
        setProperty({
          type: payload.fileType,
          key: "selectedCenter",
          value: payload.params.center
        })
      );

      if (payload.params.center !== "all") {
        dispatch(
          setProperty({
            type: payload.fileType,
            key: "combinedMode",
            value: false
          })
        );
      }

      dispatch(
        setProperty({
          type: payload.fileType,
          key: "selectedDate",
          value: payload.params.date
        })
      );
    }
    if (!payload.alreadyFetched) {
      fetchSixhourMarinePeriod();
    } else {
      // console.log(`${payload.fileType} data already fetched`);
    }

    function fetchSixhourMarinePeriod() {
      return fetchData(
        `${WDQMS_API}/sixhour-periods/?file_type=${payload.fileType}&format=json`
      )
        .then(response => {
          // response.data.length === 0 -> NO DATA
          if (response.data.length === 0) {
            dispatch(
              setPeriodDataKey({
                subObj: "periodData",
                type: payload.fileType,
                period: "six_hour",
                key: "noData",
                value: true
              })
            );
            // set the data (for a particular fileType) as fetched
            // so that won't be fetched a second time
            dispatch(
              setProperty({
                type: payload.fileType,
                key: "dataFetched",
                value: true
              })
            );
          } else {
            sixHourFetched = true;
            if (isEmpty(payload.params)) {
              dispatch(
                setProperty({
                  type: payload.fileType,
                  key: "selectedPeriodType",
                  value: "six_hour"
                })
              );
            }

            dispatch(
              updatePeriodTypes({
                type: payload.fileType,
                value: "six_hour"
              })
            );
            // }
            let tempObj = {};
            for (let p of response.data.results) {
              if (!tempObj[p.center]) {
                tempObj[p.center] = {};
              }
              if (!tempObj[p.center][moment.utc(p.date).format("YYYY-MM-DD")]) {
                tempObj[p.center][moment.utc(p.date).format("YYYY-MM-DD")] = {
                  "00": { available: false, id: null },
                  "06": { available: false, id: null },
                  "12": { available: false, id: null },
                  "18": { available: false, id: null }
                };
              }
              tempObj[p.center][moment.utc(p.date).format("YYYY-MM-DD")][
                moment.utc(p.date).format("HH")
              ].available = true;
              tempObj[p.center][moment.utc(p.date).format("YYYY-MM-DD")][
                moment.utc(p.date).format("HH")
              ].id = p.id;
            }
            // The object that contains the dates for the 'combined' version (all centers)
            let objectAll = {};
            // loop through all centers
            for (let ct of Object.keys(tempObj)) {
              // loop through all dates
              for (let d of Object.keys(tempObj[ct])) {
                // if objectAll doesn't have the key 'd'
                if (!Object.keys(objectAll).includes(d)) {
                  objectAll[d] = {};
                  // loop through the 4 six-hour periods
                  for (let p of Object.keys(tempObj[ct][d])) {
                    if (tempObj[ct][d][p].available) {
                      objectAll[d][p] = {};
                      objectAll[d][p].available = true;
                      objectAll[d][p].id = {};
                      objectAll[d][p].id[ct] = tempObj[ct][d][p].id;
                    } else {
                      objectAll[d][p] = {
                        available: false,
                        id: {}
                      };
                    }
                  }
                } else {
                  for (let p of Object.keys(tempObj[ct][d])) {
                    if (tempObj[ct][d][p].available) {
                      objectAll[d][p].available = true;
                      objectAll[d][p].id[ct] = tempObj[ct][d][p].id;
                    }
                  }
                }
              }
            }
            tempObj.all = objectAll;

            dispatch(
              setPeriodDataKey({
                subObj: "periodData",
                type: payload.fileType,
                period: "six_hour",
                key: "dates",
                value: tempObj
              })
            );
            dispatch(
              setPeriodDataKey({
                subObj: "periodData",
                type: payload.fileType,
                period: "six_hour",
                key: "centers",
                // case insensitive sort so that "all" is first element
                value: Object.keys(tempObj).sort(function(a, b) {
                  return a.toLowerCase().localeCompare(b.toLowerCase());
                })
              })
            );

            let latestDate;
            let availablePeriods;
            if (isEmpty(payload.params)) {
              const firstCenter = Object.keys(tempObj).sort(function(a, b) {
                return a.toLowerCase().localeCompare(b.toLowerCase());
              })[0];
              dispatch(
                setProperty({
                  type: payload.fileType,
                  key: "selectedCenter",
                  value: firstCenter
                })
              );

              latestDate = Object.keys(tempObj[firstCenter]).sort()[
                Object.keys(tempObj[firstCenter]).length - 1
              ];
              dispatch(
                setProperty({
                  type: payload.fileType,
                  key: "selectedDate",
                  value: latestDate
                })
              );

              availablePeriods = tempObj[firstCenter][latestDate];

              const arrAvailablePeriods = Object.keys(availablePeriods)
                .sort()
                .filter(p => availablePeriods[p].available);

              dispatch(
                setProperty({
                  type: payload.fileType,
                  key: "selectedSixHPeriod",
                  value: arrAvailablePeriods[arrAvailablePeriods.length - 1]
                })
              );
            } else if (payload.params.periodType === "six_hour") {
              latestDate = payload.params.date;
              // check if the date exist
              if (tempObj[payload.params.center][latestDate]) {
                availablePeriods = tempObj[payload.params.center][latestDate];

                dispatch(
                  setProperty({
                    type: payload.fileType,
                    key: "selectedSixHPeriod",
                    value: payload.params.period
                  })
                );

                // if the date doesn't exist, trigger an error
              } else {
                dispatch(setError404());
              }

              // if the six-hour period is not available for this date,
              // also trigger an error
              if (
                tempObj[payload.params.center][latestDate] &&
                typeof payload.params.period !== "undefined"
              ) {
                if (!availablePeriods[payload.params.period].available) {
                  dispatch(setError404());
                }
              }
            }

            dispatch(
              setProperty({
                type: payload.fileType,
                key: "currentSixHPeriods",
                value: availablePeriods
              })
            );

            if (
              isEmpty(payload.params) ||
              payload.params.periodType === "six_hour"
            ) {
              dispatch(
                setProperty({
                  type: payload.fileType,
                  key: "readyToFillMenu",
                  value: true
                })
              );
            }

            // set the data (for a particular fileType) as fetched
            // so that won't be fetched a second time
            dispatch(
              setProperty({
                type: payload.fileType,
                key: "dataFetched",
                value: true
              })
            );
          }
        })
        .catch(error => {
          // If there are URL params requesting the six-hour data
          // and an error while fetching the
          // six-hour data, trigger an error
          if (
            !isEmpty(payload.params) &&
            payload.params.periodType === "six_hour"
          ) {
            dispatch(setError404());
          }

          sixHourFetched = false;

          notification.error({
            message: "Error fetching API",
            description: "There was an error while fetching the six-hour data",
            top: 100,
            duration: 0
          });
          dispatch(
            setPeriodDataKey({
              subObj: "periodData",
              type: payload.fileType,
              period: "six_hour",
              key: "errorFetchingApi",
              value: true
            })
          );
        });
    }
    // function fetchDailyMarinePeriod() {
    //   return fetchData(
    //     `${WDQMS_API}/daily-periods/?file_type=${payload.fileType}&format=json`
    //   )
    //     .then(response => {
    //       // response.data.length === 0 -> NO DATA
    //       if (response.data.length === 0) {
    //         dispatch(
    //           setPeriodDataKey({
    //             subObj: "periodData",
    //             type: payload.fileType,
    //             period: "daily",
    //             key: "noData",
    //             value: true
    //           })
    //         );
    //       } else {
    //         // if there was an error while fetching the six-hour or params are empty
    //         // set the selected period type to "daily"
    //         dailyFetched = true;
    //         if (!sixHourFetched) {
    //           dispatch(
    //             setProperty({
    //               type: payload.fileType,
    //               key: "selectedPeriodType",
    //               value: "daily"
    //             })
    //           );
    //         }

    //         if (payload.fileType === "buoy") {
    //           dispatch(
    //             updatePeriodTypes({
    //               type: payload.fileType,
    //               value: ["daily"]
    //             })
    //           );
    //         } else {
    //           // file type is temp
    //           dispatch(
    //             updatePeriodTypes({
    //               type: payload.fileType,
    //               value: ["daily"]
    //             })
    //           );
    //         }
    //         let tempObj = {};
    //         for (let p of response.data.results) {
    //           if (!tempObj[p.center]) {
    //             tempObj[p.center] = {};
    //           }
    //           tempObj[p.center][moment.utc(p.date).format("YYYY-MM-DD")] = {
    //             id: p.id
    //           };
    //         }
    //         // The object that contains the dates for the 'combined' version (all centers)
    //         let objectAll = {};

    //         // loop through all centers
    //         for (let ct of Object.keys(tempObj)) {
    //           // loop through all dates
    //           for (let d of Object.keys(tempObj[ct])) {
    //             // if the date is not in arrayAll, add it
    //             // if objectAll doesn't have the key 'd'
    //             if (!Object.keys(objectAll).includes(d)) {
    //               objectAll[d] = {};
    //             }
    //             objectAll[d][ct] = tempObj[ct][d].id;
    //           }
    //         }
    //         // Add objectAll to tempObj
    //         tempObj.all = objectAll;

    //         dispatch(
    //           setPeriodDataKey({
    //             subObj: "periodData",
    //             type: payload.fileType,
    //             period: "daily",
    //             key: "dates",
    //             value: tempObj
    //           })
    //         );

    //         dispatch(
    //           setPeriodDataKey({
    //             subObj: "periodData",
    //             type: payload.fileType,
    //             period: "daily",
    //             key: "centers",
    //             value: Object.keys(tempObj).sort(function(a, b) {
    //               return a.toLowerCase().localeCompare(b.toLowerCase());
    //             })
    //           })
    //         );

    //         if (
    //           !isEmpty(payload.params) &&
    //           payload.params.periodType === "daily"
    //         ) {
    //           dispatch(
    //             setProperty({
    //               type: payload.fileType,
    //               key: "selectedCenter",
    //               value: payload.params.center
    //             })
    //           );

    //           // if the date doesn't exist for this center,
    //           // set "selectedDate" to its latest date
    //           // note: it can happen that a date is available
    //           // for the six-hour periods but not for the daily
    //           if (!tempObj[payload.params.center][payload.params.date]) {
    //             const latestDate = Object.keys(
    //               tempObj[payload.params.center]
    //             ).sort()[
    //               Object.keys(tempObj[payload.params.center]).length - 1
    //             ];

    //             dispatch(
    //               setProperty({
    //                 type: payload.fileType,
    //                 key: "selectedDate",
    //                 value: latestDate
    //               })
    //             );
    //           } else {
    //             dispatch(
    //               setProperty({
    //                 type: payload.fileType,
    //                 key: "selectedDate",
    //                 value: payload.params.date
    //               })
    //             );
    //           }
    //         } else if (!sixHourFetched) {
    //           // Case no param - this should only be done
    //           // when there was an error fetching the six-hour data
    //           const firstCenter = Object.keys(tempObj).sort(function(a, b) {
    //             return a.toLowerCase().localeCompare(b.toLowerCase());
    //           })[0];

    //           dispatch(
    //             setProperty({
    //               type: payload.fileType,
    //               key: "selectedCenter",
    //               value: firstCenter
    //             })
    //           );

    //           const latestDate = Object.keys(tempObj[firstCenter]).sort()[
    //             Object.keys(tempObj[firstCenter]).length - 1
    //           ];

    //           dispatch(
    //             setProperty({
    //               type: payload.fileType,
    //               key: "selectedDate",
    //               value: latestDate
    //             })
    //           );
    //         }
    //         if (
    //           isEmpty(payload.params) ||
    //           payload.params.periodType === "daily"
    //         ) {
    //           dispatch(
    //             setProperty({
    //               type: payload.fileType,
    //               key: "readyToFillMenu",
    //               value: true
    //             })
    //           );
    //         }
    //       }
    //     })
    //     .catch(error => {
    //       // If there are URL params requesting the daily data
    //       // and an error while fetching the
    //       // daily data, trigger an error
    //       if (
    //         !isEmpty(payload.params) &&
    //         payload.params.periodType === "daily"
    //       ) {
    //         dispatch(setError404());
    //       }
    //       notification.error({
    //         message: "Error fetching API",
    //         description: "There was an error while fetching the daily data",
    //         top: 100,
    //         duration: 0
    //       });
    //       dispatch(
    //         setPeriodDataKey({
    //           subObj: "periodData",
    //           type: payload.fileType,
    //           period: "daily",
    //           key: "errorFetchingApi",
    //           value: true
    //         })
    //       );
    //       if (payload.fileType === "buoy") {
    //         dispatch(
    //           setPeriodDataKey({
    //             subObj: "periodData",
    //             type: payload.fileType,
    //             period: "alert",
    //             key: "errorFetchingApi",
    //             value: true
    //           })
    //         );
    //       }
    //     });
    // }
    // function fetchMonthlyMarinePeriod() {
    //   fetchData(
    //     `${WDQMS_API}/monthly-periods/?file_type=${payload.fileType}&format=json`
    //   ).then(response => {
    //     if (response.data.length === 0) {
    //       dispatch(
    //         setPeriodDataKey({
    //           subObj: "periodData",
    //           type: payload.fileType,
    //           period: "monthly",
    //           key: "noData",
    //           value: true
    //         })
    //       );
    //       if (payload.fileType === "buoy") {
    //         dispatch(
    //           setPeriodDataKey({
    //             subObj: "periodData",
    //             type: payload.fileType,
    //             period: "monthly",
    //             key: "noData",
    //             value: true
    //           })
    //         );
    //       }
    //     } else {
    //       if (!dailyFetched) {
    //         dispatch(
    //           setProperty({
    //             type: payload.fileType,
    //             key: "selectedPeriodType",
    //             value: "monthly"
    //           })
    //         );
    //       }
    //       if (payload.fileType === "buoy") {
    //         dispatch(
    //           updatePeriodTypes({
    //             type: payload.fileType,
    //             value: ["monthly"]
    //           })
    //         );
    //       } else {
    //         // file type is ship
    //         dispatch(
    //           updatePeriodTypes({
    //             type: payload.fileType,
    //             value: ["monthly"]
    //           })
    //         );
    //       }
    //       let tempObj = {};

    //       for (let p of response.data.results) {
    //         if (!tempObj[p.center]) {
    //           tempObj[p.center] = {};
    //         }
    //         tempObj[p.center][moment.utc(p.date).format("YYYY-MM")] = {
    //           id: p.id
    //         };
    //       }
    //       // // The object that contains the dates for the 'combined' version (all centers)
    //       let objectAll = {};
    //       // loop through all centers
    //       for (let ct of Object.keys(tempObj)) {
    //         // loop through all dates
    //         for (let d of Object.keys(tempObj[ct])) {
    //           // if the date is not in arrayAll, add it
    //           // if objectAll doesn't have the key 'd'
    //           if (!Object.keys(objectAll).includes(d)) {
    //             objectAll[d] = {};
    //           }
    //           objectAll[d][ct] = tempObj[ct][d].id;
    //         }
    //       }
    //       // Add objectAll to tempObj
    //       tempObj.all = objectAll;
    //       dispatch(
    //         setPeriodDataKey({
    //           subObj: "periodData",
    //           type: payload.fileType,
    //           period: "monthly",
    //           key: "dates",
    //           value: tempObj
    //         })
    //       );
    //       if (payload.fileType === "buoy") {
    //         dispatch(
    //           setPeriodDataKey({
    //             subObj: "periodData",
    //             type: payload.fileType,
    //             period: "monthly",
    //             key: "dates",
    //             value: tempObj
    //           })
    //         );
    //       }
    //       dispatch(
    //         setPeriodDataKey({
    //           subObj: "periodData",
    //           type: payload.fileType,
    //           period: "monthly",
    //           key: "centers",
    //           value: Object.keys(tempObj).sort(function(a, b) {
    //             return a.toLowerCase().localeCompare(b.toLowerCase());
    //           })
    //         })
    //       );
    //       if (payload.fileType === "buoy") {
    //         dispatch(
    //           setPeriodDataKey({
    //             subObj: "periodData",
    //             type: payload.fileType,
    //             period: "monthly",
    //             key: "centers",
    //             value: Object.keys(tempObj).sort(function(a, b) {
    //               return a.toLowerCase().localeCompare(b.toLowerCase());
    //             })
    //           })
    //         );
    //       }
    //       if (
    //         !isEmpty(payload.params) &&
    //         payload.params.periodType === "monthly"
    //       ) {
    //         dispatch(
    //           setProperty({
    //             type: payload.fileType,
    //             key: "selectedCenter",
    //             value: payload.params.center
    //           })
    //         );
    //         // if the date doesn't exist for this center,
    //         // set "selectedDate" to its latest date
    //         // note: it can happen that a date is available
    //         // for the six-hour periods but not for the daily
    //         if (!tempObj[payload.params.center][payload.params.date]) {
    //           const latestDate = Object.keys(
    //             tempObj[payload.params.center]
    //           ).sort()[Object.keys(tempObj[payload.params.center]).length - 1];
    //           dispatch(
    //             setProperty({
    //               type: payload.fileType,
    //               key: "selectedDate",
    //               value: latestDate
    //             })
    //           );
    //         } else {
    //           dispatch(
    //             setProperty({
    //               type: payload.fileType,
    //               key: "selectedDate",
    //               value: payload.params.date
    //             })
    //           );
    //         }
    //       } else if (!dailyFetched) {
    //         // Case no param - this should only be done
    //         // when there was an error fetching the six-hour data
    //         const firstCenter = Object.keys(tempObj).sort(function(a, b) {
    //           return a.toLowerCase().localeCompare(b.toLowerCase());
    //         })[0];

    //         dispatch(
    //           setProperty({
    //             type: payload.fileType,
    //             key: "selectedCenter",
    //             value: firstCenter
    //           })
    //         );

    //         const latestDate = Object.keys(tempObj[firstCenter]).sort()[
    //           Object.keys(tempObj[firstCenter]).length - 1
    //         ];

    //         dispatch(
    //           setProperty({
    //             type: payload.fileType,
    //             key: "selectedDate",
    //             value: latestDate
    //           })
    //         );
    //       }
    //       if (
    //         isEmpty(payload.params) ||
    //         payload.params.periodType === "monthly"
    //       ) {
    //         dispatch(
    //           setProperty({
    //             type: payload.fileType,
    //             key: "readyToFillMenu",
    //             value: true
    //           })
    //         );
    //       }
    //     }
    //   });
    // }
  };
};

export const fetchBuoyShipMarineDataFromApi = (payload, pageType) => {
  // Fetching combined marine and buoy togeather.
  if (pageType == "DB_STATUS") {
    pageType = "&page_type=DB_STATUS";
  }
  return dispatch => {
    // If we have URL params, set them to the selected
    // params for the menu and map
    if (!isEmpty(payload.params)) {
      dispatch(
        setProperty({
          type: payload.fileType,
          key: "selectedPeriodType",
          value: payload.params.periodType
        })
      );

      if (payload.fileType === "marine_surface") {
        dispatch(
          setProperty({
            type: payload.fileType,
            key: "selectedReport",
            value: "quality"
          })
        );
      }
      if (payload.params.variable) {
        dispatch(
          setProperty({
            type: payload.fileType,
            key: "selectedVariable",
            value: getVariableID(payload.fileType, payload.params.variable)
          })
        );
      }

      dispatch(
        setProperty({
          type: payload.fileType,
          key: "selectedCenter",
          value: payload.params.center
        })
      );

      if (payload.params.center !== "all") {
        dispatch(
          setProperty({
            type: payload.fileType,
            key: "combinedMode",
            value: false
          })
        );
      }

      dispatch(
        setProperty({
          type: payload.fileType,
          key: "selectedDate",
          value: payload.params.date
        })
      );
    }
    if (!payload.alreadyFetched) {
      fetchSixhourBuoyShipMarinePeriod().then(response => {
        fetchDailyBuoyShipMarinePeriod().then(response => {
          fetchMonthlyBuoyShipMarinePeriod();
        });
      });
    } else {
      // console.log(`${payload.fileType} data already fetched`);
    }

    function fetchSixhourBuoyShipMarinePeriod() {
      return fetchData(
        `${WDQMS_API}/sixhour-periods/?file_type=${payload.fileType}&format=json`
      )
        .then(response => {
          // console.log("response", response);
          // response.data.length === 0 -> NO DATA
          if (response.data.results.length === 0) {
            dispatch(
              setPeriodDataKey({
                subObj: "periodData",
                type: payload.fileType,
                period: "six_hour",
                key: "noData",
                value: true
              })
            );
            // set the data (for a particular fileType) as fetched
            // so that won't be fetched a second time
            dispatch(
              setProperty({
                type: payload.fileType,
                key: "dataFetched",
                value: true
              })
            );
          } else {
            sixHourFetched = true;
            if (isEmpty(payload.params)) {
              dispatch(
                setProperty({
                  type: payload.fileType,
                  key: "selectedPeriodType",
                  value: "six_hour"
                })
              );
            }

            dispatch(
              updatePeriodTypes({
                type: payload.fileType,
                value: "six_hour"
              })
            );
            // }

            let tempObj = {};
            for (let p of response.data.results) {
              if (!tempObj[p.center]) {
                tempObj[p.center] = {};
                tempObj[p.center][p.file_type] = {};
              } else if (!tempObj[p.center][p.file_type]) {
                tempObj[p.center][p.file_type] = {};
              }

              if (
                !tempObj[p.center][p.file_type][
                  moment.utc(p.date).format("YYYY-MM-DD")
                ]
              ) {
                tempObj[p.center][p.file_type][
                  moment.utc(p.date).format("YYYY-MM-DD")
                ] = {
                  "00": { available: false, id: null },
                  "06": { available: false, id: null },
                  "12": { available: false, id: null },
                  "18": { available: false, id: null }
                };
              }

              tempObj[p.center][p.file_type][
                moment.utc(p.date).format("YYYY-MM-DD")
              ][moment.utc(p.date).format("HH")].available = true;
              tempObj[p.center][p.file_type][
                moment.utc(p.date).format("YYYY-MM-DD")
              ][moment.utc(p.date).format("HH")].id = p.id;
            }

            // The object that contains the dates for the 'combined' version (all centers)
            let objectAll = {};
            // loop through all centers
            for (let ct of Object.keys(tempObj)) {
              // loop through buoy and ship
              for (let f_type of Object.keys(tempObj[ct])) {
                // loop through all dates
                if (!Object.keys(objectAll).includes(f_type)) {
                  objectAll[f_type] = {};
                }
                for (let d of Object.keys(tempObj[ct][f_type])) {
                  // if objectAll doesn't have the key 'd'
                  if (!Object.keys(objectAll[f_type]).includes(d)) {
                    objectAll[f_type][d] = {};

                    // loop through the 4 six-hour periods
                    for (let p of Object.keys(tempObj[ct][f_type][d])) {
                      // console.log("tempObj[ct][f_type][d][p]", tempObj[ct][f_type][d][p]);
                      if (tempObj[ct][f_type][d][p].available) {
                        // if objectAll[f_type][d][p] is not defined, define it
                        objectAll[f_type][d][p] = {};
                        objectAll[f_type][d][p].available = true;
                        objectAll[f_type][d][p].id = {};

                        objectAll[f_type][d][p].id[ct] =
                          tempObj[ct][f_type][d][p].id;
                      } else {
                        objectAll[f_type][d][p] = {
                          available: false,
                          id: {}
                        };
                      }
                    }
                  } else {
                    for (let p of Object.keys(tempObj[ct][f_type][d])) {
                      if (tempObj[ct][f_type][d][p].available) {
                        objectAll[f_type][d][p].available = true;
                        objectAll[f_type][d][p].id[ct] =
                          tempObj[ct][f_type][d][p].id;
                      }
                    }
                  }
                }
              }
            }
            tempObj.all = objectAll;

            dispatch(
              setPeriodDataKey({
                subObj: "periodData",
                type: payload.fileType,
                period: "six_hour",
                key: "dates",
                value: tempObj
              })
            );
            dispatch(
              setPeriodDataKey({
                subObj: "periodData",
                type: payload.fileType,
                period: "six_hour",
                key: "centers",
                // case insensitive sort so that "all" is first element
                value: Object.keys(tempObj).sort(function(a, b) {
                  return a.toLowerCase().localeCompare(b.toLowerCase());
                })
              })
            );

            let latestDate;
            let availablePeriods;
            if (isEmpty(payload.params)) {
              const firstCenter = Object.keys(tempObj).sort(function(a, b) {
                return a.toLowerCase().localeCompare(b.toLowerCase());
              })[0];
              dispatch(
                setProperty({
                  type: payload.fileType,
                  key: "selectedCenter",
                  value: firstCenter
                })
              );

              latestDate = Object.keys(tempObj[firstCenter]["BUOY"]).sort()[
                Object.keys(tempObj[firstCenter]["BUOY"]).length - 1
              ];
              dispatch(
                setProperty({
                  type: payload.fileType,
                  key: "selectedDate",
                  value: latestDate
                })
              );

              availablePeriods = tempObj[firstCenter]["BUOY"][latestDate];

              const arrAvailablePeriods = Object.keys(availablePeriods)
                .sort()
                .filter(p => availablePeriods[p].available);

              dispatch(
                setProperty({
                  type: payload.fileType,
                  key: "selectedSixHPeriod",
                  value: arrAvailablePeriods[arrAvailablePeriods.length - 1]
                })
              );
            } else if (payload.params.periodType === "six_hour") {
              latestDate = payload.params.date;
              // check if the date exist
              if (tempObj[payload.params.center]["BUOY"][latestDate]) {
                availablePeriods =
                  tempObj[payload.params.center]["BUOY"][latestDate];

                dispatch(
                  setProperty({
                    type: payload.fileType,
                    key: "selectedSixHPeriod",
                    value: payload.params.period
                  })
                );

                // if the date doesn't exist, trigger an error
              } else {
                dispatch(setError404());
              }

              // if the six-hour period is not available for this date,
              // also trigger an error
              if (
                tempObj[payload.params.center]["BUOY"][latestDate] &&
                typeof payload.params.period !== "undefined"
              ) {
                if (!availablePeriods[payload.params.period].available) {
                  dispatch(setError404());
                }
              }
            }

            dispatch(
              setProperty({
                type: payload.fileType,
                key: "currentSixHPeriods",
                value: availablePeriods
              })
            );

            if (
              isEmpty(payload.params) ||
              payload.params.periodType === "six_hour"
            ) {
              dispatch(
                setProperty({
                  type: payload.fileType,
                  key: "readyToFillMenu",
                  value: true
                })
              );
            }

            // set the data (for a particular fileType) as fetched
            // so that won't be fetched a second time
            dispatch(
              setProperty({
                type: payload.fileType,
                key: "dataFetched",
                value: true
              })
            );
          }
        })
        .catch(error => {
          // If there are URL params requesting the six-hour data
          // and an error while fetching the
          // six-hour data, trigger an error
          if (
            !isEmpty(payload.params) &&
            payload.params.periodType === "six_hour"
          ) {
            dispatch(setError404());
          }
          console.log(error);
          sixHourFetched = false;

          notification.error({
            message: "Error fetching API",
            description: "There was an error while fetching the six-hour data",
            top: 100,
            duration: 0
          });
          dispatch(
            setPeriodDataKey({
              subObj: "periodData",
              type: payload.fileType,
              period: "six_hour",
              key: "errorFetchingApi",
              value: true
            })
          );
        });
    }
    function fetchDailyBuoyShipMarinePeriod() {
      return fetchData(
        `${WDQMS_API}/daily-periods/?file_type=${payload.fileType}&format=json`
      )
        .then(response => {
          // response.data.length === 0 -> NO DATA
          if (response.data.length === 0) {
            dispatch(
              setPeriodDataKey({
                subObj: "periodData",
                type: payload.fileType,
                period: "daily",
                key: "noData",
                value: true
              })
            );
          } else {
            // if there was an error while fetching the six-hour or params are empty
            // set the selected period type to "daily"
            dailyFetched = true;
            if (!sixHourFetched) {
              dispatch(
                setProperty({
                  type: payload.fileType,
                  key: "selectedPeriodType",
                  value: "daily"
                })
              );
            }

            if (payload.fileType === "marine_surface") {
              dispatch(
                updatePeriodTypes({
                  type: payload.fileType,
                  value: ["daily"]
                })
              );
            }
            let tempObj = {};
            for (let p of response.data) {
              if (!tempObj[p.center]) {
                tempObj[p.center] = {};
                tempObj[p.center][p.file_type] = {};
              } else if (!tempObj[p.center][p.file_type]) {
                tempObj[p.center][p.file_type] = {};
              }
              
              tempObj[p.center][p.file_type][
                moment.utc(p.date).format("YYYY-MM-DD")
              ] = {
                id: p.id
              };
            }
            // The object that contains the dates for the 'combined' version (all centers)
            let objectAll = {};

            // loop through all centers
            for (let ct of Object.keys(tempObj)) {
              // loop through all dates
              // ct is DWD or ECMWF
              for (let f_type of Object.keys(tempObj[ct])) {
                // f_type is BUOY or SHIP
                if (!Object.keys(objectAll).includes(f_type)) {
                  objectAll[f_type] = {};
                }
                for (let d of Object.keys(tempObj[ct][f_type])) {
                  // d is the date
                  // if the date is not in arrayAll, add it
                  // if objectAll doesn't have the key 'd'
                  if (!Object.keys(objectAll[f_type]).includes(d)) {
                    objectAll[f_type][d] = {};
                  }
                  objectAll[f_type][d][ct] = tempObj[ct][f_type][d].id;
                }
              }
            }
            // Add objectAll to tempObj
            tempObj.all = objectAll;
            dispatch(
              setPeriodDataKey({
                subObj: "periodData",
                type: payload.fileType,
                period: "daily",
                key: "dates",
                value: tempObj
              })
            );

            dispatch(
              setPeriodDataKey({
                subObj: "periodData",
                type: payload.fileType,
                period: "daily",
                key: "centers",
                value: Object.keys(tempObj).sort(function(a, b) {
                  return a.toLowerCase().localeCompare(b.toLowerCase());
                })
              })
            );

            if (
              !isEmpty(payload.params) &&
              payload.params.periodType === "daily"
            ) {
              dispatch(
                setProperty({
                  type: payload.fileType,
                  key: "selectedCenter",
                  value: payload.params.center
                })
              );

              // if the date doesn't exist for this center,
              // set "selectedDate" to its latest date
              // note: it can happen that a date is available
              // for the six-hour periods but not for the daily
              if (
                !tempObj[payload.params.center]["BUOY"][payload.params.date]
              ) {
                const latestDate = Object.keys(
                  tempObj[payload.params.center]["BUOY"]
                ).sort()[
                  Object.keys(tempObj[payload.params.center]["BUOY"]).length - 1
                ];
                dispatch(
                  setProperty({
                    type: payload.fileType,
                    key: "selectedDate",
                    value: latestDate
                  })
                );
              } else {
                dispatch(
                  setProperty({
                    type: payload.fileType,
                    key: "selectedDate",
                    value: payload.params.date
                  })
                );
              }
            } else if (!sixHourFetched) {
              // Case no param - this should only be done
              // when there was an error fetching the six-hour data
              const firstCenter = Object.keys(tempObj).sort(function(a, b) {
                return a.toLowerCase().localeCompare(b.toLowerCase());
              })[0];

              dispatch(
                setProperty({
                  type: payload.fileType,
                  key: "selectedCenter",
                  value: firstCenter
                })
              );

              const latestDate = Object.keys(
                tempObj[firstCenter]["BUOY"]
              ).sort()[Object.keys(tempObj[firstCenter]["BUOY"]).length - 1];
              dispatch(
                setProperty({
                  type: payload.fileType,
                  key: "selectedDate",
                  value: latestDate
                })
              );
            }
            if (
              isEmpty(payload.params) ||
              payload.params.periodType === "daily"
            ) {
              dispatch(
                setProperty({
                  type: payload.fileType,
                  key: "readyToFillMenu",
                  value: true
                })
              );
            }
          }
        })
        .catch(error => {
          // If there are URL params requesting the daily data
          // and an error while fetching the
          // daily data, trigger an error
          console.log(error);
          if (
            !isEmpty(payload.params) &&
            payload.params.periodType === "daily"
          ) {
            dispatch(setError404());
          }
          notification.error({
            message: "Error fetching API",
            description: "There was an error while fetching the daily data",
            top: 100,
            duration: 0
          });
          dispatch(
            setPeriodDataKey({
              subObj: "periodData",
              type: payload.fileType,
              period: "daily",
              key: "errorFetchingApi",
              value: true
            })
          );
          if (payload.fileType === "marine_surface") {
            dispatch(
              setPeriodDataKey({
                subObj: "periodData",
                type: payload.fileType,
                period: "alert",
                key: "errorFetchingApi",
                value: true
              })
            );
          }
        });
    }
    function fetchMonthlyBuoyShipMarinePeriod() {
      fetchData(
        `${WDQMS_API}/monthly-periods/?file_type=${payload.fileType}&format=json`
      ).then(response => {
        if (response.data.length === 0) {
          dispatch(
            setPeriodDataKey({
              subObj: "periodData",
              type: payload.fileType,
              period: "monthly",
              key: "noData",
              value: true
            })
          );
          if (payload.fileType === "marine_surface") {
            dispatch(
              setPeriodDataKey({
                subObj: "periodData",
                type: payload.fileType,
                period: "monthly",
                key: "noData",
                value: true
              })
            );
          }
        } else {
          if (!dailyFetched) {
            dispatch(
              setProperty({
                type: payload.fileType,
                key: "selectedPeriodType",
                value: "monthly"
              })
            );
          }
          if (payload.fileType === "marine_surface") {
            dispatch(
              updatePeriodTypes({
                type: payload.fileType,
                value: ["monthly"]
              })
            );
          } else {
            // file type is ship
            dispatch(
              updatePeriodTypes({
                type: payload.fileType,
                value: ["monthly"]
              })
            );
          }
          let tempObj = {};

          for (let p of response.data.results) {
            if (!tempObj[p.center]) {
              tempObj[p.center] = {};
            }
            tempObj[p.center][moment.utc(p.date).format("YYYY-MM")] = {
              id: p.id
            };
          }
          // // The object that contains the dates for the 'combined' version (all centers)
          let objectAll = {};
          // loop through all centers
          for (let ct of Object.keys(tempObj)) {
            // loop through all dates
            for (let d of Object.keys(tempObj[ct])) {
              // if the date is not in arrayAll, add it
              // if objectAll doesn't have the key 'd'
              if (!Object.keys(objectAll).includes(d)) {
                objectAll[d] = {};
              }
              objectAll[d][ct] = tempObj[ct][d].id;
            }
          }
          // Add objectAll to tempObj
          tempObj.all = objectAll;
          dispatch(
            setPeriodDataKey({
              subObj: "periodData",
              type: payload.fileType,
              period: "monthly",
              key: "dates",
              value: tempObj
            })
          );
          if (payload.fileType === "marine_surface") {
            dispatch(
              setPeriodDataKey({
                subObj: "periodData",
                type: payload.fileType,
                period: "monthly",
                key: "dates",
                value: tempObj
              })
            );
          }
          dispatch(
            setPeriodDataKey({
              subObj: "periodData",
              type: payload.fileType,
              period: "monthly",
              key: "centers",
              value: Object.keys(tempObj).sort(function(a, b) {
                return a.toLowerCase().localeCompare(b.toLowerCase());
              })
            })
          );
          if (payload.fileType === "marine_surface") {
            dispatch(
              setPeriodDataKey({
                subObj: "periodData",
                type: payload.fileType,
                period: "monthly",
                key: "centers",
                value: Object.keys(tempObj).sort(function(a, b) {
                  return a.toLowerCase().localeCompare(b.toLowerCase());
                })
              })
            );
          }
          if (
            !isEmpty(payload.params) &&
            payload.params.periodType === "monthly"
          ) {
            dispatch(
              setProperty({
                type: payload.fileType,
                key: "selectedCenter",
                value: payload.params.center
              })
            );
            // if the date doesn't exist for this center,
            // set "selectedDate" to its latest date
            // note: it can happen that a date is available
            // for the six-hour periods but not for the daily
            if (!tempObj[payload.params.center][payload.params.date]) {
              const latestDate = Object.keys(
                tempObj[payload.params.center]
              ).sort()[Object.keys(tempObj[payload.params.center]).length - 1];
              dispatch(
                setProperty({
                  type: payload.fileType,
                  key: "selectedDate",
                  value: latestDate
                })
              );
            } else {
              dispatch(
                setProperty({
                  type: payload.fileType,
                  key: "selectedDate",
                  value: payload.params.date
                })
              );
            }
          } else if (!dailyFetched) {
            // Case no param - this should only be done
            // when there was an error fetching the six-hour data
            const firstCenter = Object.keys(tempObj).sort(function(a, b) {
              return a.toLowerCase().localeCompare(b.toLowerCase());
            })[0];

            dispatch(
              setProperty({
                type: payload.fileType,
                key: "selectedCenter",
                value: firstCenter
              })
            );

            const latestDate = Object.keys(tempObj[firstCenter]).sort()[
              Object.keys(tempObj[firstCenter]).length - 1
            ];

            dispatch(
              setProperty({
                type: payload.fileType,
                key: "selectedDate",
                value: latestDate
              })
            );
          }
          if (
            isEmpty(payload.params) ||
            payload.params.periodType === "monthly"
          ) {
            dispatch(
              setProperty({
                type: payload.fileType,
                key: "readyToFillMenu",
                value: true
              })
            );
          }
        }
      });
    }
  };
};
// export const fetchMarineDataFromApi = (payload, pageType) => {
//   console.log("fetchMarineDataFromApi");
//   // differentiate between requests coming from the map and from the database status
//   // differentiate between requests coming from the map and from the database status
//   if (pageType == "DB_STATUS") {
//     pageType = "&page_type=DB_STATUS";
//   }
//   return dispatch => {
//     // If we have URL params, set them to the selected
//     // params for the menu and map
//     if (!isEmpty(payload.params)) {
//       dispatch(
//         setProperty({
//           type: payload.fileType,
//           key: "selectedPeriodType",
//           value: payload.params.periodType
//         })
//       );

//       if (payload.fileType === "buoy") {
//         dispatch(
//           setProperty({
//             type: payload.fileType,
//             key: "selectedReport",
//             value: "quality"
//           })
//         );
//       } else if (payload.fileType === "ship") {
//         if (payload.params.variable) {
//           dispatch(
//             setProperty({
//               type: payload.fileType,
//               key: "selectedReport",
//               value: "quality"
//             })
//           );
//         } else {
//           // Otherwise, the report is "availability"
//           dispatch(
//             setProperty({
//               type: payload.fileType,
//               key: "selectedReport",
//               value: "availability"
//             })
//           );
//         }
//       }
//       if (payload.params.variable) {
//         dispatch(
//           setProperty({
//             type: payload.fileType,
//             key: "selectedVariable",
//             value: getVariableID(payload.fileType, payload.params.variable)
//           })
//         );
//       }

//       dispatch(
//         setProperty({
//           type: payload.fileType,
//           key: "selectedCenter",
//           value: payload.params.center
//         })
//       );

//       if (payload.params.center !== "all") {
//         dispatch(
//           setProperty({
//             type: payload.fileType,
//             key: "combinedMode",
//             value: false
//           })
//         );
//       }

//       dispatch(
//         setProperty({
//           type: payload.fileType,
//           key: "selectedDate",
//           value: payload.params.date
//         })
//       );
//     }

//     if (!payload.alreadyFetched) {
//       // Load intiatil periods of six hour (quick fetch to load page faster )
//       if (loadInitialPeriods(900, pageType, payload, "Marine", dispatch)) {
//         // Load all periods of six hour
//         loadAllPeriods(payload, pageType, "Marine").then(response => {
//           // set calendar periods
//           setCalendarPeriods(response, dispatch, payload, true);
//           dispatch(
//             setProperty({
//               type: payload.fileType,
//               key: "allDataFetched",
//               value: true
//             })
//           );
//         });
//       }
//     } else {
//       console.log(`${payload.fileType} data already fetched`);
//     }
//   };
// };

// export const fetchBuoyShipMarineDataFromApi = (payload, pageType) => {
//   console.log("fetchBuoyShipMarineDataFromApi");
//   // Fetching combined marine and buoy togeather.
//   if (pageType == "DB_STATUS") {
//     pageType = "&page_type=DB_STATUS";
//   }
//   return dispatch => {
//     // If we have URL params, set them to the selected
//     // params for the menu and map
//     if (!isEmpty(payload.params)) {
//       dispatch(
//         setProperty({
//           type: payload.fileType,
//           key: "selectedPeriodType",
//           value: payload.params.periodType
//         })
//       );

//       if (payload.fileType === "marine_surface") {
//         dispatch(
//           setProperty({
//             type: payload.fileType,
//             key: "selectedReport",
//             value: "quality"
//           })
//         );
//       }
//       if (payload.params.variable) {
//         dispatch(
//           setProperty({
//             type: payload.fileType,
//             key: "selectedVariable",
//             value: getVariableID(payload.fileType, payload.params.variable)
//           })
//         );
//       }

//       dispatch(
//         setProperty({
//           type: payload.fileType,
//           key: "selectedCenter",
//           value: payload.params.center
//         })
//       );

//       if (payload.params.center !== "all") {
//         dispatch(
//           setProperty({
//             type: payload.fileType,
//             key: "combinedMode",
//             value: false
//           })
//         );
//       }

//       dispatch(
//         setProperty({
//           type: payload.fileType,
//           key: "selectedDate",
//           value: payload.params.date
//         })
//       );
//     }
//     if (!payload.alreadyFetched) {
//       // Load intiatil periods of six hour (quick fetch to load page faster )
//       if (loadInitialPeriods(900, pageType, payload, "Marine", dispatch)) {
//         // Load all periods of six hour
//         loadAllPeriods(payload, pageType, "Marine").then(response => {
//           // set calendar periods
//           setCalendarPeriods(response, dispatch, payload, true);
//           dispatch(
//             setProperty({
//               type: payload.fileType,
//               key: "allDataFetched",
//               value: true
//             })
//           );
//         });
//       }
//     } else {
//       console.log(`${payload.fileType} data already fetched`);
//     }
//   };
// };

export const setSelectedStation = data => {
  return {
    type: actionTypes.SET_SELECTED_STATION,
    payload: data
  };
};

export const initializeMap = data => {
  return {
    type: actionTypes.INITIALIZE_MAP,
    payload: data
  };
};

export const fetchGCOSDataFromApi = payload => {
  return dispatch => {
    // If we have URL params, set them to the selected
    // params for the menu and map
    if (!isEmpty(payload.params)) {
      if (payload.params.variable) {
        let report;
        // For GUAN if there is 'payload.params.variable'
        // it means that the report is "quality"
        if (payload.fileType === "guan") {
          report = "quality";
        } else if (payload.fileType === "gsn") {
          // For GSN if there is 'payload.params.variable'
          // it means that the report is "completeness"
          report = "completeness";
        }
        dispatch(
          setProperty({
            type: payload.fileType,
            key: "selectedReport",
            value: report
          })
        );

        dispatch(
          setProperty({
            type: payload.fileType,
            key: "selectedVariable",
            value: payload.params.variable
          })
        );
      } else {
        // Otherwise, the report is "availability"
        dispatch(
          setProperty({
            type: payload.fileType,
            key: "selectedReport",
            value: "availability"
          })
        );
      }

      dispatch(
        setProperty({
          type: payload.fileType,
          key: "selectedDate",
          value: payload.params.date
        })
      );
    }

    if (!payload.alreadyFetched) {
      fetchData(`${WDQMS_API}/gcos/periods/?network=${payload.fileType}`)
        .then(response => {
          // response.data.length === 0 -> NO DATA
          if (response.data.length === 0) {
            dispatch(
              setProperty({
                type: payload.fileType,
                key: "noData",
                value: true
              })
            );
            // set the data (for a particular fileType) as fetched
            // so that won't be fetched a second time
            dispatch(
              setProperty({
                type: payload.fileType,
                key: "dataFetched",
                value: true
              })
            );
          } else {
            let tempObj = {};
            for (let p of response.data) {
              tempObj[moment.utc(p.date).format("YYYY-MM")] = {
                id: p.id
              };
            }

            dispatch(
              setProperty({
                type: payload.fileType,
                key: "dates",
                value: tempObj
              })
            );

            // the default date is the lastest one available
            const lastDate = Object.keys(tempObj)[0];

            if (!payload.params.date) {
              dispatch(
                setProperty({
                  type: payload.fileType,
                  key: "selectedDate",
                  value: lastDate
                })
              );
            }
            dispatch(
              setProperty({
                type: payload.fileType,
                key: "readyToFillMenu",
                value: true
              })
            );
            // set the data (for a particular fileType) as fetched
            // so that won't be fetched a second time
            dispatch(
              setProperty({
                type: payload.fileType,
                key: "dataFetched",
                value: true
              })
            );
          }
        })
        .catch(error => {
          // TO DO
        });
    } else {
      console.log(`${payload.fileType} data already fetched`);
    }
  };
};

// Load the initial periods of six-hour and daily periods
function loadInitialPeriods(page_size, pageType, payload, baseline, dispatch) {
  let date_param = "";
  if (payload.params["date"]) {
    date_param = `&date=${payload.params["date"]}`;
    if (payload.params.periodType === "monthly") {
      // convert date param from YYYY-MM to YYYY-MM-01
      date_param = `&date=${payload.params["date"]}-01`;
    }
  }
  let file_type = payload.fileType;
  let baselineParam = "";
  if (baseline === "Gbon") {
    file_type = payload.parentFileType;
    baselineParam = `&baseline=${payload.baseline}`;
  }
  fetchData(
    `${WDQMS_API}/sixhour-periods/?file_type=${file_type}${pageType}${baselineParam}&page_size=${page_size}${date_param}`
  )
    .then(response => {
      // response.data.length === 0 -> NO DATA
      if (response.data.length === 0) {
        dispatch(
          setPeriodDataKey({
            subObj: "periodData",
            type: payload.fileType,
            period: "six_hour",
            key: "noData",
            value: true
          })
        );
        // set the data (for a particular fileType) as fetched
        // so that won't be fetched a second time
        dispatch(
          setProperty({
            type: payload.fileType,
            key: "dataFetched",
            value: true
          })
        );
      } else {
        sixHourFetched = true;
        if (isEmpty(payload.params)) {
          dispatch(
            setProperty({
              type: payload.fileType,
              key: "selectedPeriodType",
              value: "six_hour"
            })
          );
        }

        dispatch(
          updatePeriodTypes({
            type: payload.fileType,
            value: "six_hour"
          })
        );
        setCalendarPeriods(response.data.results, dispatch, payload, false);

        if (
          isEmpty(payload.params) ||
          payload.params.periodType === "six_hour"
        ) {
          dispatch(
            setProperty({
              type: payload.fileType,
              key: "readyToFillMenu",
              value: true
            })
          );
        }

        // set the data (for a particular fileType) as fetched
        // so that won't be fetched a second time
        dispatch(
          setProperty({
            type: payload.fileType,
            key: "dataFetched",
            value: true
          })
        );
      }
    })
    .catch(error => {
      // If there are URL params requesting the six-hour data
      // and an error while fetching the
      // six-hour data, trigger an error
      console.log("error", error);
      if (
        !isEmpty(payload.params) &&
        payload.params.periodType === "six_hour"
      ) {
        dispatch(setError404());
      }

      sixHourFetched = false;

      notification.error({
        message: "Error fetching API",
        description: "There was an error while fetching the six-hour data",
        top: 100,
        duration: 0
      });
      dispatch(
        setPeriodDataKey({
          subObj: "periodData",
          type: payload.fileType,
          period: "six_hour",
          key: "errorFetchingApi",
          value: true
        })
      );
    })
    .then(response => {
      fetchData(
        `${WDQMS_API}/daily-periods/?file_type=${file_type}${pageType}${baselineParam}`
      )
        .then(response => {
          // response.data.length === 0 -> NO DATA
          if (response.data.length === 0) {
            dispatch(
              setPeriodDataKey({
                subObj: "periodData",
                type: payload.fileType,
                period: "daily",
                key: "noData",
                value: true
              })
            );
            // Alert only for SYNOP for now
            // Alert contains the same data as Daily
            if (payload.fileType === "synop") {
              dispatch(
                setPeriodDataKey({
                  subObj: "periodData",
                  type: payload.fileType,
                  period: "alert",
                  key: "noData",
                  value: true
                })
              );
            }
          } else {
            // if there was an error while fetching the six-hour
            // set the selected period type to "daily"
            dailyFetched = true;
            if (!sixHourFetched) {
              dispatch(
                setProperty({
                  type: payload.fileType,
                  key: "selectedPeriodType",
                  value: "daily"
                })
              );
            }
            if (payload.fileType === "synop") {
              dispatch(
                updatePeriodTypes({
                  type: payload.fileType,
                  value: ["daily", "alert"]
                })
              );
            } else {
              // file type is temp
              dispatch(
                updatePeriodTypes({
                  type: payload.fileType,
                  value: ["daily"]
                })
              );
            }

            let tempObj = {};
            for (let p of response.data) {
              if (!tempObj[p.center]) {
                tempObj[p.center] = {};
              }
              tempObj[p.center][moment.utc(p.date).format("YYYY-MM-DD")] = {
                id: p.id
              };
            }
            // The object that contains the dates for the 'combined' version (all centers)
            let objectAll = {};

            // loop through all centers
            for (let ct of Object.keys(tempObj)) {
              // loop through all dates
              for (let d of Object.keys(tempObj[ct])) {
                // if the date is not in arrayAll, add it
                // if objectAll doesn't have the key 'd'
                if (!Object.keys(objectAll).includes(d)) {
                  objectAll[d] = {};
                }
                objectAll[d][ct] = tempObj[ct][d].id;
              }
            }
            // Add objectAll to tempObj
            tempObj.all = objectAll;

            dispatch(
              setPeriodDataKey({
                subObj: "periodData",
                type: payload.fileType,
                period: "daily",
                key: "dates",
                value: tempObj
              })
            );

            if (payload.fileType === "synop") {
              dispatch(
                setPeriodDataKey({
                  subObj: "periodData",
                  type: payload.fileType,
                  period: "alert",
                  key: "dates",
                  value: tempObj
                })
              );
            }

            dispatch(
              setPeriodDataKey({
                subObj: "periodData",
                type: payload.fileType,
                period: "daily",
                key: "centers",
                value: Object.keys(tempObj).sort(function(a, b) {
                  return a.toLowerCase().localeCompare(b.toLowerCase());
                })
              })
            );
            if (payload.fileType === "synop") {
              dispatch(
                setPeriodDataKey({
                  subObj: "periodData",
                  type: payload.fileType,
                  period: "alert",
                  key: "centers",
                  value: Object.keys(tempObj).sort(function(a, b) {
                    return a.toLowerCase().localeCompare(b.toLowerCase());
                  })
                })
              );
            }

            if (
              !isEmpty(payload.params) &&
              payload.params.periodType === "daily"
            ) {
              dispatch(
                setProperty({
                  type: payload.fileType,
                  key: "selectedCenter",
                  value: payload.params.center
                })
              );

              // if the date doesn't exist for this center,
              // set "selectedDate" to its latest date
              // note: it can happen that a date is available
              // for the six-hour periods but not for the daily
              if (!tempObj[payload.params.center][payload.params.date]) {
                const latestDate = Object.keys(
                  tempObj[payload.params.center]
                ).sort()[
                  Object.keys(tempObj[payload.params.center]).length - 1
                ];

                dispatch(
                  setProperty({
                    type: payload.fileType,
                    key: "selectedDate",
                    value: latestDate
                  })
                );
              } else {
                dispatch(
                  setProperty({
                    type: payload.fileType,
                    key: "selectedDate",
                    value: payload.params.date
                  })
                );
              }
            } else if (!sixHourFetched) {
              // Case no param - this should only be done
              // when there was an error fetching the six-hour data
              const firstCenter = Object.keys(tempObj).sort(function(a, b) {
                return a.toLowerCase().localeCompare(b.toLowerCase());
              })[0];

              dispatch(
                setProperty({
                  type: payload.fileType,
                  key: "selectedCenter",
                  value: firstCenter
                })
              );

              const latestDate = Object.keys(tempObj[firstCenter]).sort()[
                Object.keys(tempObj[firstCenter]).length - 1
              ];

              dispatch(
                setProperty({
                  type: payload.fileType,
                  key: "selectedDate",
                  value: latestDate
                })
              );
            }
            if (
              isEmpty(payload.params) ||
              payload.params.periodType === "daily"
            ) {
              dispatch(
                setProperty({
                  type: payload.fileType,
                  key: "readyToFillMenu",
                  value: true
                })
              );
            }
            if (
              isEmpty(payload.params) ||
              payload.params.periodType === "alert"
            ) {
              dispatch(
                setProperty({
                  type: payload.fileType,
                  key: "readyToFillMenu",
                  value: true
                })
              );
            }
          }
        })
        .catch(error => {
          // If there are URL params requesting the daily data
          // and an error while fetching the
          // daily data, trigger an error
          console.log(error);
          if (
            !isEmpty(payload.params) &&
            payload.params.periodType === "daily"
          ) {
            dispatch(setError404());
          }
          notification.error({
            message: "Error fetching API",
            description: "There was an error while fetching the daily data",
            top: 100,
            duration: 0
          });
          dispatch(
            setPeriodDataKey({
              subObj: "periodData",
              type: payload.fileType,
              period: "daily",
              key: "errorFetchingApi",
              value: true
            })
          );
          if (payload.fileType === "synop") {
            dispatch(
              setPeriodDataKey({
                subObj: "periodData",
                type: payload.fileType,
                period: "alert",
                key: "errorFetchingApi",
                value: true
              })
            );
          }
        })
        .then(response => {
          fetchData(
            `${WDQMS_API}/monthly-periods/?file_type=${file_type}${baselineParam}`
          ).then(response => {
            if (response.data.length === 0) {
              dispatch(
                setPeriodDataKey({
                  subObj: "periodData",
                  type: payload.fileType,
                  period: "monthly",
                  key: "noData",
                  value: true
                })
              );
              if (payload.fileType === "synop") {
                dispatch(
                  setPeriodDataKey({
                    subObj: "periodData",
                    type: payload.fileType,
                    period: "monthly",
                    key: "noData",
                    value: true
                  })
                );
              }
            } else {
              if (!dailyFetched) {
                dispatch(
                  setProperty({
                    type: payload.fileType,
                    key: "selectedPeriodType",
                    value: "monthly"
                  })
                );
              }
              if (payload.fileType === "synop") {
                dispatch(
                  updatePeriodTypes({
                    type: payload.fileType,
                    value: ["monthly"]
                  })
                );
              } else {
                // file type is temp
                dispatch(
                  updatePeriodTypes({
                    type: payload.fileType,
                    value: ["monthly"]
                  })
                );
              }
              let tempObj = {};
              for (let p of response.data) {
                if (!tempObj[p.center]) {
                  tempObj[p.center] = {};
                }
                tempObj[p.center][moment.utc(p.date).format("YYYY-MM")] = {
                  id: p.id
                };
              }
              // // The object that contains the dates for the 'combined' version (all centers)
              let objectAll = {};
              // loop through all centers
              for (let ct of Object.keys(tempObj)) {
                // loop through all dates
                for (let d of Object.keys(tempObj[ct])) {
                  // if the date is not in arrayAll, add it
                  // if objectAll doesn't have the key 'd'
                  if (!Object.keys(objectAll).includes(d)) {
                    objectAll[d] = {};
                  }
                  objectAll[d][ct] = tempObj[ct][d].id;
                }
              }
              // Add objectAll to tempObj
              tempObj.all = objectAll;
              dispatch(
                setPeriodDataKey({
                  subObj: "periodData",
                  type: payload.fileType,
                  period: "monthly",
                  key: "dates",
                  value: tempObj
                })
              );
              if (payload.fileType === "synop") {
                dispatch(
                  setPeriodDataKey({
                    subObj: "periodData",
                    type: payload.fileType,
                    period: "monthly",
                    key: "dates",
                    value: tempObj
                  })
                );
              }
              dispatch(
                setPeriodDataKey({
                  subObj: "periodData",
                  type: payload.fileType,
                  period: "monthly",
                  key: "centers",
                  value: Object.keys(tempObj).sort(function(a, b) {
                    return a.toLowerCase().localeCompare(b.toLowerCase());
                  })
                })
              );
              if (payload.fileType === "synop") {
                dispatch(
                  setPeriodDataKey({
                    subObj: "periodData",
                    type: payload.fileType,
                    period: "monthly",
                    key: "centers",
                    value: Object.keys(tempObj).sort(function(a, b) {
                      return a.toLowerCase().localeCompare(b.toLowerCase());
                    })
                  })
                );
              }
              if (
                !isEmpty(payload.params) &&
                payload.params.periodType === "monthly"
              ) {
                dispatch(
                  setProperty({
                    type: payload.fileType,
                    key: "selectedCenter",
                    value: payload.params.center
                  })
                );
                // if the date doesn't exist for this center,
                // set "selectedDate" to its latest date
                // note: it can happen that a date is available
                // for the six-hour periods but not for the daily
                if (!tempObj[payload.params.center][payload.params.date]) {
                  const latestDate = Object.keys(
                    tempObj[payload.params.center]
                  ).sort()[
                    Object.keys(tempObj[payload.params.center]).length - 1
                  ];
                  dispatch(
                    setProperty({
                      type: payload.fileType,
                      key: "selectedDate",
                      value: latestDate
                    })
                  );
                } else {
                  dispatch(
                    setProperty({
                      type: payload.fileType,
                      key: "selectedDate",
                      value: payload.params.date
                    })
                  );
                }
              } else if (!dailyFetched) {
                // Case no param - this should only be done
                // when there was an error fetching the six-hour data
                const firstCenter = Object.keys(tempObj).sort(function(a, b) {
                  return a.toLowerCase().localeCompare(b.toLowerCase());
                })[0];

                dispatch(
                  setProperty({
                    type: payload.fileType,
                    key: "selectedCenter",
                    value: firstCenter
                  })
                );

                const latestDate = Object.keys(tempObj[firstCenter]).sort()[
                  Object.keys(tempObj[firstCenter]).length - 1
                ];

                dispatch(
                  setProperty({
                    type: payload.fileType,
                    key: "selectedDate",
                    value: latestDate
                  })
                );
              }
              if (
                isEmpty(payload.params) ||
                payload.params.periodType === "monthly"
              ) {
                dispatch(
                  setProperty({
                    type: payload.fileType,
                    key: "readyToFillMenu",
                    value: true
                  })
                );
              }
            }
          });
        });
    });
  return true;
}

// Load all six-hour periods asynchronously and return the data
async function loadAllPeriods(payload, pageType, baseline = "Oscar") {
  let data = [];
  let file_type = payload.fileType;
  let baselineParam = "";
  if (baseline === "Gbon") {
    file_type = payload.parentFileType;
    baselineParam = `&baseline=${payload.baseline}`;
  }

  let nextPage = `${WDQMS_API}/sixhour-periods/?file_type=${file_type}${pageType}${baselineParam}&page=1&page_size=8000`;
  while (nextPage) {
    try {
      const response = await fetch(nextPage);
      const jsonResponse = await response.json();
      data = data.concat(jsonResponse.results); // Assuming the API response structure includes a 'results' field
      // console.log("Fetching data of next page: ", nextPage);
      nextPage = jsonResponse.next; // Update nextPage based on the 'next' field in the response
    } catch (error) {
      console.error("Fetching data error: ", error);
      break; // Exit loop in case of error
    }
  }
  return data;
}

// Set calendar periods for the six-hour periods
function setCalendarPeriods(responseResults, dispatch, payload, allPeriods) {
  if (responseResults) {
    let tempObj = {};
    for (let p of responseResults) {
      if (!tempObj[p.center]) {
        tempObj[p.center] = {};
      }
      if (!tempObj[p.center][moment.utc(p.date).format("YYYY-MM-DD")]) {
        tempObj[p.center][moment.utc(p.date).format("YYYY-MM-DD")] = {
          "00": { available: false, id: null },
          "06": { available: false, id: null },
          "12": { available: false, id: null },
          "18": { available: false, id: null }
        };
      }
      tempObj[p.center][moment.utc(p.date).format("YYYY-MM-DD")][
        moment.utc(p.date).format("HH")
      ].available = true;
      tempObj[p.center][moment.utc(p.date).format("YYYY-MM-DD")][
        moment.utc(p.date).format("HH")
      ].id = p.id;
    }
    // The object that contains the dates for the 'combined' version (all centers)
    let objectAll = {};
    // loop through all centers
    for (let ct of Object.keys(tempObj)) {
      // loop through all dates
      for (let d of Object.keys(tempObj[ct])) {
        // if objectAll doesn't have the key 'd'
        if (!Object.keys(objectAll).includes(d)) {
          objectAll[d] = {};
          // loop through the 4 six-hour periods
          for (let p of Object.keys(tempObj[ct][d])) {
            if (tempObj[ct][d][p].available) {
              objectAll[d][p] = {};
              objectAll[d][p].available = true;
              objectAll[d][p].id = {};
              objectAll[d][p].id[ct] = tempObj[ct][d][p].id;
            } else {
              objectAll[d][p] = {
                available: false,
                id: {}
              };
            }
          }
        } else {
          for (let p of Object.keys(tempObj[ct][d])) {
            if (tempObj[ct][d][p].available) {
              objectAll[d][p].available = true;
              objectAll[d][p].id[ct] = tempObj[ct][d][p].id;
            }
          }
        }
      }
    }
    tempObj.all = objectAll;
    dispatch(
      setPeriodDataKey({
        subObj: "periodData",
        type: payload.fileType,
        period: "six_hour",
        key: "dates",
        value: tempObj
      })
    );
    dispatch(
      setPeriodDataKey({
        subObj: "periodData",
        type: payload.fileType,
        period: "six_hour",
        key: "centers",
        // case insensitive sort so that "all" is first element
        value: Object.keys(tempObj).sort(function(a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        })
      })
    );
    if (!allPeriods) {
      let latestDate;
      let availablePeriods;
      if (isEmpty(payload.params)) {
        const firstCenter = Object.keys(tempObj).sort(function(a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        })[0];
        dispatch(
          setProperty({
            type: payload.fileType,
            key: "selectedCenter",
            value: firstCenter
          })
        );

        latestDate = Object.keys(tempObj[firstCenter]).sort()[
          Object.keys(tempObj[firstCenter]).length - 1
        ];
        dispatch(
          setProperty({
            type: payload.fileType,
            key: "selectedDate",
            value: latestDate
          })
        );
        availablePeriods = tempObj[firstCenter][latestDate];

        const arrAvailablePeriods = Object.keys(availablePeriods)
          .sort()
          .filter(p => availablePeriods[p].available);

        dispatch(
          setProperty({
            type: payload.fileType,
            key: "selectedSixHPeriod",
            value: arrAvailablePeriods[arrAvailablePeriods.length - 1]
          })
        );
      } else if (payload.params.periodType === "six_hour") {
        latestDate = payload.params.date;
        // check if the date exist
        if (tempObj[payload.params.center][latestDate]) {
          availablePeriods = tempObj[payload.params.center][latestDate];

          dispatch(
            setProperty({
              type: payload.fileType,
              key: "selectedSixHPeriod",
              value: payload.params.period
            })
          );

          // if the date doesn't exist, trigger an error
        } else {
          dispatch(setError404());
        }

        // if the six-hour period is not available for this date,
        // also trigger an error
        if (
          tempObj[payload.params.center][latestDate] &&
          typeof payload.params.period !== "undefined"
        ) {
          if (!availablePeriods[payload.params.period].available) {
            dispatch(setError404());
          }
        }
      }
      // if (!allPeriods) {
      dispatch(
        setProperty({
          type: payload.fileType,
          key: "currentSixHPeriods",
          value: availablePeriods
        })
      );
    }
  }
}
