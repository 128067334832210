import React from "react";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import AppMenu from "./components/Menu/Menu";
import { initGA } from "./Analytics";
import Router from "../src/router";

import "./App.css";

const App = props => {
  initGA();
  return (
    <div className="level1">
      <Header />
      <AppMenu />
      <main className="wdqms-content">
        <Router />
      </main>
      <Footer />
    </div>
  );
};
export default App;
