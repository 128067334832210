import React from "react";
import { connect } from "react-redux";
import { Tooltip } from "antd";

import { getDaysInMonth } from "../../../utils/general";
import {
  getNWPAvailabilityClassFilter,
  getNWPQualityClassFilter
} from "../../../utils/mapFilters/nwp";
import {
  getGUANAvailabilityClassFilter,
  getGUANQualityClassFilter,
  getGSNAvailabilityClassFilter,
  getGSNCompletenessClassFilter
} from "../../../utils/mapFilters/gcos";
import { getGBONAvailabilityClassFilter } from "../../../utils/mapFilters/gbon";
import "./Legend.css";
import infoIcon from "../../../images/icons/info.svg";

// returns the index of an array inside another array, -1 otherwise
// source: https://stackoverflow.com/questions/6315180/javascript-search-array-of-arrays
function indexOfArray(val, array) {
  var hash = {};
  for (let i = 0; i < array.length; i++) {
    hash[array[i]] = i;
  }
  return hash.hasOwnProperty(val) ? hash[val] : -1;
}

const Legend = props => {
  let prefix = "";
  if (props.baseline === "GBON") {
    prefix = "gbon_";
  }

  const ftype = props[prefix + props.fileType];

  // Get the number of days in the month
  // this is used for the filter in GSN/Completeness
  const nbDays = getDaysInMonth(ftype.selectedDate);

  const filterLayer = event => {
    const classes = event.target.className.split(" ");
    const filterLabel = event.target.getAttribute("data-filter");

    const layerName = props.map.getStyle().layers[
      props.map.getStyle().layers.length - 1
    ].id;
    let buoy_layer;
    let ship_layer;
    if (props.fileType === "marine_surface") {
      buoy_layer = props.map.getStyle().layers[
        props.map.getStyle().layers.length - 2
      ].id;

      ship_layer = props.map.getStyle().layers[
        props.map.getStyle().layers.length - 1
      ].id;
    }

    const currentFilter = props.map.getFilter(layerName);
    let newFilter;
    if (
      props.fileType === "synop" ||
      props.fileType === "temp" ||
      props.fileType === "ship" ||
      props.fileType === "buoy" ||
      props.fileType === "marine_surface"
    ) {
      if (ftype.selectedReport === "availability") {
        if (props.baseline === "GBON") {
          newFilter = getGBONAvailabilityClassFilter(
            filterLabel,
            ftype.baseline,
            ftype.selectedPeriodType,
            props.fileType
          );
        } else {
          newFilter = getNWPAvailabilityClassFilter(
            filterLabel,
            ftype.baseline,
            ftype.selectedPeriodType,
            props.fileType,
            ftype.selectedDate
          );
        }
      } else {
        // quality
        newFilter = getNWPQualityClassFilter(filterLabel);
      }
    } else if (props.fileType === "guan") {
      if (ftype.selectedReport === "availability") {
        newFilter = getGUANAvailabilityClassFilter(filterLabel);
      } else {
        // quality
        newFilter = getGUANQualityClassFilter(
          filterLabel,
          ftype.selectedVariable
        );
      }
    } else if (props.fileType === "gsn") {
      if (ftype.selectedReport === "availability") {
        newFilter = getGSNAvailabilityClassFilter(filterLabel);
      } else {
        // Completeness;
        newFilter = getGSNCompletenessClassFilter(
          filterLabel,
          ftype.selectedVariable,
          nbDays
        );
      }
    } else if (props.fileType in ["buoy", "ship", "marine_surface"]) {
      // quality
      newFilter = getNWPQualityClassFilter(filterLabel);
    }
    // Adding the class back
    if (classes.includes("unselected")) {
      event.target.classList.remove("unselected");

      const index = indexOfArray(newFilter, currentFilter);

      currentFilter.splice(index, 1);

      if (props.fileType === "marine_surface") {
        props.map.setFilter(buoy_layer, currentFilter);
        props.map.setFilter(ship_layer, currentFilter);
      } else {
        props.map.setFilter(layerName, currentFilter);
      }
    } else {
      // filtering out the class
      event.target.classList.add("unselected");
      let f;
      if (currentFilter) {
        currentFilter.push(newFilter);
        f = currentFilter;
      } else {
        f = ["all", newFilter];
      }
      if (props.fileType === "marine_surface") {
        props.map.setFilter(buoy_layer, f);
        props.map.setFilter(ship_layer, f);
      } else {
        props.map.setFilter(layerName, f);
      }
    }
  };
  const title = props.legendContent.title ? (
    <h4>{props.legendContent.title}</h4>
  ) : null;

  const body = props.legendContent.body.map(e => {
    let asterisk = null;
    if (e.asterisk) {
      asterisk = (
        <Tooltip placement="rightBottom" title={e.asterisk}>
          <img
            src={infoIcon}
            alt="Icon info legend element"
            className="legend-info"
          />
        </Tooltip>
      );
    }
    let styleClass = { backgroundColor: e.color };
    let actionOnClick = filterLayer;
    let classLegend = "legend-class-clickable";
    if (e.stroke) {
      styleClass = { border: `1px solid ${e.color}` };
      actionOnClick = null;
      classLegend = "legend-class-not-clickable";
    }
    return (
      <div key={e.color} className="legend_class-row">
        <div
          className={classLegend}
          key={e.color}
          onClick={actionOnClick}
          data-filter={e.filter}
        >
          <span className="legend-element-style" style={styleClass} />
          {e.label}
        </div>
        {asterisk}
      </div>
    );
  });

  const visible = props.isVisible ? "block" : "none";

  const divStyle = {
    display: visible
  };
  return (
    <div className="legend" style={divStyle}>
      {title}
      {body}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    map: state.map,
    synop: state.synop,
    temp: state.temp,
    gbon_synop: state.gbon_synop,
    gbon_temp: state.gbon_temp,
    guan: state.guan,
    gsn: state.gsn,
    buoy: state.buoy,
    ship: state.ship,
    marine_surface: state.marine_surface
  };
};

export default connect(mapStateToProps)(Legend);
