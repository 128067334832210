import { makeFilterQualityEdge, makeFilterQualityMiddle } from "./utils";

// For GUAN and GSN

// GUAN

export const getGUANAvailabilityClassFilter = label => {
  switch (label) {
    case "cl-guan-avail-gt25":
      return ["<", ["get", "availability"], 25];
    case "cl-guan-avail-lt25":
      return [
        "any",
        [">=", ["get", "availability"], 25],
        ["<", ["get", "availability"], 15]
      ];
    case "cl-guan-avail-lt15":
      return [
        "any",
        [">=", ["get", "availability"], 15],
        ["<=", ["get", "availability"], 0]
      ];
    case "cl-guan-avail-zero":
      return ["!=", ["get", "availability"], 0];
    case "cl-guan-avail-nodata":
      return ["!=", ["get", "availability"], -1];
    default:
      return [];
  }
};

export const getGUANQualityClassFilter = (label, field) => {
  switch (label) {
    case "cl-guan-quality-gt-30":
      return makeFilterQualityEdge(field, "<=", 30);
    case "cl-guan-quality-gt-15":
      return makeFilterQualityEdge(field, "<=", 15);
    case "cl-guan-quality-gt-10":
      return makeFilterQualityEdge(field, "<=", 10);
    case "cl-guan-quality-gt-15-le-30":
      return makeFilterQualityMiddle(field, 15, 30);
    case "cl-guan-quality-gt-10-le-15":
      return makeFilterQualityMiddle(field, 10, 15);
    case "cl-guan-quality-gt-8-le-15":
      return makeFilterQualityMiddle(field, 8, 15);
    case "cl-guan-quality-gt-5-le-10":
      return makeFilterQualityMiddle(field, 5, 10);
    case "cl-guan-quality-gt-5-le-8":
      return makeFilterQualityMiddle(field, 5, 8);
    case "cl-guan-quality-gt-3-le-5":
      return makeFilterQualityMiddle(field, 3, 5);
    case "cl-guan-quality-gt-2-le-5":
      return makeFilterQualityMiddle(field, 2, 5);
    case "cl-guan-quality-gt-1-le-3":
      return makeFilterQualityMiddle(field, 1, 3);
    case "cl-guan-quality-gt-0p5-le-1":
      return makeFilterQualityMiddle(field, 0.5, 1);
    case "cl-guan-quality-le-2":
      return makeFilterQualityEdge(field, ">", 2);
    case "cl-guan-quality-le-1":
      return makeFilterQualityEdge(field, ">", 1);
    case "cl-guan-quality-le-0p5":
      return makeFilterQualityEdge(field, ">", 0.5);
    default:
      return [];
  }
};

// GSN

export const getGSNAvailabilityClassFilter = label => {
  switch (label) {
    case "cl-gsn-avail-reporting":
      return [
        "any",
        ["!=", ["get", "availability"], true],
        ["!=", ["get", "in_oscar"], true]
      ];
    case "cl-gsn-avail-no-match":
      return [
        "any",
        ["!=", ["get", "availability"], true],
        ["!=", ["get", "in_oscar"], false]
      ];
    case "cl-gsn-avail-not-reporting":
      return [
        "any",
        ["!=", ["get", "availability"], false],
        ["!=", ["get", "in_oscar"], true]
      ];
    case "cl-gsn-avail-not-reporting-no-match":
      return [
        "any",
        ["!=", ["get", "availability"], false],
        ["!=", ["get", "in_oscar"], false]
      ];
    default:
      return [];
  }
};

export const getGSNCompletenessClassFilter = (label, field, nbDays) => {
  const column_name = `days_with_${field}`;
  switch (label) {
    case "cl-gsn-completeness-top":
      return ["<", ["get", column_name], nbDays];
    case "cl-gsn-completeness-middle":
      return [
        "any",
        [">=", ["get", column_name], nbDays],
        ["<", ["get", column_name], 15]
      ];
    case "cl-gsn-completeness-low":
      return [
        "any",
        [">=", ["get", column_name], 15],
        ["<", ["get", column_name], 1]
      ];
    case "cl-gsn-completeness-null":
      return ["!=", ["get", column_name], 0];
    case "cl-gsn-completeness-not-provided":
      return [">", ["get", column_name], -1];
    default:
      return [];
  }
};
