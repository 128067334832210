const noData = "#000000";
const notInOscar = "#e5e600";

export const colors = {
  map: {
    classNeeded: "#ffffff",
    defaultStroke: noData,
    synop: {
      availability: [
        "#ff33cc",
        "#009900",
        "#ff9900",
        "#ff3300",
        noData,
        "#969696",
        notInOscar
      ],
      quality: [
        "#006d2c",
        "#41ab5d",
        "#a1d99b",
        "#fed976",
        "#fd8d3c",
        "#e31a1c"
      ],
      alertStroke: "#bdbdbd"
    },
    temp: {
      availability: {
        sixhour: ["#009900", "#ff9900", "#ff3300", noData, notInOscar],
        daily: ["#ff33cc", "#009900", "#ff9900", "#ff3300", noData, notInOscar]
      },
      quality: [
        "#006d2c",
        "#41ab5d",
        "#a1d99b",
        "#fed976",
        "#fd8d3c",
        "#e31a1c"
      ]
    },
    guan: {
      availability: ["#009900", "#FFFF00", "#ff9900", "#ff3300", noData],
      quality: [
        "#006d2c",
        "#41ab5d",
        "#a1d99b",
        "#fed976",
        "#fd8d3c",
        "#e31a1c"
      ]
    },
    gsn: {
      availability: ["#009900", notInOscar, noData, "#969696"],
      completeness: ["#009900", "#FFFF00", "#ff9900", "#ff3300", "#8c510a"]
    },
    buoy: {
      quality: [
        "#006d2c",
        "#41ab5d",
        "#a1d99b",
        "#fed976",
        "#fd8d3c",
        "#e31a1c"
      ],
      alertStroke: "#bdbdbd"
    },
    ship: {
      quality: [
        "#006d2c",
        "#41ab5d",
        "#a1d99b",
        "#fed976",
        "#fd8d3c",
        "#e31a1c"
      ],
      alertStroke: "#bdbdbd"
    },
    marine_surface: {
      quality: [
        "#006d2c",
        "#41ab5d",
        "#a1d99b",
        "#fed976",
        "#fd8d3c",
        "#e31a1c"
      ],
      alertStroke: "#bdbdbd"
    }
  },
  chart: {
    centers: {
      dwd: "#F1CB49",
      ecmwf: "#30F196",
      jma: "#B77CEE",
      ncep: "#FA6AB0"
    },
    availability: {
      expected: "#5B77FF"
    },
    quality: {
      simple: "#bf812d"
    },
    alert: {
      simple: "#ed8078"
    },
    levels: {
      dwd: {
        stra: "#F1CB49",
        trop: "#ba9000"
      },
      ecmwf: {
        stra: "#30F196",
        trop: "#048a4b"
      },
      jma: {
        stra: "#B77CEE",
        trop: "#4f0099"
      },
      ncep: {
        stra: "#FA6AB0",
        trop: "#b00056"
      }
    },
    guan: {
      availability: "#377eb8",
      quality: {
        temperature: "#66c2a5",
        wind: "#fc8d62",
        humidity: "#8da0cb"
      }
    },
    gsn: {
      availability: "#377eb8",
      completeness: {
        days_with_pressure: "#7fc97f",
        days_with_temperature: "#beaed4",
        days_with_max_temperature: "#fdc086",
        days_with_min_temperature: "#ffff99",
        days_with_watervapor: "#386cb0",
        days_with_precipitation: "#f0027f",
        days_with_sunshine_duration: "#bf5b17"
      }
    }
  }
};
