import { colors } from "../colors";
import { getDaysInMonth } from "../general";

export const styleGsnAvailability = [
  "case",
  [
    "all",
    ["==", ["get", "availability"], true],
    ["==", ["get", "in_oscar"], true]
  ],
  colors.map.gsn.availability[0],
  [
    "all",
    ["==", ["get", "availability"], true],
    ["==", ["get", "in_oscar"], false]
  ],
  colors.map.gsn.availability[1],
  [
    "all",
    ["==", ["get", "availability"], false],
    ["==", ["get", "in_oscar"], true]
  ],
  colors.map.gsn.availability[2],
  [
    "all",
    ["==", ["get", "availability"], false],
    ["==", ["get", "in_oscar"], false]
  ],
  colors.map.gsn.availability[3],
  /* other - never used here but needed */ colors.map.classNeeded
];

export const getStyleGsnCompleteness = (variable, date) => {
  // Get the number of days in the month
  const nbDays = getDaysInMonth(date);

  // The name of the column in the DB starts with 'days_with_'
  const column_variable = `days_with_${variable}`;
  return [
    "case",
    ["==", ["get", column_variable], nbDays],
    colors.map.gsn.completeness[0],
    [
      "all",
      ["<", ["get", column_variable], nbDays],
      [">=", ["get", column_variable], 15]
    ],
    colors.map.gsn.completeness[1],
    [
      "all",
      ["<", ["get", column_variable], 15],
      [">", ["get", column_variable], 0]
    ],
    colors.map.gsn.completeness[2],
    ["==", ["get", column_variable], 0],
    colors.map.gsn.completeness[3],
    ["==", ["get", column_variable], -1],
    colors.map.gsn.completeness[4],
    /* other - never used here but needed */ colors.map.classNeeded
  ];
};
