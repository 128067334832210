import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal, Button, Spin } from "antd";
import moment from "moment";

import { D3Chart } from "../../utils/chart";
import { URL_OSCAR_SURFACE_SEARCH } from "../../utils/api";
import { prepareDataForChart } from "../../utils/dataAPIformatting/index";
import { getChartTitle } from "../../utils/chartsMetadata";
import { dateNewNCEPSYNOP } from "../../utils/general";
import GSNAvailability from "./GSNAvailability/GSNAvailability";

import "./Chart.css";

const Chart = props => {
  let prefix = "";
  if (props.baseline === "GBON") {
    prefix = "gbon_";
  }

  const ftype = props[prefix + props.fileType];
  let classesSpinner = ["spinner-chart"];
  let classesSpinnerString = classesSpinner.join(" ");
  const [content, setContent] = useState(
    <div id="chart-station">
      <Spin className={classesSpinnerString} tip="Loading..." />
    </div>
  );

  let preparedData;

  useEffect(() => {
    // When the Chart component is visible remove the class "hidden" from classesSpinner
    // so that the spinner is visible
    if (props.visible) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      classesSpinner = ["spinner-chart"];
      // eslint-disable-next-line react-hooks/exhaustive-deps
      classesSpinnerString = classesSpinner.join(" ");
      setContent(
        <div id="chart-station">
          <Spin className={classesSpinnerString} tip="Loading..." />
        </div>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible]);

  useEffect(() => {
    // GSN/Availability is the only case where we don't
    // display time-series (see else if below)
    if (
      ftype.chartData.length > 0 &&
      (props.fileType !== "gsn" || ftype.selectedReport !== "availability")
    ) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      preparedData = prepareDataForChart(props.fileType, ftype);

      // In case of SYNOP/Combined/Quality/Humidity and if
      // props.selectedDate is older than 'dateNewNCEPSYNOP'
      // remove NCEP from the chart
      if (
        ftype.combinedMode &&
        props.fileType === "synop" &&
        ftype.selectedReport === "quality" &&
        ftype.selectedVariable === "58" &&
        moment.utc(props.selectedDate) < moment.utc(dateNewNCEPSYNOP)
      ) {
        delete preparedData.series.ncep;
      }

      // convert the 2 dates to date objects
      // so that we can calculate the number of days
      // between them
      const dateSelected = moment.utc(props.selectedDate);
      const dateChange = moment.utc(dateNewNCEPSYNOP);

      // for SYNOP/Quality/Humidity/Combined if there is less than 30 days
      // between the selected date (selectedDate) and the date
      // when we started using the new NCEP SYNOP files(dateNewNCEPSYNOP)
      // we have to delete the NCEP values older than 'dateNewNCEPSYNOP' as
      // they come from the older version of the NCEP SYNOP files
      // where humidity is not correct
      if (
        ftype.combinedMode &&
        props.fileType === "synop" &&
        ftype.selectedReport === "quality" &&
        ftype.selectedVariable === "58" &&
        dateSelected.diff(dateChange, "days") < 30
      ) {
        if (preparedData.series.hasOwnProperty("ncep")) {
          // we also need to update the min and max of the serie
          let newMax = -Infinity;
          let newMin = Infinity;
          preparedData.series.ncep.data.forEach(v => {
            if (moment.utc(v.x).format("YYYY-MM-DD") < dateNewNCEPSYNOP) {
              v.y = null;
            } else {
              if (v.y && v.y > newMax) {
                newMax = v.y;
              }
              if (v.y && v.y < newMin) {
                newMin = v.y;
              }
            }
          });
          preparedData.series.ncep.max = newMax;
          preparedData.series.ncep.min = newMin;
        }
      }

      // for SYNOP/Quality/Humidity/NCEP if there is less than 30 days
      // between the selected date (selectedDate) and the date
      // when we started using the new NCEP SYNOP files(dateNewNCEPSYNOP)
      // we have to delete the values from the 2 series 'ncep' and 'individual'
      // older than 'dateNewNCEPSYNOP' as they come from the older version
      // of the NCEP SYNOP files where humidity is not correct
      if (
        !ftype.combinedMode &&
        props.fileType === "synop" &&
        ftype.selectedReport === "quality" &&
        ftype.selectedVariable === "58" &&
        ftype.selectedCenter === "NCEP" &&
        dateSelected.diff(dateChange, "days") < 30
      ) {
        if (preparedData.series.hasOwnProperty("ncep")) {
          // we also need to update the min and max of the serie
          let newMax = -Infinity;
          let newMin = Infinity;
          preparedData.series.ncep.data.forEach(v => {
            if (moment.utc(v.x).format("YYYY-MM-DD") < dateNewNCEPSYNOP) {
              v.y = null;
            } else {
              if (v.y && v.y > newMax) {
                newMax = v.y;
              }
              if (v.y && v.y < newMin) {
                newMin = v.y;
              }
            }
          });
          preparedData.series.ncep.max = newMax;
          preparedData.series.ncep.min = newMin;
        }

        if (preparedData.series.hasOwnProperty("individual")) {
          // we also need to update the min and max of the serie
          let newMax = -Infinity;
          let newMin = Infinity;
          preparedData.series.individual.data.forEach(v => {
            if (moment.utc(v.x).format("YYYY-MM-DD") < dateNewNCEPSYNOP) {
              v.y = null;
            } else {
              if (v.y && v.y && v.y > newMax) {
                newMax = v.y;
              }
              if (v.y && v.y && v.y < newMin) {
                newMin = v.y;
              }
            }
          });
          preparedData.series.individual.max = newMax;
          preparedData.series.individual.min = newMin;
        }
      }

      // Change 'nr_expected' serie if baseline is 'GBON'
      // and the station is in OSCAR
      if (
        preparedData.series.nr_expected &&
        ftype.baseline === "GBON" &&
        props.selectedStation.in_oscar
      ) {
        // let expectedGBON;
        // if (props.fileType === "synop") {
        //   if (ftype.selectedPeriodType === "six_hour") {
        //     expectedGBON = 6;
        //   } else if (ftype.selectedPeriodType === "daily") {
        //     expectedGBON = 24;
        //   }
        //   else if (ftype.selectedPeriodType === "monthly") {
        //     expectedGBON = 24;
        //   }
        // } else if (props.fileType === "temp") {
        //   if (ftype.selectedPeriodType === "daily") {
        //     expectedGBON = 2;
        //   } else if (ftype.selectedPeriodType === "monthly") {
        //     expectedGBON = 60;
        //   }
        // }
        // preparedData.series.nr_expected.data.forEach(element => {
        //   if (element.y) {
        //     element.y = expectedGBON;
        //   }
        // });
        // preparedData.series.nr_expected.max = expectedGBON;
        // The above code is not needed for GBON, as we are getting the
        // Number of expected from database.
        preparedData.series.nr_expected.label = "Expected";
      }

      // Before drawing the chart, hide the spinner
      classesSpinner.push("hidden");
      // eslint-disable-next-line react-hooks/exhaustive-deps
      classesSpinnerString = classesSpinner.join(" ");
      setContent(
        <div id="chart-station">
          <Spin className={classesSpinnerString} tip="Loading..." />
        </div>
      );

      // Remove the SVG element from the DOM
      try {
        const SVGElement = document.getElementById("svg-time-series");
        SVGElement.parentNode.removeChild(SVGElement);
      } catch (error) {}

      // Remove the tooltip
      try {
        const tooltipElements = document.getElementsByClassName("tooltip");
        while (tooltipElements[0]) {
          tooltipElements[0].parentNode.removeChild(tooltipElements[0]);
        }
      } catch (error) {}
      // Draw the chart
      // Wait for 10 milliseconds before drawing the chart
      // This is a hack to solve cases in local dev environment
      // where we have a small amount of data and the data for the chart
      // is ready before the modal is open. This only happens once (the first time)
      setTimeout(function() {
        new D3Chart("chart-station", preparedData);
      }, 10);
    } else if (
      ftype.chartData.length > 0 &&
      props.fileType === "gsn" &&
      ftype.selectedReport === "availability"
    ) {
      // GSN/Availability doesn't contain quantitative values
      // so we can't display time-series. We display a
      // div per month (the color is base on the availability value)
      setContent(<GSNAvailability data={ftype.chartData} />);
    }
  }, [ftype.chartData]);

  let chartContainer = null;
  if (props.selectedStation) {
    const wigosid = props.selectedStation.wigosid;
    let addressLink;
    let stationLabel = "WIGOS-ID";
    let stationName = props.selectedStation.name;
    // key attribute necessary to prevent es-lint alert
    let linkOSCAR = <p key={Math.floor(Math.random() * 100)} />;
    let linkDownload = (
      <p key={"linkdownload" + Math.floor(Math.random() * 100)} />
    );
    // check if the timeseries-url attribute is there
    if (ftype.chartData[2]) {
      linkDownload = (
        <a
          // key attribute necessary to prevent es-lint alert
          key={"linkdownload" + Math.floor(Math.random() * 100)}
          className="link-oscar"
          target="_blank"
          rel="noopener noreferrer"
          href={ftype.chartData[2]["timeseries-url"] + "&format=json"}
        >
          Download Timeseries
        </a>
      );
    }
    if (props.selectedStation.in_oscar) {
      addressLink = `${URL_OSCAR_SURFACE_SEARCH}${wigosid}`;
      linkOSCAR = (
        <a
          // key attribute necessary to prevent es-lint alert
          key={Math.floor(Math.random() * 100)}
          className="link-oscar"
          target="_blank"
          rel="noopener noreferrer"
          href={addressLink}
        >
          Open in OSCAR
        </a>
      );
    } else if (props.fileType === "gsn") {
      stationLabel = "Reported-ID";
      stationName = props.selectedStation.name;
    } else {
      stationLabel = "TSI";
      if (props.selectedStation.wigosid.includes("-")) {
        stationLabel = "WSI";
      }
      stationName = "Unkwown";
    }

    let links = (
      <div key={Math.floor(Math.random() * 100)}>
        {linkOSCAR} - {linkDownload}
      </div>
    );
    const title = (
      <p>
        <b>Station: </b>
        {stationName} - <b>{stationLabel}: </b>
        {wigosid}
      </p>
    );

    const closeModal = () => {
      // Remove the SVG element from the DOM
      try {
        const SVGElement = document.getElementById("svg-time-series");
        SVGElement.parentNode.removeChild(SVGElement);
      } catch (error) {}

      props.setVisible(false);
      // Remove the tooltip
      try {
        const tooltipElements = document.getElementsByClassName("tooltip");
        while (tooltipElements[0]) {
          tooltipElements[0].parentNode.removeChild(tooltipElements[0]);
        }
      } catch (error) {}
    };

    const titleChart = getChartTitle(props.fileType, ftype);

    chartContainer = (
      <Modal
        title={title}
        visible={props.visible}
        centered={true}
        onOk={closeModal}
        onCancel={closeModal}
        footer={[
          links,
          <Button key="ok" type="primary" onClick={closeModal}>
            Close
          </Button>
        ]}
      >
        <div className="chart-title">{titleChart}</div>
        <div id="chart-container">{content}</div>
      </Modal>
    );
  }

  return chartContainer;
};

const mapStateToProps = state => {
  return {
    selectedStation: state.selectedStation,
    synop: state.synop,
    temp: state.temp,
    gbon_synop: state.gbon_synop,
    gbon_temp: state.gbon_temp,
    guan: state.guan,
    gsn: state.gsn,
    buoy: state.buoy,
    ship: state.ship,
    marine_surface: state.marine_surface
  };
};

export default connect(mapStateToProps)(Chart);
