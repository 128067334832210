import React, { useState } from "react";

import "./DBOscarInfo.css";
import { fetchData, WDQMS_API } from "../../../utils/api";
import { size } from "lodash";

const DBOscarInfo = props => {
  let oscarStatURL = `${WDQMS_API}/cronjobs/status/?job-name=fetch-gos-stations`;
  var syncTime = "";
  const [sync_time, setSyncTime] = useState("");

  fetchData(oscarStatURL).then(response => {
    if (size(response.data.jobs) > 0) {
      syncTime = response.data.jobs[0].start_time;
    } else {
      syncTime = "N/A";
    }

    setSyncTime(syncTime);
  });

  return (
    <>
      <div className="sync-time">Oscar Last time sync: {sync_time}</div>
    </>
  );
};

export default DBOscarInfo;
