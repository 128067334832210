import { colors } from "../colors";
import { getDaysInMonth } from "../general";

// Availability

// baseline OSCAR
export const LegendSynopAvailability = baseline => {
  let ret = {
    title: "Received observations",
    body: [
      {
        label: "More than 100%",
        color: colors.map.synop.availability[0],
        filter: "cl-synop-avail-more-than-100p"
      },
      {
        label: "Normal (\u2265 80%)",
        color: colors.map.synop.availability[1],
        filter: "cl-synop-avail-normal"
      },
      {
        label: "Availability issues (\u2265 30%)",
        color: colors.map.synop.availability[2],
        filter: "cl-synop-avail-small-issues"
      },
      {
        label: "Availability issues (< 30%)",
        color: colors.map.synop.availability[3],
        filter: "cl-synop-avail-big-issues"
      },
      {
        label: "Not received in period",
        color: colors.map.synop.availability[4],
        filter: "cl-avail-silent"
      },
      {
        label: "OSCAR schedule issue",
        color: colors.map.synop.availability[5],
        filter: "cl-synop-avail-expected-zero",
        asterisk:
          "Not expected to report during the period according to OSCAR/Surface schedule, yet data was received."
      },
      {
        label: "No match in OSCAR/Surface",
        color: colors.map.synop.availability[6],
        filter: "cl-not-oscar",
        asterisk: "There is no corresponding station ID in OSCAR/Surface."
      }
    ]
  };
  // remove (OSCAR schedule issue) and (No match in OSCAR/Surface)
  // from the body if baseline is GBON
  if (baseline === "GBON") {
    ret = {
      title: "Received observations",
      body: [
        {
          label: "More than 100%",
          color: colors.map.synop.availability[0],
          filter: "cl-synop-avail-more-than-100p-hourly"
        },
        {
          label: "Normal (\u2265 80%)",
          color: colors.map.synop.availability[1],
          filter: "cl-synop-avail-normal-hourly"
        },
        {
          label: "Availability issues (\u2265 30%)",
          color: colors.map.synop.availability[2],
          filter: "cl-synop-avail-small-issues-hourly"
        },
        {
          label: "Availability issues (< 30%)",
          color: colors.map.synop.availability[3],
          filter: "cl-synop-avail-big-issues-hourly"
        },
        {
          label: "Not received in period",
          color: colors.map.synop.availability[4],
          filter: "cl-avail-silent"
        }
      ]
    };
  }
  return ret;
};

// baseline GBON
// this is the same as `LegendSynopAvailability` but
// without the grey class "OSCAR schedule issue"
export const LegendSynopAvailabilityGbonSixhour = {
  title: "Received observations",
  body: [
    {
      label: "Complete (\u2265 80%)",
      color: colors.map.synop.availability[1],
      filter: "cl-synop-gbon-avail-normal",
      asterisk: "More than 4 observations"
    },
    {
      label: "Availability issues (\u2265 30%)",
      color: colors.map.synop.availability[2],
      filter: "cl-synop-avail-small-issues",
      asterisk: "More than 1 and less than or equal 4 observations"
    },
    {
      label: "Availability issues (< 30%)",
      color: colors.map.synop.availability[3],
      filter: "cl-synop-avail-big-issues",
      asterisk: "Only 1 observation received"
    },
    {
      label: "Not received in period",
      color: colors.map.synop.availability[4],
      filter: "cl-avail-silent",
      asterisk: "Zero observations"
    }
  ]
};

export const LegendSynopAvailabilityGbonDaily = {
  title: "Received observations",
  body: [
    {
      label: "Complete (\u2265 80%)",
      color: colors.map.synop.availability[1],
      filter: "cl-synop-gbon-avail-normal",
      asterisk: "More than 19 observations"
    },
    {
      label: "Availability issues (\u2265 30%)",
      color: colors.map.synop.availability[2],
      filter: "cl-synop-avail-small-issues",
      asterisk: "More than 7 and less than or equal 19 observations"
    },
    {
      label: "Availability issues (< 30%)",
      color: colors.map.synop.availability[3],
      filter: "cl-synop-avail-big-issues",
      asterisk: "More than 0 and less than or equal 7 observations"
    },
    {
      label: "Not received in period",
      color: colors.map.synop.availability[4],
      filter: "cl-avail-silent",
      asterisk: "Zero observations "
    }
  ]
};

export const LegendSynopAvailabilityMonthly = baseline => {
  let ret = {
    title: "Received observations",
    body: [
      {
        label: "More than 100%",
        color: colors.map.synop.availability[0],
        filter: "cl-synop-avail-more-than-100p"
      },
      {
        label: "Normal (\u2265 80%)",
        color: colors.map.synop.availability[1],
        filter: "cl-synop-avail-normal"
      },
      {
        label: "Availability issues (\u2265 30%)",
        color: colors.map.synop.availability[2],
        filter: "cl-synop-avail-small-issues"
      },
      {
        label: "Availability issues (< 30%)",
        color: colors.map.synop.availability[3],
        filter: "cl-synop-avail-big-issues"
      },
      {
        label: "Not received in period",
        color: colors.map.synop.availability[4],
        filter: "cl-avail-silent"
      },
      {
        label: "OSCAR schedule issue",
        color: colors.map.synop.availability[5],
        filter: "cl-synop-avail-expected-zero",
        asterisk:
          "Not expected to report during the period according to OSCAR/Surface schedule, yet data was received."
      },
      {
        label: "No match in OSCAR/Surface",
        color: colors.map.synop.availability[6],
        filter: "cl-not-oscar",
        asterisk: "There is no corresponding station ID in OSCAR/Surface."
      },
      {
        label: "Less than 10 days",
        color: colors.map.synop.alertStroke,
        stroke: true
      }
    ]
  };
  // remove item index 5 (OSCAR schedule issue) from the body if baseline is GBON
  if (baseline === "GBON") {
    ret = {
      title: "Received observations",
      body: [
        {
          label: "More than 100%",
          color: colors.map.synop.availability[0],
          filter: "cl-synop-avail-more-than-100p-hourly"
        },
        {
          label: "Normal (\u2265 80%)",
          color: colors.map.synop.availability[1],
          filter: "cl-synop-avail-normal-hourly"
        },
        {
          label: "Availability issues (\u2265 30%)",
          color: colors.map.synop.availability[2],
          filter: "cl-synop-avail-small-issues-hourly"
        },
        {
          label: "Availability issues (< 30%)",
          color: colors.map.synop.availability[3],
          filter: "cl-synop-avail-big-issues-hourly"
        },
        {
          label: "Not received in period",
          color: colors.map.synop.availability[4],
          filter: "cl-avail-silent"
        },

        {
          label: "Less than 10 days",
          color: colors.map.synop.alertStroke,
          stroke: true
        }
      ]
    };
  }
  return ret;
};

export const legendSynopGbonMonthly = date => {
  const nbDays = getDaysInMonth(date);
  const expectedGBON = nbDays * 24;
  let expected_80_percent = Math.round((80 * expectedGBON) / 100);
  let expected_30_percent = Math.round((30 * expectedGBON) / 100);

  return {
    title: "Monthly Received observations",
    body: [
      {
        label: "Complete (\u2265 80%)",
        color: colors.map.temp.availability.daily[1],
        filter: "cl-gbon-temp-avail-daily-all",
        asterisk: expected_80_percent + " or more observations"
      },
      {
        label: "Availability issues (\u2265 30%)",
        color: colors.map.temp.availability.daily[2],
        filter: "cl-gbon-temp-avail-small-issues",
        asterisk:
          "more than " +
          expected_30_percent +
          "  observations and less than " +
          expected_80_percent
      },
      {
        label: "Availability issues (< 30%)",
        color: colors.map.temp.availability.daily[3],
        filter: "cl-gbon-temp-avail-big-issues",
        asterisk:
          "more than or equal one observation and less than or equal " +
          expected_30_percent
      },
      {
        label: "Not received in period",
        color: colors.map.temp.availability.daily[4],
        filter: "cl-avail-silent",
        asterisk: "Zero observations"
      },
      {
        label: "Less than 10 days",
        color: colors.map.synop.alertStroke,
        stroke: true
      }
    ]
  };
};
// Quality

// '\n' is used in CSS to create a new line
const commonTitle = "Observation and model differences\nAbsolute values";
export const LegendSynopQualityWind = {
  title: `${commonTitle} (m/s)`,
  body: [
    {
      label: "> 15",
      color: colors.map.synop.quality[5],
      filter: "cl-synop-quality-gt-15"
    },
    {
      label: "5 < x \u2264 15",
      color: colors.map.synop.quality[4],
      filter: "cl-synop-quality-gt-5-le-15"
    },
    {
      label: "3 < x \u2264 5",
      color: colors.map.synop.quality[3],
      filter: "cl-synop-quality-gt-3-le-5"
    },
    {
      label: "2 < x \u2264 3",
      color: colors.map.synop.quality[2],
      filter: "cl-synop-quality-gt-2-le-3"
    },
    {
      label: "0.5 < x \u2264 2",
      color: colors.map.synop.quality[1],
      filter: "cl-synop-quality-gt-0p5-le-2"
    },
    {
      label: "\u2264 0.5",
      color: colors.map.synop.quality[0],
      filter: "cl-synop-quality-le-0p5"
    }
  ]
};

export const LegendSynopQualityHumidity = {
  title: `${commonTitle} (%)`,
  body: [
    {
      label: "> 30",
      color: colors.map.synop.quality[5],
      filter: "cl-synop-quality-gt-0p3"
    },
    {
      label: "15 < x \u2264 30",
      color: colors.map.synop.quality[4],
      filter: "cl-synop-quality-gt-0p15-le-0p3"
    },
    {
      label: "10 < x \u2264 15",
      color: colors.map.synop.quality[3],
      filter: "cl-synop-quality-gt-0p1-le-0p15"
    },
    {
      label: "5 < x \u2264 10",
      color: colors.map.synop.quality[2],
      filter: "cl-synop-quality-gt-0p05-le-0p1"
    },
    {
      label: "2 < x \u2264 5",
      color: colors.map.synop.quality[1],
      filter: "cl-synop-quality-gt-0p02-le-0p05"
    },
    {
      label: "\u2264 2",
      color: colors.map.synop.quality[0],
      filter: "cl-synop-quality-le-0p02"
    }
  ]
};

export const LegendSynopQualitySurfacePressure = {
  title: `${commonTitle} (hPa)`,
  body: [
    {
      label: "> 10",
      color: colors.map.synop.quality[5],
      filter: "cl-synop-quality-gt-10"
    },
    {
      label: "5 < x \u2264 10",
      color: colors.map.synop.quality[4],
      filter: "cl-synop-quality-gt-5-le-10"
    },
    {
      label: "1 < x \u2264 5",
      color: colors.map.synop.quality[3],
      filter: "cl-synop-quality-gt-1-le-5"
    },
    {
      label: "0.5 < x \u2264 1",
      color: colors.map.synop.quality[1],
      filter: "cl-synop-quality-gt-0p5-le-1"
    },
    {
      label: "\u2264 0.5",
      color: colors.map.synop.quality[0],
      filter: "cl-synop-quality-le-0p5"
    }
  ]
};

export const LegendSynopQualityTemperature = {
  title: `${commonTitle} (K)`,
  body: [
    {
      label: "> 10",
      color: colors.map.synop.quality[5],
      filter: "cl-synop-quality-gt-10"
    },
    {
      label: "5 < x \u2264 10",
      color: colors.map.synop.quality[4],
      filter: "cl-synop-quality-gt-5-le-10"
    },
    {
      label: "2 < x \u2264 5",
      color: colors.map.synop.quality[3],
      filter: "cl-synop-quality-gt-2-le-5"
    },
    {
      label: "1 < x \u2264 2",
      color: colors.map.synop.quality[2],
      filter: "cl-synop-quality-gt-1-le-2"
    },
    {
      label: "0.5 < x \u2264 1",
      color: colors.map.synop.quality[1],
      filter: "cl-synop-quality-gt-0p5-le-1"
    },
    {
      label: "\u2264 0.5",
      color: colors.map.synop.quality[0],
      filter: "cl-synop-quality-le-0p5"
    }
  ]
};

export const LegendSynopQualityGeopotential = {
  title: `${commonTitle} (m)`,
  body: [
    {
      label: "> 100",
      color: colors.map.synop.quality[5],
      filter: "cl-synop-quality-gt-100"
    },
    {
      label: "40 < x \u2264 100",
      color: colors.map.synop.quality[4],
      filter: "cl-synop-quality-gt-40-le-100"
    },
    {
      label: "30 < x \u2264 40",
      color: colors.map.synop.quality[3],
      filter: "cl-synop-quality-gt-30-le-40"
    },
    {
      label: "\u2264 30",
      color: colors.map.synop.quality[0],
      filter: "cl-synop-quality-le-30"
    }
  ]
};

// Alert

const commonTitleAlert =
  "Observation and model differences\n5-day moving average\nAbsolute values";

const extraElement = {
  label: "Less than 5 values",
  color: colors.map.synop.alertStroke,
  stroke: true
};

const extraElementMonthly = {
  label: "Less than 10 days",
  color: colors.map.synop.alertStroke,
  stroke: true
};

export const LegendSynopAlertQualityWind = {
  title: `${commonTitleAlert} (m/s)`,
  body: [...LegendSynopQualityWind.body, extraElement]
};

export const LegendSynopAlertQualityHumidity = {
  title: `${commonTitleAlert} (%)`,
  body: [...LegendSynopQualityHumidity.body, extraElement]
};

export const LegendSynopAlertQualitySurfacePressure = {
  title: `${commonTitleAlert} (hPa)`,
  body: [...LegendSynopQualitySurfacePressure.body, extraElement]
};

export const LegendSynopAlertQualityTemperature = {
  title: `${commonTitleAlert} (K)`,
  body: [...LegendSynopQualityTemperature.body, extraElement]
};

export const LegendSynopAlertQualityGeopotential = {
  title: `${commonTitleAlert} (m)`,
  body: [...LegendSynopQualityGeopotential.body, extraElement]
};

const commonTitleMonthly =
  "Observation and model differences\nRoot Mean Square Error";

export const LegendSynopQualityWindMonthly = {
  title: `${commonTitleMonthly} (m/s)`,
  body: [...LegendSynopQualityWind.body, extraElementMonthly]
};

export const LegendSynopQualityHumidityMonthly = {
  title: `${commonTitleMonthly} (%)`,
  body: [...LegendSynopQualityHumidity.body, extraElementMonthly]
};

export const LegendSynopQualitySurfacePressureMonthly = {
  title: `${commonTitleMonthly} (hPa)`,
  body: [...LegendSynopQualitySurfacePressure.body, extraElementMonthly]
};

export const LegendSynopQualityTemperatureMonthly = {
  title: `${commonTitleMonthly} (K)`,
  body: [...LegendSynopQualityTemperature.body, extraElementMonthly]
};

export const LegendSynopQualityGeopotentialMonthly = {
  title: `${commonTitleMonthly} (m)`,
  body: [...LegendSynopQualityGeopotential.body, extraElementMonthly]
};
