import { select, selectAll, pointer } from "d3-selection";
import { min, max, extent, bisector } from "d3-array";
import { line } from "d3-shape";
import { scaleLinear, scaleTime } from "d3-scale";
import { axisBottom, axisLeft } from "d3-axis";
import { format } from "d3-format";
import { timeParse, timeFormat } from "d3-time-format";
import { transition } from "d3-transition";
import { Tooltip } from "./tooltip";

export class D3Chart {
  constructor(parentElement, data) {
    this.parentElement = parentElement;
    this.data = data.series;
    this.rangeX = data.rangeX;
    this.dateFormats = data.dateFormats;
    this.metadataYAxis = data.metadataYAxis;
    this.initVis();
  }

  initVis() {
    // maybe to set as a parameter
    this.margin = { top: 10, right: 20, bottom: 30, left: 50 };

    const containerChart = document.getElementById(this.parentElement);
    const positionInfo = containerChart.getBoundingClientRect();

    this.width = positionInfo.width - this.margin.left - this.margin.right;
    this.height = positionInfo.height - this.margin.top - this.margin.bottom;

    // 1) Set up the SVG and g elements
    this.svg = select(`#${this.parentElement}`)
      .append("svg")
      .attr("id", "svg-time-series")
      .attr("width", this.width + this.margin.left + this.margin.right)
      .attr("height", this.height + this.margin.top + this.margin.bottom);

    this.g = this.svg
      .append("g")
      .attr(
        "transform",
        "translate(" + this.margin.left + "," + this.margin.top + ")"
      );

    this.t = () => {
      return transition().duration(1000);
    };

    // 2) Calculate the axis
    const formatDate = "%Y-%m-%dT%H:%M:%SZ";
    this.parserTime = timeParse(formatDate);
    this.x = scaleTime()
      .range([0, this.width])
      .domain(extent(this.rangeX, d => this.parserTime(d)));

    // calculate the data min and max to know the domain for the Y axis
    const maxs = [];
    const mins = [];
    for (const key in this.data) {
      maxs.push(this.data[key].max);
      mins.push(this.data[key].min);
    }
    this.maxY = max(maxs);

    // if this.metadataYAxis.min is defined use it, else calculate the min of all series
    this.minY =
      this.metadataYAxis.min !== undefined && this.metadataYAxis.min !== null
        ? this.metadataYAxis.min
        : min(mins);

    this.y = scaleLinear()
      .domain([this.minY, this.maxY])
      .nice()
      .range([this.height, 0]);

    const yTicks = min([10, Math.round(this.maxY - this.minY)]);

    this.yAxisCall = axisLeft(this.y)
      .ticks(yTicks)
      .tickFormat(format(this.metadataYAxis.format));

    this.yAxis = this.g
      .append("g")
      .attr("transform", "translate(0, 0)")
      .call(this.yAxisCall);

    // Y-Axis label
    this.g
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", -50)
      .attr("x", -(this.height / 2))
      .attr("dy", ".8em")
      .style("text-anchor", "middle")
      .attr("fill", "#5D6971")
      .text(this.metadataYAxis.label);

    // If minY < 0 display the line y=0
    if (this.metadataYAxis.y0 && this.minY < 0) {
      this.g
        .append("line")
        .classed("y-zero", true)
        .attr("x1", this.x(this.parserTime(this.rangeX[0])))
        .attr("y1", this.y(0))
        .attr(
          "x2",
          this.x(this.parserTime(this.rangeX[this.rangeX.length - 1]))
        )
        .attr("y2", this.y(0))
        .attr("stroke", "rgb(31, 51, 73, 0.5)")
        .attr("stroke-width", 0.5)
        .attr("stroke-dasharray", "4, 2");
    }

    this.xAxisCall = axisBottom(this.x)
      .ticks(5)
      .tickFormat(timeFormat(this.dateFormats.xAxis));

    this.xAxis = this.g
      .append("g")
      .attr("transform", "translate(0," + this.height + ")")
      .call(this.xAxisCall);

    this.wrangleData();
  }

  wrangleData() {
    for (const serie in this.data) {
      // for series that are represented as `lines`
      // we check if there are some isolated values that have to be representend as points
      if (
        this.data[serie].representAs === "lines" ||
        this.data[serie].representAs === "dashlines"
      ) {
        this.data[serie].isolated = identifyIsolatedValues(this.data[serie]);
      }
      // For all series add a new property `rangeX` that contains
      // all the steps of this serie
      // this will be used when hovering over the data to check if
      // the highlighted step is in the array `rangeX`
      this.data[serie].rangeX = this.data[serie].data.map(d => d.x);
    }
    this.updateVis();
  }

  updateVis() {
    const vis = this;

    // draw the lines
    // eslint-disable-next-line guard-for-in
    for (const serie in this.data) {
      if (
        this.data[serie].representAs === "lines" ||
        this.data[serie].representAs === "dashlines"
      ) {
        let lineGenerator = line()
          .defined(function(d) {
            return d.y !== null;
          })
          .x(function(d) {
            return vis.x(vis.parserTime(d.x));
          })
          .y(function(d) {
            return vis.y(d.y);
          });

        const lineType = this.data[serie].representAs;
        this.g
          .append("path")
          .attr("class", "line")
          .attr("fill", "none")
          .attr("stroke", this.data[serie].color)
          .attr("stroke-width", 1.5)
          .attr("stroke-dasharray", function(d) {
            return lineType === "dashlines" ? "2, 2" : null;
          })
          .attr("d", lineGenerator(this.data[serie].data));

        if (this.data[serie].isolated && this.data[serie].isolated.length > 0) {
          // draw the isolated values as points
          this.g
            .selectAll(`circle.isolated`)
            .data(this.data[serie].isolated)
            .enter()
            .append("circle")
            .attr("cx", function(d) {
              return vis.x(vis.parserTime(d.x));
            })
            .attr("cy", function(d) {
              return vis.y(d.y);
            })
            .attr("r", 2)
            .attr("fill", this.data[serie].color);
        }
      }

      // draw the points
      if (this.data[serie].representAs === "points") {
        this.g
          .selectAll(`circle.isolated`)
          // only display when `y` is not null
          .data(this.data[serie].data.filter(d => d.y !== null))
          .enter()
          .append("circle")
          .attr("cx", function(d) {
            return vis.x(vis.parserTime(d.x));
          })
          .attr("cy", function(d) {
            return vis.y(d.y);
          })
          .attr("r", 2)
          .attr("fill", function(d) {
            if (typeof vis.data[serie].color === "function") {
              return vis.data[serie].color(d);
            }
            return vis.data[serie].color;
          });
      }
    }

    this.addInteractivity();
  }

  addInteractivity() {
    const vis = this;
    const bisectDate = bisector(function(d) {
      return vis.parserTime(d);
    }).left;

    // Display tooltip
    vis.tooltip = new Tooltip();

    vis.g
      .append("rect")
      .attr("class", "overlay")
      .attr("fill", "none")
      .style("pointer-events", "all")
      .attr("width", vis.width)
      .attr("height", vis.height)
      .on("mousemove", (event, d) => {
        mousemove(event);
      });

    function mousemove(event) {
      // Remove content from the previous step
      selectAll(".highlighted").remove();

      // calculate the new values
      const xMouse = vis.x.invert(pointer(event)[0]);
      const nearestIndex = bisectDate(vis.rangeX, xMouse);

      const displayTime = vis.rangeX[nearestIndex];
      if (displayTime) {
        // Show vertical line
        vis.g
          .append("line")
          .classed("highlighted", true)
          .attr("x1", vis.x(vis.parserTime(displayTime)))
          .attr("y1", vis.y(vis.y.domain()[1]))
          .attr("x2", vis.x(vis.parserTime(displayTime)))
          .attr("y2", vis.y(vis.y.domain()[0]))
          .attr("stroke", "rgb(31, 51, 73, 0.5)")
          .attr("stroke-width", 1);

        const dataForTooltip = {};
        // show the highlighted points
        for (const serie in vis.data) {
          const indexDate = vis.data[serie].rangeX.indexOf(displayTime);
          if (indexDate !== -1 && vis.data[serie].data[indexDate].y !== null) {
            dataForTooltip[serie] = {
              label: vis.data[serie].label,
              color: vis.data[serie].color,
              data: vis.data[serie].data[indexDate],
              tooltip: vis.data[serie].tooltip
            };
            vis.g
              .append("circle")
              .classed("highlighted", true)
              .attr("cx", vis.x(vis.parserTime(displayTime)))
              .attr("cy", vis.y(vis.data[serie].data[indexDate].y))
              .attr("r", 3)
              .attr("stroke", "white")
              .attr("stroke-width", 2)
              .attr("fill", function() {
                if (typeof vis.data[serie].color === "function") {
                  return vis.data[serie].color(
                    vis.data[serie].data[indexDate],
                    "status"
                  );
                }
                return vis.data[serie].color;
              });
          }
        }
        // Show the tooltip
        vis.tooltip.show(
          event,
          displayTime,
          dataForTooltip,
          vis.dateFormats.tooltip
        );
      }
    }
  }
}

// `identifyIsolatedValues` identifies the isolated
// values (meaning a value that is between two nulls).
// It will be displayed as points on the chart
export const identifyIsolatedValues = serie => {
  const isolated = [];
  if (serie.data.length === 1) {
    return serie.data;
  }
  // if the first element is not null and the second
  // element is null, then the first element is isolated
  const dFirst = serie.data[0];
  if (dFirst && dFirst.y !== null) {
    const next = serie.data[1];
    if (next.y === null) {
      isolated.push(dFirst);
    }
  }
  // Check from the second to the penultimate elements
  for (let i = 1; i < serie.data.length - 1; i++) {
    const d = serie.data[i];
    if (d && d.y !== null) {
      const prev = serie.data[i - 1];
      const next = serie.data[i + 1];
      if (prev.y === null && next.y === null) {
        isolated.push(d);
      }
    }
  }
  // if the last element is not null and the penultimate
  // element is null, then the last element is isolated
  const dLast = serie.data[serie.data.length - 1];
  if (dLast && dLast.y !== null) {
    const prev = serie.data[serie.data.length - 2];
    if (prev.y === null) {
      isolated.push(dLast);
    }
  }
  return isolated;
};
