import ReactGA from "react-ga4";
export const initGA = () => {
    if (window.location.hostname !== "localhost") {
        if (window.location.hostname.includes("dev")) {
          ReactGA.initialize("G-LKBVCDSCHH");
        } else if (window.location.hostname.includes("test")) {
          ReactGA.initialize("G-69VGNEHTEW");
        } else {
          // PROD
          ReactGA.initialize("G-VF9MQE5EKY");
        }
      }
};
export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
};