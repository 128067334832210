import { select } from "d3-selection";
import { timeFormat, timeParse } from "d3-time-format";

const formatDate = "%Y-%m-%dT%H:%M:%SZ";
const parserTime = timeParse(formatDate);

const keyMapper = {
  y: "Value",
  status: "Status",
  obs_type: "Type",
  nb_obs: "#Daily values",
  details: "Details"
};

const statusFlagMapper = {
  0: "Used",
  1: "Not used",
  2: "Rejected by DA",
  3: "Never used by DA",
  4: "Data thinned",
  5: "Rejected before DA",
  6: "Alternative used",
  7: "Quality issue",
  8: "Other reason",
  9: "No content"
};

const getColor = serie => {
  if (typeof serie.color === "function") {
    return serie.color(serie.data, "status");
  }
  return serie.color;
};

export class Tooltip {
  constructor() {
    this.init();
  }

  init() {
    this.body = select("body")
      .append("div")
      .attr("class", "tooltip");
  }

  createSimpleItem(serie) {
    let tooltipItem;
    const value = Math.round(serie.data.y * 100) / 100;

    if (serie.tooltip === "point") {
      tooltipItem = `<div class="tooltip-item"><span class="tooltip-item-icon" style="background-color: ${serie.color}"></span><div class="tooltip-item-label"><div>${serie.label}:</div><div>${value}</div></div></div>`;
    }
    if (serie.tooltip === "line") {
      tooltipItem = `<div class="tooltip-item">
    <div class="tooltip-item-line">
     <svg width="20" height="20" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <line stroke-width="1.5" stroke="${serie.color}" x1="0" y1="12" x2="25" y2="12"></line>
     </svg></div>
    <div class="tooltip-item-label"><div>${serie.label}:</div><div>${value}</div></div></div>`;
    }
    if (serie.tooltip === "dashline") {
      tooltipItem = `<div class="tooltip-item">
    <div class="tooltip-item-line">
     <svg width="20" height="20" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <line stroke-dasharray="2, 2" stroke-width="1.5" stroke="${serie.color}" x1="0" y1="12" x2="25" y2="12"></line>
     </svg></div>
    <div class="tooltip-item-label"><div>${serie.label}:</div><div>${value}</div></div></div>`;
    }
    return tooltipItem;
  }

  createComplexItem(serie) {
    let items = "";
    for (const key in serie.data) {
      let valueToDisplay = serie.data[key];
      if (key !== "x") {
        if (key === "y") {
          valueToDisplay = Math.round(serie.data[key] * 100) / 100;
        }
        if (key === "status") {
          valueToDisplay = statusFlagMapper[serie.data[key]];
        }
        if (key === "obs_type") {
          valueToDisplay = serie.data[key] === null ? "n/a" : serie.data[key];
        }
        items += `<div class="tooltip-item-complex-elem"><div class="tooltip-item-complex-elem-part">${keyMapper[key]}:</div><div class="tooltip-item-complex-elem-part">${valueToDisplay}</div></div>`;
      }
    }

    return `<div class="tooltip-item-complex">
    <div class="tooltip-item-complex-title" style="background-color: ${getColor(
      serie
    )}">${serie.label}
    </div>
      ${items}
    </div>`;
  }

  show(event, title, body, dateFormat) {
    let bodyHTML = "";
    if (Object.keys(body).length > 0) {
      bodyHTML = '<div class="tooltip-body">';
      for (const key in body) {
        const serie = body[key];

        if (Object.keys(serie.data).length === 2) {
          bodyHTML += this.createSimpleItem(serie);
        } else {
          bodyHTML += this.createComplexItem(serie);
        }
      }
      bodyHTML += "</div>";
    }

    const formaterString = timeFormat(dateFormat);

    this.body
      .html(
        `<div class="tooltip-title">${formaterString(
          parserTime(title)
        )}</div>${bodyHTML}`
      )
      .style("opacity", 0.9)
      .style("position", "absolute")
      .style("top", event.pageY - 20 + "px")
      .style("left", event.pageX - 200 + "px");
  }
}
