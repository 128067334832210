import React, { useEffect } from "react";
import { initGA, logPageView } from "../../Analytics";
import { Card } from "antd";
import modules from "../../config/modules";

import "./Home.css";

const { Meta } = Card;

const Home = props => {
  // Google analytics
  useEffect(() => {
    if (window.location.hostname !== "localhost") {
      initGA();
      logPageView();
    }
  }, []);
  return (
    <div className="homepage">
      <div className="homepage-part-1">
        <>
          <h2>
            Welcome to the WIGOS Data Quality Monitoring System (WDQMS) webtool
          </h2>
          <p>
            The WDQMS webtool is a resource developed by WMO to monitor the
            performance of all WIGOS observing components.
          </p>
          <p>
            For more information about the webtool, click{" "}
            <a href="/about" target="_blank">
              here
            </a>
            .
          </p>
          <h3>
            Three modules are currently available for the WIGOS monitoring:
          </h3>
        </>
      </div>
      <div className="homepage-part-2">
        <div className="module-container">
          {Object.entries(modules).map(module => {
            return (
              // module[0] is the key
              // module[1] is the property (which is also an object)
              <div key={module[0]} className={"data-module " + module[0]}>
                <div className="data-module-title">
                  {module[1].title}{" "}
                  {module[1].feature ? (
                    <span className="data-model-newfeature">
                      {module[1].feature}
                    </span>
                  ) : (
                    <span />
                  )}
                </div>
                <div className="data-module-body">
                  {Object.entries(module[1].networks).map(network => {
                    // network[0] is the key
                    // network[1] is the property (which is also an object)

                    // If the property 'hasImage' is true, display the
                    // image (defined in property 'imagePath' as a background
                    // image. Otherwise, display a 'div' that contains
                    // the text defined in the property 'textNoImage' )
                    const cover = network[1].hasImage ? (
                      <img alt={network[0]} src={network[1].imagePath} />
                    ) : (
                      <div>{network[1].textNoImage}</div>
                    );

                    return (
                      <a
                        key={network[0]}
                        href={`/${module[0]}/${network[0]}`}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <Card hoverable cover={cover}>
                          <Meta
                            title={network[1].title}
                            description={network[1].description}
                          />
                        </Card>
                      </a>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Home;
