import React from "react";

import { Tooltip } from "antd";

import "./DBBody.css";

const tootipText = {
  available: "Click to see this period on the map",
  missing: "This period is missing"
};

const DBBody = props => {
  let body;
  if (
    props.module === "nwp" ||
    props.module === "gbon" ||
    props.module === "marine"
  ) {
    let baseLink = `${window.location.origin}/${props.module}/${props.fileType}/six_hour/availability`;
    if (props.module === "marine") {
      baseLink = `${window.location.origin}/nwp/marine_surface/six_hour/quality/pressure`;
    }
    body = Object.keys(props.template).map(d => {
      return (
        <div className="dbs-row" key={d}>
          <div className="dbs-row-item">{d}</div>
          {Object.keys(props.template[d]).map((c, i) => {
            const borderRight =
              i < Object.keys(props.template[d]).length - 1
                ? "border-right"
                : "";
            return (
              <div className={`dbs-row-item ${borderRight}`} key={c}>
                {Object.keys(props.template[d][c])
                  .sort()
                  .map(p => {
                    let tooltip;
                    let content;
                    // if the period is missing
                    if (!props.template[d][c][p]) {
                      tooltip = tootipText.missing;
                      const classPeriod = "period-item period-item-missing";
                      content = <div className={classPeriod}></div>;
                    } else {
                      // if the period is available
                      tooltip = tootipText.available;
                      const classPeriod = "period-item period-item-available";
                      let link;
                      if (props.fileType === "synop") {
                        link = `${baseLink}/pressure/${c}/${d}/${p}`;
                      } else {
                        link = `${baseLink}/${c}/${d}/${p}`;
                      }
                      content = (
                        <a
                          href={link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className={classPeriod}></div>
                        </a>
                      );
                    }

                    return (
                      <div className="period-element" key={p}>
                        <Tooltip placement="top" title={tooltip}>
                          {content}
                        </Tooltip>
                      </div>
                    );
                  })}
              </div>
            );
          })}
        </div>
      );
    });
  } else {
    // GCOS
    body = Object.keys(props.template).map(d => {
      let tooltip;
      let content;

      // Is the period is available
      if (props.template[d]) {
        tooltip = tootipText.available;
        let link;
        if (props.fileType === "gsn") {
          link = `${window.location.origin}/gcos/land_surface/availability/${d}`;
        } else {
          link = `${window.location.origin}/gcos/land_upper-air/availability/${d}`;
        }

        content = (
          <a href={link} target="_blank" rel="noopener noreferrer">
            <div className="period-item period-item-available"></div>
          </a>
        );
      } else {
        tooltip = tootipText.missing;
        content = <div className="period-item period-item-missing"></div>;
      }

      return (
        <div className="dbs-row" key={d}>
          <div className="dbs-row-item">{d}</div>
          <div className="dbs-row-item">
            <div className="period-element">
              <Tooltip placement="top" title={tooltip}>
                {content}
              </Tooltip>
            </div>
          </div>
          {/* We add an empty div so that we have 2 columns which are
          aligned with the 3 columns of the footer */}
          <div className="dbs-row-item"></div>
        </div>
      );
    });
  }

  return body;
};

export default DBBody;
