import React from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import { connect } from "react-redux";
import * as actions from "../../store/actions/actions";

import "./Menu.css";

const { SubMenu } = Menu;

const redirectTo = (prefix, fileType) => {
  if (window.location.pathname.startsWith(`/${prefix}/${fileType}`)) {
    return window.location.pathname;
  }
  return `/${prefix}/${fileType}`;
};

const AppMenu = props => {
  const handleMenuClick = e => {
    props.onChangeStatus({
      key: "selectedCountries",
      value: ""
    });
  };
  return (
    <Menu mode="horizontal" className="menu-top">
      <Menu.Item key="home">
        <Link to="/">Home</Link>
      </Menu.Item>

      <SubMenu
        key="sm-1"
        title={<span className="submenu-title-wrapper">Monitoring</span>}
      >
        <Menu.ItemGroup title="GBON">
          <Menu.Item key="map-1">
            <Link
              to={() => redirectTo("gbon", "land_surface")}
              onClick={handleMenuClick}
            >
              Surface
            </Link>
          </Menu.Item>
          <Menu.Item key="map-2">
            <Link
              to={() => redirectTo("gbon", "land_upper-air")}
              onClick={handleMenuClick}
            >
              Upper-air
            </Link>
          </Menu.Item>
        </Menu.ItemGroup>
        <Menu.ItemGroup title="Near-real-time NWP">
          <Menu.Item key="map-3">
            <Link
              to={() => redirectTo("nwp", "land_surface")}
              onClick={handleMenuClick}
            >
              Land Surface
            </Link>
          </Menu.Item>
          <Menu.Item key="map-4">
            <Link
              to={() => redirectTo("nwp", "land_upper-air")}
              onClick={handleMenuClick}
            >
              Land Upper-air
            </Link>
          </Menu.Item>
          <Menu.Item key="map-5">
            <Link
              to={() => redirectTo("nwp", "marine_surface")}
              onClick={handleMenuClick}
            >
              Marine Surface
            </Link>
          </Menu.Item>
        </Menu.ItemGroup>
        <Menu.ItemGroup title="GCOS">
          <Menu.Item key="map-6">
            <Link
              to={() => redirectTo("gcos", "land_surface")}
              onClick={handleMenuClick}
            >
              Surface
            </Link>
          </Menu.Item>
          <Menu.Item key="map-7">
            <Link
              to={() => redirectTo("gcos", "land_upper-air")}
              onClick={handleMenuClick}
            >
              Upper-air
            </Link>
          </Menu.Item>
        </Menu.ItemGroup>
      </SubMenu>

      <SubMenu key="sm-2" title={<span>Support</span>} id="menu-support">
        <Menu.Item key="support-1" className="submenu-support">
          <a
            href="https://etrp.wmo.int/mod/forum/view.php?id=10062"
            target="_blank"
            rel="noopener noreferrer"
          >
            Support
          </a>
        </Menu.Item>

        <Menu.Item key="support-2" className="submenu-support">
          <a
            href="https://community.wmo.int/news/operational-launch-wdqms-webtool"
            target="_blank"
            rel="noopener noreferrer"
          >
            Info
          </a>
        </Menu.Item>

        <Menu.Item key="support-3" className="submenu-support">
          <a
            href="https://confluence.ecmwf.int/display/WIGOSWT"
            target="_blank"
            rel="noopener noreferrer"
          >
            User guide
          </a>
        </Menu.Item>
      </SubMenu>
      <Menu.Item key="about" id="menu-about">
        <Link to="/about">About</Link>
      </Menu.Item>
      <SubMenu
        key="sm-3"
        title={<span>Database status</span>}
        id="menu-dbstatus"
      >
        <Menu.Item key="dbs-1">
          <Link to="/database-status/gbon">GBON</Link>
        </Menu.Item>

        <Menu.ItemGroup title="Near-real-time NWP">
          <Menu.Item key="dbs-2">
            <Link to="/database-status/nwp">Land</Link>
          </Menu.Item>
          <Menu.Item key="dbs-4">
            <Link to="/database-status/marine">Marine</Link>
          </Menu.Item>
        </Menu.ItemGroup>

        <Menu.Item key="dbs-3">
          <Link to="/database-status/gcos">GCOS</Link>
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
};

const mapStateToProps = state => {
  return {
    map: state.map,
    synop: state.synop,
    temp: state.temp,
    gbon_synop: state.gbon_synop,
    gbon_temp: state.gbon_temp,
    guan: state.guan,
    gsn: state.gsn,
    buoy: state.buoy,
    ship: state.ship,
    searchedStations: state.searchedStations
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChangeStatus: data => {
      dispatch(
        actions.setProperty({
          type: "searchedStations",
          key: data.key,
          value: data.value
        })
      );
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AppMenu);
