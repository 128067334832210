import React from "react";

import {
  fetchData,
  URL_GUAN_OBSERVATIONS,
  URL_GSN_OBSERVATIONS
} from "../../../utils/api";

const GCOSLinkTimeSeries = props => {
  const URL_OBS =
    props.fileType === "guan" ? URL_GUAN_OBSERVATIONS : URL_GSN_OBSERVATIONS;

  const fetchStationData = e => {
    e.preventDefault();
    // open the chart modal
    props.setVisible(true);
    const station = e.target.getAttribute("data-station");
    let dateUntil = e.target.getAttribute("data-date");

    fetchData(`${URL_OBS}/?station=${station}&date=${dateUntil}&nb_months=11`)
      .then(response => {
        props.onSetChartData(response.data);
      })
      .catch(error => {
        console.log("GUAN: ", error);
      });
  };
  return (
    <a
      id="link-chart"
      onClick={fetchStationData}
      href="#"
      data-station={props.data.station}
      data-date={props.data.date}
    >
      Time series
    </a>
  );
};

export default GCOSLinkTimeSeries;
