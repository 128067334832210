import React from "react";
import { reports } from "../../../../utils/general";

const MenuReports = props => {
  const reportsArray = Object.keys(reports[props.fileType]);

  const reportsOptions = reportsArray.map(r => (
    <option key={r}>{r.charAt(0).toUpperCase() + r.slice(1)}</option>
  ));

  return (
    <div className="menu-element">
      <label htmlFor="menu-reports">Monitoring category</label>
      <select
        className="select-css"
        id="menu-reports"
        onChange={props.onChangeReport}
        value={props.selectedReport}
      >
        {reportsOptions}
      </select>
    </div>
  );
};

export default MenuReports;
