import * as actionTypes from "./actions/actionTypes";
import { reports, periodDataTemplate } from "../utils/general";

const initialState = {
  synop: {
    dates: null,
    periodData: periodDataTemplate,
    selectedPeriodType: null,
    periodTypes: [],
    selectedCenter: null,
    selectedReport: Object.keys(reports.synop)[0],
    selectedVariable: reports.synop[Object.keys(reports.synop)[0]][0].code,
    selectedDate: null,
    currentSixHPeriods: null,
    selectedSixHPeriod: null,
    readyToFillMenu: false,
    chartData: [],
    // The first map displayed when the app loads is a combined one.
    combinedMode: true,
    // "OSCAR" or "GBON"
    baseline: "OSCAR",
    dataFetched: false,
    allDataFetched: false
  },
  gbon_synop: {
    dates: null,
    periodData: periodDataTemplate,
    selectedPeriodType: null,
    periodTypes: [],
    selectedCenter: null,
    selectedReport: Object.keys(reports.synop)[0],
    selectedVariable: reports.synop[Object.keys(reports.synop)[0]][0].code,
    selectedDate: null,
    currentSixHPeriods: null,
    selectedSixHPeriod: null,
    readyToFillMenu: false,
    chartData: [],
    // The first map displayed when the app loads is a combined one.
    combinedMode: true,
    baseline: "GBON",
    dataFetched: false
  },
  gbon_temp: {
    dates: null,
    periodData: periodDataTemplate,
    selectedPeriodType: null,
    periodTypes: [],
    selectedCenter: null,
    selectedReport: Object.keys(reports.temp)[0],
    selectedVariable: reports.temp.quality[0].code,
    selectedDate: null,
    currentSixHPeriods: null,
    selectedSixHPeriod: null,
    readyToFillMenu: false,
    chartData: [],
    // The first map displayed when the app loads is a combined one.
    combinedMode: true,
    baseline: "GBON",
    // This is only for TEMP - can be "Stratosphere" or "Troposhere"
    level: null,
    dataFetched: false
  },
  temp: {
    dates: null,
    periodData: periodDataTemplate,
    selectedPeriodType: null,
    periodTypes: [],
    selectedCenter: null,
    selectedReport: Object.keys(reports.temp)[0],
    selectedVariable: reports.temp.quality[0].code,
    selectedDate: null,
    currentSixHPeriods: null,
    selectedSixHPeriod: null,
    readyToFillMenu: false,
    chartData: [],
    // The first map displayed when the app loads is a combined one.
    combinedMode: true,
    // "OSCAR" or "GBON",
    baseline: "OSCAR",
    // This is only for TEMP - can be "Stratosphere" or "Troposhere"
    level: null,
    dataFetched: false
  },
  guan: {
    dates: null,
    errorFetchingApi: false,
    noData: false,
    selectedPeriodType: "monthly",
    selectedReport: Object.keys(reports.guan)[0],
    selectedVariable: reports.guan.quality.variables[0].code,
    selectedDate: null,
    readyToFillMenu: false,
    chartData: [],
    dataFetched: false
  },
  gsn: {
    dates: null,
    errorFetchingApi: false,
    noData: false,
    selectedPeriodType: "monthly",
    selectedReport: Object.keys(reports.gsn)[0],
    selectedVariable: reports.gsn.completeness.variables[0].code,
    selectedDate: null,
    readyToFillMenu: false,
    chartData: [],
    dataFetched: false
  },
  buoy: {
    dates: null,
    periodData: periodDataTemplate,
    selectedPeriodType: null,
    periodTypes: [],
    selectedCenter: null,
    selectedReport: Object.keys(reports.buoy)[0],
    selectedVariable: reports.buoy[Object.keys(reports.buoy)[0]][0].code,
    selectedDate: null,
    currentSixHPeriods: null,
    selectedSixHPeriod: null,
    readyToFillMenu: false,
    chartData: [],
    combinedMode: true,
    baseline: "OSCAR",
    dataFetched: false
  },
  ship: {
    dates: null,
    periodData: periodDataTemplate,
    selectedPeriodType: null,
    periodTypes: [],
    selectedCenter: null,
    selectedReport: Object.keys(reports.ship)[0],
    selectedVariable: reports.ship[Object.keys(reports.ship)[0]][0].code,
    selectedDate: null,
    currentSixHPeriods: null,
    selectedSixHPeriod: null,
    readyToFillMenu: false,
    chartData: [],
    combinedMode: true,
    baseline: "OSCAR",
    dataFetched: false
  },
  marine_surface: {
    dates: null,
    periodData: periodDataTemplate,
    selectedPeriodType: null,
    periodTypes: [],
    selectedCenter: null,
    selectedReport: Object.keys(reports.marine_surface)[0],
    selectedVariable: reports.marine_surface[Object.keys(reports.marine_surface)[0]][0].code,
    selectedDate: null,
    currentSixHPeriods: null,
    selectedSixHPeriod: null,
    readyToFillMenu: false,
    chartData: [],
    combinedMode: true,
    baseline: "OSCAR",
    dataFetched: false
  },
  map: null,
  selectedStation: null,
  activeLayerName: null,
  error404: false,
  searchedStations: {
    matches: [],
    result: "",
    classContainer: "inside-filter-closed",
    classCCtrlL1: "ctrl-search",
    selectedCountries: ""
  }
};

const setPeriodDataKey = (state, action) => {
  return {
    ...state,
    [action.payload.type]: {
      ...state[action.payload.type],
      [action.payload.subObj]: {
        ...state[action.payload.type][action.payload.subObj],
        [action.payload.period]: {
          ...state[action.payload.type][action.payload.subObj][
            action.payload.period
          ],
          [action.payload.key]: action.payload.value
        }
      }
    }
  };
};

const setProperty = (state, action) => {
  return {
    ...state,
    [action.payload.type]: {
      ...state[action.payload.type],
      [action.payload.key]: action.payload.value
    }
  };
};

const updatePeriodTypes = (state, action) => {
  state[action.payload.type].periodTypes = state[
    action.payload.type
  ].periodTypes.concat(action.payload.value);
  return state;
};

const initializeMap = (state, action) => {
  state.map = action.payload;
  return state;
};

const setSelectedStation = (state, action) => {
  state.selectedStation = action.payload;
  return state;
};

const setActiveLayerName = (state, action) => {
  state.activeLayerName = action.payload;
  return state;
};

const setError404 = state => {
  state.error404 = true;
  return state;
};

const setLevel = (state, action) => {
  state.temp.level = action.payload;
  return state;
};

const reducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case actionTypes.SET_PERIOD_DATA_KEY:
      return setPeriodDataKey(newState, action);
    case actionTypes.SET_PROPERTY:
      return setProperty(newState, action);
    case actionTypes.INITIALIZE_MAP:
      return initializeMap(newState, action);
    case actionTypes.SET_SELECTED_STATION:
      return setSelectedStation(newState, action);
    case actionTypes.UPDATE_PERIOD_TYPES:
      return updatePeriodTypes(newState, action);
    case actionTypes.SET_ERROR_404:
      return setError404(newState);
    case actionTypes.SET_ACTIVELAYERNAME:
      return setActiveLayerName(newState, action);
    case actionTypes.SET_LEVEL:
      return setLevel(newState, action);
    default:
      return newState;
  }
};

export default reducer;
