import { min, max, mean, groups } from "d3-array";
import { getChartMetadata } from "../chartsMetadata";
import { get6hPeriod } from "../../utils/general";
import {
  rangeBetweenDates,
  rangeMonthsBetweenDates,
  formatPeriodSeries,
  updateTimeSteps,
  getColorByKey
} from "./index";

// This function returns a object with the following 3 properties:
//
// - series: an object that contains the series. The key is the
//   name of the serie. The value is an obect containing the 7 following propeties:
//     - data: An array of objects. Each object contains 2 mandatory properties (can contain others):
//       - x: the date
//       - y: the value
//
//     - max: The naximum value of the serie
//     - min: The minimum value of the serie
//     - label: The name of the serie to display
//     - color: The color of the serie to display
//     - representAs: how to represent the serie: 'points', 'lines' or 'dashlines'
//     - tooltip: how the serie should be represented in the tooltip: 'point', 'line' or 'dashline'
//
// - metadataYAxis: An object containing metadata about the Y axis
//   with the following 4 properties:
//     - min: Can be null or equal to '0'. If '0' the minimum value of the Y axis will be '0'.
//              If null, will be replaced later by the minimum value of all series
//     - label: The label of the Y axis to display
//     - format: D3 format for the values of the Y axis, integer or float. For example: "d" or ".1f"
//     - y0: boolean. If true, display a line y=0 on the chart
//
// - rangeX:  An array of all the dates between the oldest and the newest dates without gaps
//            If displaying six-hour periods, the interval between all dates is 6 hours
//            If displaying daily periods, the interval between all dates is 1 day
//            If displaying monthly periods, the interval between all dates is 1 month
//            This is used to build the X axis of the chart
export const prepareNwpDataForChart = (fileType, props) => {
  // For variable "humidity"
  // (SYNOP & varid = 58 or TEMP & varid = 29)
  // we need to multiply the value by 100 to have it as a %
  // this is for Quality reports
  if (props.selectedVariable === "58" || props.selectedVariable === "29") {
    props.chartData.forEach(function(arr) {
      if (Array.isArray(arr)) {
        // this check has been  made as we added data-y as an extra param
        arr.forEach(function(element) {
          if (element.bg_dep) {
            element.bg_dep =
              element.bg_dep === null ? null : element.bg_dep * 100;
          }
          if (element.avg_bg_dep) {
            element.avg_bg_dep =
              element.avg_bg_dep === null ? null : element.avg_bg_dep * 100;
          }
          if (element.std_bg_dep) {
            element.std_bg_dep =
              element.std_bg_dep === null ? null : element.std_bg_dep * 100;
          }
          if (element.avg_std_bg_dep) {
            element.avg_std_bg_dep =
              element.avg_std_bg_dep === null
                ? null
                : element.avg_std_bg_dep * 100;
          }
          if (element.rms) {
            element.rms = element.rms === null ? null : element.rms * 100;
          }
        });
      }
    });
  }

  const sixHourPeriods =
    props.selectedPeriodType === "six_hour" ? ["18", "12", "06", "00"] : [];

  let allTimeSteps = [];
  if (props.selectedPeriodType === "monthly") {
    allTimeSteps = rangeMonthsBetweenDates(props.selectedDate, 23);
  } else {
    // Get all the time-steps
    allTimeSteps = rangeBetweenDates(
      props.selectedDate,
      30,
      props.selectedSixHPeriod,
      sixHourPeriods
    );
  }

  // An array of objects for all dates of the time-series
  // to use a template for all series
  const allTimeStepsNull = allTimeSteps.map(t => {
    let obj = { x: t, y: null };
    return obj;
  });

  const mode = props.combinedMode ? "combined" : "simple";

  const chartMetadata = getChartMetadata(
    fileType,
    props.selectedReport,
    props.selectedPeriodType,
    props.selectedCenter,
    props.selectedVariable,
    mode,
    props.chartData[1]["data-y"]
  );

  const metadataYAxis = chartMetadata.metadataYAxis;

  let series;
  let keyData = chartMetadata.keyData;
  let keyExpected = chartMetadata.keyExpected;
  if (
    props.selectedPeriodType === "monthly" &&
    props.selectedReport === "quality" &&
    mode === "combined" &&
    fileType === "synop"
  ) {
    keyData = props.chartData[1]["data-y"];
  }

  if (
    props.selectedReport === "quality" &&
    (fileType === "ship" ||
      fileType === "buoy" ||
      fileType === "marine_surface")
  ) {
    keyData = "bg_dep";
    if (props.selectedPeriodType === "daily") {
      keyData = "avg_bg_dep";
    }
  }

  if (chartMetadata.type === "generic") {
    series = formatPeriodSeries(
      props.chartData[0],
      chartMetadata.seriesNames,
      allTimeSteps,
      allTimeStepsNull,
      keyData,
      keyExpected,
      props
    );
  }
  // In some cases we need to update 'series'
  // or build it if it's not of type 'generic'
  if (
    fileType === "synop" ||
    fileType === "ship" ||
    fileType === "buoy" ||
    fileType === "marine_surface"
  ) {
    if (
      props.selectedReport === "quality" &&
      props.selectedPeriodType === "six_hour" &&
      props.combinedMode
    ) {
      // 'entriesTemp' is an array. Each element is an array
      // which contains 2 elements:
      // - index 0: the name of the group
      // - index 1: the data of that group
      const entriesTemp = groups(props.chartData[0], d =>
        d.center.toLowerCase()
      );

      const entries = entriesTemp.map(e => {
        const entryValues = e[1].map(d => d.bg_dep);
        return {
          key: e[0],
          max: max(entryValues) ? max(entryValues) : -Infinity,
          min: min(entryValues) ? min(entryValues) : Infinity,
          data: e[1].map(d => {
            // rename keys `date` to `x` and `bg_dep` to '`y`
            return {
              x: d.date,
              y: d.bg_dep,
              status: d.status,
              obs_type: d.obs_type
            };
          })
        };
      });

      series = {};
      entries.forEach(function(item, index) {
        series[item.key] = {
          data: item.data,
          max: item.max,
          min: item.min,
          label: chartMetadata.seriesNames.filter(s => s.name === item.key)[0]
            .label,
          color: chartMetadata.seriesNames.filter(s => s.name === item.key)[0]
            .color,
          representAs: chartMetadata.seriesNames.filter(
            s => s.name === item.key
          )[0].representAs,
          tooltip: chartMetadata.seriesNames.filter(s => s.name === item.key)[0]
            .tooltip
        };
      });
      // Update allTimeSteps to include the individual dates
      allTimeSteps = updateTimeSteps(series, allTimeSteps);
    }
    if (
      props.selectedReport === "quality" &&
      props.selectedPeriodType === "six_hour" &&
      !props.combinedMode
    ) {
      // process the individual data if any

      let chartIndex = 1;
      if (props.chartData.length > 1) {
        let serieValues;

        if (
          fileType === "ship" ||
          fileType === "buoy" ||
          fileType === "marine_surface"
        ) {
          chartIndex = 0;
        } else {
          chartIndex = 1;
        }
        serieValues = props.chartData[chartIndex].map(d => d.bg_dep);
        series.individual = {
          data: props.chartData[chartIndex].map(d => {
            return {
              x: d.date,
              y: d.bg_dep,
              status: d.status,
              obs_type: d.obs_type
            };
          }),
          max: max(serieValues),
          min: min(serieValues),
          label: "O-B",
          // create a new function that takes only 'obj' as parameter
          color: obj => getColorByKey(obj, "status", 0),
          representAs: "points"
        };
      }

      // Update allTimeSteps to include the individual dates
      // Merge the time-steps of props.chartData[1] (individuals)
      // into allTimeSteps (periods) if not already in
      props.chartData[chartIndex].forEach(entry => {
        if (allTimeSteps.indexOf(entry.date) === -1) {
          allTimeSteps.push(entry.date);
        }
      });
      allTimeSteps.sort();
    }
    if (
      props.selectedPeriodType === "monthly" &&
      props.selectedReport === "quality" &&
      !props.combinedMode
    ) {
      series = {};
      chartMetadata.seriesNames.forEach(function(serie) {
        series[serie.name] = {
          data: allTimeStepsNull.map(a => ({ ...a })),
          max: -Infinity,
          min: Infinity,
          label: serie.label,
          color: serie.color,
          representAs: serie.representAs,
          tooltip: serie.tooltip
        };
      });

      props.chartData[0].forEach(function(d) {
        const indexToUpdate = allTimeSteps.indexOf(d.date);

        series.avg_bg_dep.data[indexToUpdate] = { x: d.date, y: d.avg_bg_dep };

        if (d.avg_bg_dep > series.avg_bg_dep.max) {
          series.avg_bg_dep.max = d.avg_bg_dep;
        }
        if (d.avg_bg_dep < series.avg_bg_dep.min) {
          series.avg_bg_dep.min = d.avg_bg_dep;
        }

        series.std_bg_dep.data[indexToUpdate] = {
          x: d.date,
          y: d.std_bg_dep
        };

        if (d.std_bg_dep > series.std_bg_dep.max) {
          series.std_bg_dep.max = d.std_bg_dep;
        }
        if (d.std_bg_dep < series.std_bg_dep.min) {
          series.std_bg_dep.min = d.std_bg_dep;
        }
      });
    }

    // For alert, we calculate the 5-day average from the daily values
    if (props.selectedPeriodType === "alert" && props.combinedMode) {
      // 1 serie per center: 5-day moving average (represented as line)

      const seriesTemp = formatPeriodSeries(
        props.chartData[0],
        chartMetadata.seriesNames,
        allTimeSteps,
        allTimeStepsNull,
        chartMetadata.keyData,
        chartMetadata.keyExpected,
        props
      );

      series = {};
      for (const serie in seriesTemp) {
        series[serie] = {};
        series[serie].color = seriesTemp[serie].color;
        series[serie].label = seriesTemp[serie].label;
        series[serie].representAs = seriesTemp[serie].representAs;
        series[serie].tooltip = seriesTemp[serie].tooltip;
        series[serie].data = seriesTemp[serie].data.slice(4).map((d, i) => {
          const iOriginal = i + 4;
          const arrLast5Days = [
            seriesTemp[serie].data[iOriginal - 4].y,
            seriesTemp[serie].data[iOriginal - 3].y,
            seriesTemp[serie].data[iOriginal - 2].y,
            seriesTemp[serie].data[iOriginal - 1].y,
            seriesTemp[serie].data[iOriginal].y
          ];
          const nbNotNull = arrLast5Days.filter(v => v !== null).length;
          return {
            x: d.x,
            y: nbNotNull > 0 ? mean(arrLast5Days) : null, // for precision use `deviation1 instead of `mean`
            nb_obs: nbNotNull
          };
        });
        series[serie].max = max(series[serie].data.map(d => d.y));
        series[serie].min = min(series[serie].data.map(d => d.y));
      }
    }

    if (props.selectedPeriodType === "alert" && !props.combinedMode) {
      // 2 series: 5-day moving average (represented as line)
      // and daily average (represented as point)

      // get the name of the property above
      const key = Object.keys(series)[0];

      // the 5-day average
      series.fiveday_avg = {
        data: series[key].data.slice(4).map((d, i) => {
          const iOriginal = i + 4;
          const arrLast5Days = [
            series[key].data[iOriginal - 4].y,
            series[key].data[iOriginal - 3].y,
            series[key].data[iOriginal - 2].y,
            series[key].data[iOriginal - 1].y,
            series[key].data[iOriginal].y
          ];
          const nbNotNull = arrLast5Days.filter(v => v !== null).length;
          return {
            x: d.x,
            y: nbNotNull > 0 ? mean(arrLast5Days) : null, // for precision use `deviation1 instead of `mean`
            nb_obs: nbNotNull
          };
        }),
        label: "5-day average O-B",
        color: "#60a2bf",
        representAs: "lines",
        tooltip: "point"
      };
      series.fiveday_avg.max = max(series.fiveday_avg.data.map(d => d.y));
      series.fiveday_avg.min = min(series.fiveday_avg.data.map(d => d.y));
    }
  } else if (fileType === "temp") {
    if (
      (props.selectedPeriodType === "daily" ||
        props.selectedPeriodType === "monthly") &&
      props.selectedReport === "quality" &&
      !props.combinedMode
    ) {
      series = {};
      chartMetadata.seriesNames.forEach(function(serie) {
        series[serie.name] = {
          data: allTimeStepsNull.map(a => ({ ...a })),
          max: -Infinity,
          min: Infinity,
          label: serie.label,
          color: serie.color,
          representAs: serie.representAs,
          tooltip: serie.tooltip
        };
      });

      props.chartData[0].forEach(function(d) {
        const indexToUpdate = allTimeSteps.indexOf(d.date);
        if (d.levels === "Stra") {
          series.avg_stra.data[indexToUpdate] = { x: d.date, y: d.avg_bg_dep };

          if (d.avg_bg_dep > series.avg_stra.max) {
            series.avg_stra.max = d.avg_bg_dep;
          }
          if (d.avg_bg_dep < series.avg_stra.min) {
            series.avg_stra.min = d.avg_bg_dep;
          }

          series.std_stra.data[indexToUpdate] = {
            x: d.date,
            y: d.avg_std_bg_dep
          };

          if (d.avg_std_bg_dep > series.std_stra.max) {
            series.std_stra.max = d.avg_std_bg_dep;
          }
          if (d.avg_std_bg_dep < series.std_stra.min) {
            series.std_stra.min = d.avg_std_bg_dep;
          }
        } else if (d.levels === "Trop") {
          series.avg_trop.data[indexToUpdate] = { x: d.date, y: d.avg_bg_dep };

          if (d.avg_bg_dep > series.avg_trop.max) {
            series.avg_trop.max = d.avg_bg_dep;
          }
          if (d.avg_bg_dep < series.avg_trop.min) {
            series.avg_trop.min = d.avg_bg_dep;
          }

          series.std_trop.data[indexToUpdate] = {
            x: d.date,
            y: d.avg_std_bg_dep
          };

          if (d.avg_std_bg_dep > series.std_trop.max) {
            series.std_trop.max = d.avg_std_bg_dep;
          }
          if (d.avg_std_bg_dep < series.std_trop.min) {
            series.std_trop.min = d.avg_std_bg_dep;
          }
        }
      });
    } else if (
      props.selectedPeriodType === "six_hour" &&
      props.selectedReport === "quality" &&
      !props.combinedMode
    ) {
      // Here we have individual observations so there can't
      // be any missing value between time-steps
      series = {};
      chartMetadata.seriesNames.forEach(function(serie) {
        series[serie.name] = {
          data: [], // empty array as we don't know yet teh time-steps
          max: -Infinity,
          min: Infinity,
          label: serie.label,
          color: serie.color,
          representAs: serie.representAs,
          tooltip: serie.tooltip
        };
      });
      props.chartData[0].forEach(function(d) {
        if (d.levels === "Stra") {
          series.avg_stra.data.push({ x: d.date, y: d.bg_dep });
          if (d.bg_dep > series.avg_stra.max) {
            series.avg_stra.max = d.bg_dep;
          }
          if (d.bg_dep < series.avg_stra.min) {
            series.avg_stra.min = d.bg_dep;
          }

          series.std_stra.data.push({ x: d.date, y: d.std_bg_dep });
          if (d.std_bg_dep > series.std_stra.max) {
            series.std_stra.max = d.std_bg_dep;
          }
          if (d.std_bg_dep < series.std_stra.min) {
            series.std_stra.min = d.std_bg_dep;
          }
        } else if (d.levels === "Trop") {
          series.avg_trop.data.push({ x: d.date, y: d.bg_dep });
          if (d.bg_dep > series.avg_trop.max) {
            series.avg_trop.max = d.bg_dep;
          }
          if (d.bg_dep < series.avg_trop.min) {
            series.avg_trop.min = d.bg_dep;
          }

          series.std_trop.data.push({ x: d.date, y: d.std_bg_dep });
          if (d.std_bg_dep > series.std_trop.max) {
            series.std_trop.max = d.std_bg_dep;
          }
          if (d.std_bg_dep < series.std_trop.min) {
            series.std_trop.min = d.std_bg_dep;
          }
        }
      });

      // Update allTimeSteps to include the individual dates
      allTimeSteps = updateTimeSteps(series, allTimeSteps);
    } else if (
      props.selectedPeriodType === "six_hour" &&
      props.selectedReport === "quality" &&
      props.combinedMode
    ) {
      // This a specific case because we don't have missing steps
      series = {};
      chartMetadata.seriesNames.forEach(function(serie) {
        series[serie.name] = {
          data: props.chartData[0]
            .filter(d => d.center.toLowerCase() === serie.name)
            .map(d => d.date),
          max: -Infinity,
          min: Infinity,
          label: serie.label,
          color: serie.color,
          representAs: serie.representAs,
          tooltip: serie.tooltip
        };
      });

      props.chartData[0].forEach(function(d) {
        const center = d.center.toLowerCase();
        const indexToUpdate = series[center].data.indexOf(d.date);
        series[center].data[indexToUpdate] = {
          x: d.date,
          y: d.rms
        };

        if (d.rms > series[center].max) {
          series[center].max = d.rms;
        }
        if (d.rms && d.rms < series[center].min) {
          series[center].min = d.rms;
        }
      });

      // Update allTimeSteps to include the individual dates
      allTimeSteps = updateTimeSteps(series, allTimeSteps);
    } else if (
      props.selectedPeriodType === "six_hour" &&
      props.selectedReport === "availability"
    ) {
      // From the individual observations we calculate
      // how many observations we have for both levels (Stra and Trop)
      // per six-hour period. Based on that we calculate a % of completeness

      series = {};

      let s = {};

      let arrayDates = [];
      props.chartData[0].forEach(function(d) {
        const center = d.center.toLowerCase();

        // new center
        if (!s[center]) {
          s[center] = { data: [] };
          arrayDates = [];
        }
        const pd = get6hPeriod(d.date);

        if (!arrayDates.includes(pd)) {
          arrayDates.push(pd);
          s[center].data.push({ x: pd, Trop: [], Stra: [] });
        }
        const lastIndex = s[center].data.length - 1;
        s[center].data[lastIndex][d.levels].push(d.var_id);
      });

      chartMetadata.seriesNames.forEach(function(d) {
        // IMPORTANT: we make a copy of the object d
        series[d.name] = { ...d };
      });

      for (const center in s) {
        const arrayData = [];
        const arrayDates = [];
        const arrayYValues = [];
        s[center].data.forEach(function(d) {
          arrayDates.push(d.x);
          const nbTrop = d.Trop.length;
          const nbStra = d.Stra.length;

          const divider = getSmallestMultiple(max([nbTrop, nbStra]), 4);
          const percentage = ((nbTrop + nbStra) / (divider * 2)) * 100;
          arrayData.push({
            x: d.x,
            y: percentage,
            details: getCompletenessDetails(percentage)
          });
          arrayYValues.push(percentage);
        });
        series[center].data = arrayData;
        series[center].rangeX = arrayDates;
        series[center].max = max(arrayYValues);
        series[center].min = min(arrayYValues);
      }

      // delete empty series
      for (const center in series) {
        if (!series[center].data || series[center].data.length === 0) {
          delete series[center];
        }
      }

      // Update allTimeSteps to include the individual dates
      allTimeSteps = updateTimeSteps(series, allTimeSteps);
    }
  }

  let dateFormats = { xAxis: "%Y-%m-%d", tooltip: null };
  if (props.selectedPeriodType === "six_hour") {
    dateFormats.tooltip = "%d/%m/%Y %H:%M";
  } else if (
    props.selectedPeriodType === "daily" ||
    props.selectedPeriodType === "alert"
  ) {
    dateFormats.tooltip = "%d/%m/%Y";
  } else if (props.selectedPeriodType === "monthly") {
    dateFormats.tooltip = "%d/%m/%Y";
    dateFormats.xAxis = "%Y-%m";
  }
  return { series, metadataYAxis, rangeX: allTimeSteps, dateFormats };
};

// exported only for test
export const getCompletenessDetails = percentage => {
  let details;
  if (percentage === 100) {
    details = "Full";
  } else if (percentage > 50) {
    details = "Missing variable(s)";
  } else {
    details = "Missing layer";
  }
  return details;
};

// Get the smallest multiple of k that is greater or equal to n
// exported only for test
export const getSmallestMultiple = (n, k) => {
  if (n === 0) {
    return k;
  }

  let rem = (n + k) % k;

  if (rem === 0) {
    return n;
  }

  return n + k - rem;
};
